import { When } from 'react-if';

import { CHALLENGE_LOCATIONS } from 'features/challenge/config';
import {
  INonRepeatedChallenge,
  IRepeatedChallenge,
  TChallengeLocation,
} from 'features/challenge/config/types';
import {
  PlanningDraftSprintChallengeCard,
  IPlanningDraftSprint,
  PlanningBacklogChallengeCard,
  PlanningDraggableItem,
  getNextLeftChallengeId,
  isDndItemCopied,
} from 'features/planning';
import { PlanningBoardColumnItem } from 'features/planning/components/Board/Column/Item';
import { useMovePlanningChallengeMutation__NEW } from 'features/planning/hooks/mutation/useMovePlanningChallenge__NEW';

import { getDraggedItemIndex } from 'shared/components/DragNDrop/utils/getDraggedItemIndex';

type TPlanningBoardItems = {
  challenges: (INonRepeatedChallenge | IRepeatedChallenge)[];
  sprint?: IPlanningDraftSprint;
  location: TChallengeLocation;
};

export const PlanningBoardItems__NEW = ({
  challenges,
  sprint,
  location,
}: TPlanningBoardItems) => {
  const { mutate } = useMovePlanningChallengeMutation__NEW();

  return (
    <When condition={challenges?.length}>
      {challenges.map((challenge, challengeIndex) => {
        return (
          <PlanningBoardColumnItem
            key={`${challenge.challengeId}-${challengeIndex}`}
            type="challenge"
            id={challenge.challengeId!}
            index={challengeIndex}
            parentId={sprint?.sprintId ?? CHALLENGE_LOCATIONS.BACKLOG}
            onDrop={(item: PlanningDraggableItem) => {
              const { id, index, parentId, location } = item;

              mutate({
                id,
                fromBacklog: parentId === CHALLENGE_LOCATIONS.BACKLOG,
                fromSprintId:
                  parentId === CHALLENGE_LOCATIONS.BACKLOG
                    ? undefined
                    : parentId,
                toBacklog: !sprint,
                toSprintId: sprint?.sprintId,
                underChallengeId: getNextLeftChallengeId({
                  challenges,
                  newIndex: index,
                  id,
                  isNew:
                    parentId !==
                    (sprint?.sprintId ?? CHALLENGE_LOCATIONS.BACKLOG),
                }),
                prevLocation: location,
                newLocation: sprint
                  ? CHALLENGE_LOCATIONS.DRAFT_SPRINT
                  : CHALLENGE_LOCATIONS.BACKLOG,
              });

              if (!isDndItemCopied(location)) {
                item.parentId = sprint?.sprintId ?? CHALLENGE_LOCATIONS.BACKLOG;
                item.location = sprint
                  ? CHALLENGE_LOCATIONS.DRAFT_SPRINT
                  : CHALLENGE_LOCATIONS.BACKLOG;
              }
            }}
            onHover={(
              draggedItem: PlanningDraggableItem,
              hoveredItem: PlanningDraggableItem
            ) => {
              draggedItem.index = getDraggedItemIndex<
                PlanningDraggableItem,
                PlanningDraggableItem
              >(
                draggedItem,
                hoveredItem,
                challenges.findIndex(
                  ({ challengeId }) => challengeId === draggedItem.id
                )
              );
            }}
            location={location}
          >
            <ChallengeCard challenge={challenge} sprint={sprint} />
          </PlanningBoardColumnItem>
        );
      })}
    </When>
  );
};

const ChallengeCard = ({
  sprint,
  challenge,
}: {
  challenge: IRepeatedChallenge | INonRepeatedChallenge;
  sprint?: IPlanningDraftSprint;
}) => (
  <>
    {sprint ? (
      <PlanningDraftSprintChallengeCard challenge={challenge} sprint={sprint} />
    ) : (
      <PlanningBacklogChallengeCard challenge={challenge} />
    )}
  </>
);
