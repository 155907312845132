import {
  JournalCardReactions,
  useCreateJournalNoteReaction,
  useDeleteJournalNoteReaction,
  JournalReaction,
} from 'features/journal';

type TJournalNoteCardReactions = {
  noteId: string;
  reactions: JournalReaction[];
};

export const JournalNoteCardReactions = ({
  noteId,
  reactions,
}: TJournalNoteCardReactions) => {
  const { mutateAsync: addReaction } = useCreateJournalNoteReaction();
  const { mutateAsync: removeReaction } = useDeleteJournalNoteReaction();

  const handleAddReaction = async (reaction: string) =>
    await addReaction({ noteId, reaction });

  const handleRemoveReaction = async (reactionId: number) =>
    await removeReaction({ noteId, reactionId });

  return (
    <JournalCardReactions
      reactions={reactions}
      onAddReaction={(reaction: string) => handleAddReaction(reaction)}
      onRemoveReaction={(reactionId: number) =>
        handleRemoveReaction(reactionId)
      }
    />
  );
};
