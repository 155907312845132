import { Box } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useDrag, useDrop, XYCoord } from 'react-dnd';

type SortableProps<T> = {
  onHover: (draggedItem: T, hoveredItem: T) => void;
  children?: React.ReactNode;
  item: T;
  onDrop: (item: T) => void;
  width?: string;
};

export const Sortable__NEW = <
  T extends {
    id: string;
    index: number;
    type: string;
    hoverState?: { isUpperOver: boolean; isLowerOver: boolean };
  }
>({
  children,
  onHover,
  item,
  onDrop,
}: SortableProps<T>) => {
  const [hoverState, setHoverState] = useState<{
    isUpperOver: boolean;
    isLowerOver: boolean;
  }>({
    isUpperOver: false,
    isLowerOver: false,
  });
  const ref = useRef<HTMLDivElement>(null);

  const [{ isOver, didDrop }, drop] = useDrop(() => ({
    accept: item.type,
    drop(itemDropped: T, monitor) {
      item.hoverState = {
        isUpperOver: false,
        isLowerOver: false,
      };

      setHoverState({
        isUpperOver: false,
        isLowerOver: false,
      });

      if (monitor.isOver()) {
        onDrop(itemDropped);
      }
    },
    hover(itemDragged: T, monitor) {
      if (!ref.current) {
        return;
      }

      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const clientOffset = monitor.getClientOffset();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffsetY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (clientOffsetY > hoverMiddleY) {
        item.hoverState = {
          isLowerOver: true,
          isUpperOver: false,
        };
        setHoverState({
          isLowerOver: true,
          isUpperOver: false,
        });
      }

      if (clientOffsetY < hoverMiddleY) {
        item.hoverState = {
          isUpperOver: true,
          isLowerOver: false,
        };
        setHoverState({
          isUpperOver: true,
          isLowerOver: false,
        });
      }

      onHover(itemDragged, item);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      didDrop: monitor.didDrop(),
    }),
  }));

  const [{ isDragging }, drag] = useDrag(() => ({
    type: item.type,
    item,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    isDragging: (monitor) => monitor.getItem().id === item.id,
  }));

  const isOverRef = useRef(isOver);

  useEffect(() => {
    if (isOverRef.current && !isOver && !didDrop) {
      item.hoverState = {
        isUpperOver: false,
        isLowerOver: false,
      };
      setHoverState({
        isUpperOver: false,
        isLowerOver: false,
      });
    }

    isOverRef.current = isOver;
  }, [isOver, didDrop, item]);

  drag(drop(ref));

  return (
    <Box
      ref={ref}
      position="relative"
      paddingTop="4px"
      cursor="move"
      opacity={isDragging ? 0.5 : 1}
      _before={{
        content: '""',
        position: 'absolute',
        bottom: hoverState.isLowerOver ? '-5px' : 'unset',
        top: hoverState.isUpperOver ? '-1px' : 'unset',
        left: 0,
        height: '6px',
        width: '6px',
        borderRadius: '50%',
        backgroundColor:
          hoverState.isUpperOver || hoverState.isLowerOver
            ? 'var(--brdAccent)'
            : 'transparent',
      }}
      _after={{
        content: '""',
        position: 'absolute',
        bottom: hoverState.isLowerOver ? '-5px' : 'unset',
        top: hoverState.isUpperOver ? '-1px' : 'unset',
        right: 0,
        height: '6px',
        width: '6px',
        borderRadius: '50%',
        backgroundColor:
          hoverState.isUpperOver || hoverState.isLowerOver
            ? 'var(--brdAccent)'
            : 'transparent',
      }}
    >
      <Box
        position="relative"
        _before={{
          content: '""',
          position: 'absolute',
          top: '-3px',
          left: 0,
          right: 0,
          height: '2px',
          backgroundColor: hoverState.isUpperOver
            ? 'var(--brdAccent)'
            : 'transparent',
        }}
        _after={{
          content: '""',
          position: 'absolute',
          bottom: '-3px',
          left: 0,
          right: 0,
          height: '2px',
          backgroundColor: hoverState.isLowerOver
            ? 'var(--brdAccent)'
            : 'transparent',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
