import {
  INonRepeatedChallenge,
  IRepeatedChallenge,
} from 'features/challenge/config/types';
import { useCreateChallengeModal } from 'features/challenge/hooks/useCreateChallengeModal';
import {
  IPlanningDraftSprint,
  usePlanningAnalyticsEvents,
} from 'features/planning';
import { useAddChallengesToSprintMutation } from 'features/sprint/hooks/mutation/useAddChallengesToSprint';

type TUseAddDraftSprintChallenge = {
  sprint: IPlanningDraftSprint;
};

export const useAddDraftSprintChallenge = ({
  sprint,
}: TUseAddDraftSprintChallenge) => {
  const { sendPlanningChallengeAddedEvent } = usePlanningAnalyticsEvents();

  const { mutateAsync: addChallenge } = useAddChallengesToSprintMutation({
    sprint,
  });

  const openAddChallengeModal = useCreateChallengeModal({
    mutateFunc: async (challenge) => {
      await addChallenge([
        challenge as IRepeatedChallenge | INonRepeatedChallenge,
      ]);

      sendPlanningChallengeAddedEvent('sprintDraft');
    },
    sprint: sprint,
  });

  return openAddChallengeModal;
};
