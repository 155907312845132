import { IChallenge } from 'features/challenge/config/types';
import { useEditCustomChallenge } from 'features/challenge/hooks/useEditCustomChallenge';

import { useOpenChallengeDialog } from './useOpenChallengeDialog';

type TUseOpenEditBacklogChallengeDialog = {
  challenge: IChallenge;
};

export const useOpenEditBacklogChallengeDialog = ({
  challenge,
}: TUseOpenEditBacklogChallengeDialog) => {
  const { editCustomChallenge: onChallengeSubmit } = useEditCustomChallenge();

  const openChallengeModal = useOpenChallengeDialog();

  return openChallengeModal({
    onChallengeSubmit,
    challenge,
  });
};
