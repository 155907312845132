import { useMutation } from '@tanstack/react-query';

import { logError } from 'lib/sentry/logError';
import { useMutationHTTPRequest } from 'shared_DEPRECATED/hooks';

import {
  INonRepeatedChallenge,
  IRepeatedChallenge,
} from 'features/challenge/config/types';
import { useUserId } from 'features/user/hooks/useUserId';

type TUseCreateCustomChallengeMutation = {
  options: any;
};

export const useCreateCustomChallengeMutation = ({
  options,
}: TUseCreateCustomChallengeMutation) => {
  const userId = useUserId();

  const { request } = useMutationHTTPRequest();

  return useMutation(
    (
      challenge: {
        customChallengeId: string;
      } & (INonRepeatedChallenge | IRepeatedChallenge)
    ) =>
      request({
        url: '/api/web/challenges/custom/create',
        body: { ...challenge, clientId: userId },
      }),
    {
      ...options,
      onError: (err, ...args) => {
        options?.logError?.(err, ...args);
        logError(err);
      },
    }
  );
};
