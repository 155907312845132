import { useQueryClient } from '@tanstack/react-query';

import { queryKeys } from 'api/config';

export const useFeatureFlag = (featureFlagKey: string) => {
  const queryClient = useQueryClient();
  const user = queryClient.getQueryData<{
    featureFlags: {
      [key: string]: boolean;
    };
  }>([queryKeys.user]);
  const isFeatureEnabled = user?.featureFlags?.[featureFlagKey] ?? false;

  return isFeatureEnabled;
};
