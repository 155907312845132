import { toastConfig } from 'lib/reactToastify/config';
import { lazy, Suspense, useCallback } from 'react';
import { toast } from 'react-toastify';

import Loader from 'shared_DEPRECATED/components/Loader';
import lazyRetry from 'shared_DEPRECATED/utils/lazyRetry';

import { useAddGoalFocusAreaMutation } from 'features/goal';

import { modal, SIZES } from 'shared/components/Modal';
import { getUuId } from 'shared/utils/uuid';

const GoalAddFocusAreaDialogContent = lazy(() =>
  lazyRetry(
    () => import('features/goal/components/FocusArea/Add/Dialog'),
    'GoalAddFocusAreaDialog'
  )
);

export const useAddGoalFocusArea = () => {
  const { mutateAsync } = useAddGoalFocusAreaMutation();

  const onSubmit = useCallback(
    async ({ title }: { title: string }) => {
      try {
        const temporaryFocusAreaId = getUuId();
        await mutateAsync({ title, id: temporaryFocusAreaId });
      } catch (err) {
        toast.error(
          "There's an error with creating the focus area. Please try again later.",
          toastConfig
        );
      }
    },
    [mutateAsync]
  );

  const openAddGoalFocusAreaModal = useCallback(() => {
    modal.open({
      content: (
        <Suspense fallback={<Loader size="sm" />}>
          <GoalAddFocusAreaDialogContent onSubmit={onSubmit} />
        </Suspense>
      ),
      props: {
        size: SIZES.MD,
      },
    });
  }, [onSubmit]);

  return {
    onAddFocusAreaClick: openAddGoalFocusAreaModal,
  };
};
