import { UseMutateAsyncFunction } from '@tanstack/react-query';
import { toastConfig } from 'lib/reactToastify/config';
import { useCallback } from 'react';
import { toast } from 'react-toastify';

import {
  INonRepeatedChallenge,
  IRepeatedChallenge,
} from 'features/challenge/config/types';
import { useOpenChallengeModal } from 'features/challenge/hooks';
import { useInitialChallenge } from 'features/challenge/hooks/useInitialChallenge';
import { IPlanningDraftSprint } from 'features/planning';
import { IDraftSprint } from 'features/sprint/config/types';

type TUseCreateCustomChallenge<
  T extends IRepeatedChallenge | INonRepeatedChallenge
> = {
  mutateFunc: UseMutateAsyncFunction<any, unknown, T, unknown>;
  sprint?: IDraftSprint | IPlanningDraftSprint;
};

export const useCreateChallengeModal = <
  T extends IRepeatedChallenge | INonRepeatedChallenge
>({
  mutateFunc,
  sprint,
}: TUseCreateCustomChallenge<T>) => {
  const initialChallenge = useInitialChallenge();
  const openChallengeModal = useOpenChallengeModal();

  const onSubmit = useCallback(
    async (challenge: T) => {
      try {
        await mutateFunc(challenge);
      } catch (err) {
        toast.error(
          "There's an error with creating the challenge. Please try again later.",
          toastConfig
        );
      }
    },
    [mutateFunc]
  );

  const openAddChallengeModal = () =>
    openChallengeModal({
      challenge: initialChallenge,
      onChallengeSubmit: onSubmit,
      title: 'Create Challenge',
      sprint,
    });

  return openAddChallengeModal;
};
