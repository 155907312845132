import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { queryKeys } from 'api/config';
import { useQueryHTTPRequest } from 'shared_DEPRECATED/hooks';

import { mapSessionData } from 'features/sessions/utils';

export const useSessionQuery = () => {
  const { sessionId } = useParams();
  const { request } = useQueryHTTPRequest();

  return useQuery(
    [queryKeys.coachSession, sessionId],
    () => request({ url: `/api/coach/coaching-sessions/${sessionId}` }),
    { enabled: !!sessionId, select: mapSessionData }
  );
};
