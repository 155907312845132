import { ReactNode } from 'react';

import { Flex } from 'shared';

type TJournalCardHeader = {
  children: ReactNode;
};

export const JournalCardHeader = ({ children }: TJournalCardHeader) => (
  <Flex justifyContent="space-between" alignItems="center" gap="0.5rem">
    {children}
  </Flex>
);
