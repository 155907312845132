import { useCallback } from 'react';

import { useDeleteCoachingToolFromFinishedSprintMutation } from './mutation/useDeleteCoachingToolFromFinishedSprint';

export const useCoachingToolDeleteFromActiveFinishedSprint = () => {
  const mutation = useDeleteCoachingToolFromFinishedSprintMutation();

  return useCallback(
    (toolId: string, sprintId: string) => {
      mutation.mutate({ toolId, sprintId });
    },
    [mutation]
  );
};
