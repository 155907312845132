import { ReactNode, createContext, useContext, useMemo } from 'react';

import {
  INonRepeatedChallenge,
  IRepeatedChallenge,
} from 'features/challenge/config/types';
import {
  filterChallengesByGoal,
  usePlanningBacklogQuery,
  useSelectedGoalsContext,
} from 'features/planning';

type PlanningBacklogFilterContextType = {
  filteredChallenges: (INonRepeatedChallenge | IRepeatedChallenge)[];
};

const defaultPlanningBacklogFilterContext: PlanningBacklogFilterContextType = {
  filteredChallenges: [],
};

const PlanningBacklogFilterContext = createContext(
  defaultPlanningBacklogFilterContext
);

export const PlanningBacklogFilterProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { selectedGoalsIds } = useSelectedGoalsContext();
  const { data: backlogData } = usePlanningBacklogQuery();

  const filteredChallenges = useMemo(() => {
    if (!backlogData) {
      return [];
    }

    return !selectedGoalsIds.length
      ? backlogData
      : filterChallengesByGoal({
          challenges: backlogData,
          selectedGoalsIds,
        });
  }, [selectedGoalsIds, backlogData]);

  const contextValue = useMemo(
    () => ({
      filteredChallenges,
    }),
    [filteredChallenges]
  );

  return (
    <PlanningBacklogFilterContext.Provider value={contextValue}>
      {children}
    </PlanningBacklogFilterContext.Provider>
  );
};

export const usePlanningBacklogFilterContext = () => {
  const planningBacklogFilterContext = useContext(PlanningBacklogFilterContext);

  if (!planningBacklogFilterContext) {
    throw new Error(
      'usePlanningBacklogFilterContext must be used within a PlanningBacklogFilterContext.Provider'
    );
  }

  return planningBacklogFilterContext;
};
