export const findIndexByProp = (
  arr: any[],
  prop: string,
  value: any
): number => {
  return arr.findIndex((item) => item[prop] === value);
};

export const deleteItemMutative = (arr: any[], index: number) => {
  const [item] = arr.splice(index, 1);

  return item;
};

export const insertItemMutative = (
  arr: any[],
  index: number,
  item: any
): void => {
  arr.splice(index, 0, item);
};
