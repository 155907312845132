import { useNavigate } from 'react-router-dom';

import { JOURNAL_FEATURE_FLAG, useFeatureFlag } from 'featureFlags';
import Box from 'shared_DEPRECATED/components/Box';
import Button, { VARIANTS } from 'shared_DEPRECATED/components/Button';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

import { openNotesSidebar } from 'features/challengeNotes/utils';
import { openJournalSidebar } from 'features/journal';
import {
  ParticipantsTableActionDisabled,
  ParticipantsTableActionWrapper,
  ParticipantsTableCounter,
} from 'features/participants/components/Table/Actions/';
import {
  ParticipantsTableLatestSprint,
  ParticipantsTableUserInfo,
} from 'features/participants/config/types';
import { useUserContext } from 'features/user/context';

type ParticipantsTableNotesPropTypes = {
  unreadNotes?: number;
  latestSprint: ParticipantsTableLatestSprint;
  userInfo: ParticipantsTableUserInfo;
};

export const ParticipantsTableNotes = ({
  unreadNotes,
  latestSprint,
  userInfo,
}: ParticipantsTableNotesPropTypes) => {
  const navigate = useNavigate();
  const { user } = useUserContext();
  const isJournalEnabled = useFeatureFlag(JOURNAL_FEATURE_FLAG);

  const handleNotesButtonClick = () => {
    navigate(`/coach/participants/${userInfo.email}/${latestSprint.sprintId}`);
    if (isJournalEnabled) {
      openJournalSidebar({ user });

      return;
    }

    openNotesSidebar({
      sprint: latestSprint,
      user,
    });
  };

  return typeof unreadNotes === 'number' ? (
    <Button
      variant={VARIANTS.TERTIARY}
      onClick={handleNotesButtonClick}
      dataTestid="participant-notes-btn"
    >
      <ParticipantsTableActionWrapper>
        <Box style={{ position: 'relative' }}>
          <Icon name={iconNames.message} width={18} height={18} />
          {unreadNotes > 0 && <ParticipantsTableCounter value={unreadNotes} />}
        </Box>
      </ParticipantsTableActionWrapper>
    </Button>
  ) : (
    <ParticipantsTableActionDisabled
      label="User doesn't have an active or past sprints"
      icon={iconNames.message}
    />
  );
};
