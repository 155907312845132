import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useCallback,
  ReactNode,
} from 'react';

import {
  JournalQueryParamsType,
  DEFAULT_JOURNAL_QUERY_PARAMS,
} from 'features/journal';

type JournalFilterContextType = {
  queryParams: JournalQueryParamsType;
  onQueryParamsChange: (updatedParams: Partial<JournalQueryParamsType>) => void;
  resetQueryParams: () => void;
};

type JournalFilterProviderProps = { children: ReactNode };

const JournalFilterContext = createContext<
  JournalFilterContextType | undefined
>(undefined);

export const JournalFilterProvider = ({
  children,
}: JournalFilterProviderProps) => {
  const [queryParams, setQueryParams] = useState<JournalQueryParamsType>(
    DEFAULT_JOURNAL_QUERY_PARAMS
  );

  const onQueryParamsChange = useCallback(
    (updatedParams: Partial<JournalQueryParamsType>) => {
      setQueryParams((prev) => ({ ...prev, ...updatedParams }));
    },
    []
  );

  const resetQueryParams = useCallback(
    () => setQueryParams(DEFAULT_JOURNAL_QUERY_PARAMS),
    []
  );

  const providerValue = useMemo(
    () => ({ queryParams, onQueryParamsChange, resetQueryParams }),
    [queryParams, onQueryParamsChange, resetQueryParams]
  );

  return (
    <JournalFilterContext.Provider value={providerValue}>
      {children}
    </JournalFilterContext.Provider>
  );
};

export const useJournalFilterContext = () => {
  const context = useContext(JournalFilterContext);

  if (!context) {
    throw new Error(
      'useJournalFilterContext must be used within JournalFilterProvider'
    );
  }

  return context;
};
