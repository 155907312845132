import { SPRINT_PLANNING_DND, useFeatureFlag } from 'featureFlags';
import Box from 'shared_DEPRECATED/components/Box';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import Loader from 'shared_DEPRECATED/components/Loader';

import {
  PlanningBacklog,
  usePlanningDraftSprintsQuery,
  PlanningBoardWrapper,
  BOARD_COLUMN_WIDTH,
  PlanningDraftSprints,
  PlanningActiveFinishedSprints,
  usePlanningBacklogQuery,
} from 'features/planning';
import { PlanningDraftSprints__NEW } from 'features/planning/components/Board/DraftSprints__NEW';
import { useSprintsQuery } from 'features/sprint/hooks';

export const PlanningBoard = () => {
  const isSprintPlanningDndEnabled = useFeatureFlag(SPRINT_PLANNING_DND);

  const { isLoading: isDraftSprintsLoading } = usePlanningDraftSprintsQuery();
  const { isLoading: areSprintsLoading } = useSprintsQuery();
  const { isLoading: isBacklogLoading } = usePlanningBacklogQuery();

  if (isDraftSprintsLoading || areSprintsLoading || isBacklogLoading) {
    return <Loader />;
  }

  return (
    <PlanningBoardWrapper>
      <Flex height="100%" gap="0.5rem">
        <PlanningBacklog />
        <PlanningActiveFinishedSprints pinned />
        {isSprintPlanningDndEnabled ? (
          // eslint-disable-next-line
          <PlanningDraftSprints__NEW pinned />
        ) : (
          <PlanningDraftSprints pinned />
        )}
      </Flex>
      <Box
        style={{
          width: `calc(100% - ${BOARD_COLUMN_WIDTH})`,
          height: '100%',
          overflowX: 'auto',
        }}
      >
        <Flex height="100%" width="100%" gap="0.5rem">
          <PlanningActiveFinishedSprints pinned={false} />
          {isSprintPlanningDndEnabled ? (
            // eslint-disable-next-line
            <PlanningDraftSprints__NEW pinned={false} />
          ) : (
            <PlanningDraftSprints pinned={false} />
          )}
        </Flex>
      </Box>
    </PlanningBoardWrapper>
  );
};
