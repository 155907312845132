import { useQuery } from '@tanstack/react-query';

import { queryKeys } from 'api/config';
import { useQueryHTTPRequest } from 'api/hooks/useHTTPRequest';
import { getQueryString } from 'shared_DEPRECATED/utils';

const url = '/api/coach/notifications';

export const useNotificationsQuery = (filter) => {
  const { request } = useQueryHTTPRequest();
  const queryParams = getQueryString(filter);

  const {
    data: notifications,
    status,
    isFetching,
    error,
    refetch,
  } = useQuery(
    [queryKeys.notifications, JSON.stringify(filter)],
    async () => {
      const res = await request({
        url: queryParams ? `${url}?${queryParams}` : url,
      });

      return res.items;
    },
    { initialData: [], useErrorBoundary: false }
  );

  return {
    notifications,
    status,
    isFetching,
    error,
    refetch,
  };
};
