import { Nullable } from 'shared_DEPRECATED/types';
import { Picture } from 'shared_DEPRECATED/types/Picture';

import {
  IGoal,
  GoalDeepDiveType,
  GOAL_DEEP_DIVE_DEFAULT_VALUE,
} from 'features/goal';

export class Goal implements IGoal {
  readonly goalId: string;
  readonly columnId: string;
  readonly columnLabel: string;
  readonly completedAt: Nullable<string>;
  readonly cover: Nullable<Picture>;
  name: string;
  deadline: Nullable<string>;
  dimensions: string[];
  deepDive: GoalDeepDiveType;
  deepDiveAvailable: boolean;
  note: string;
  prioritizedAt: Nullable<string>;

  constructor(goal: IGoal) {
    this.goalId = goal.goalId;
    this.columnId = goal.columnId;
    this.columnLabel = goal.columnLabel;
    this.name = goal.name;
    this.deadline = goal.deadline;
    this.dimensions = goal.dimensions;
    this.deepDive = goal.deepDive || GOAL_DEEP_DIVE_DEFAULT_VALUE;
    this.deepDiveAvailable = goal.deepDiveAvailable;
    this.completedAt = goal.completedAt;
    this.cover = goal.cover;
    this.note = goal.note;
    this.prioritizedAt = goal.prioritizedAt;
  }
}
