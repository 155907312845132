import PropTypes from 'prop-types';
import { Fragment } from 'react';

import { isImage } from 'shared_DEPRECATED/components/File/utils';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import Spacer from 'shared_DEPRECATED/components/Spacer';
import { getPictureUrl } from 'shared_DEPRECATED/utils';

import {
  ChallengeNotesCardBody,
  ChallengeNotesCardContainer,
  ChallengeNotesCardHeader,
  ChallengeNotesCardImageAttachment,
  ChallengeNotesCardVideoAttachment,
  ChallengeNotesCardMenu,
  ChallengeNotesCardTextWithHighlight,
  ChallengeNotesCardUserInfo,
  ChallengeNotesCardFooter,
} from 'features/challengeNotes/components/Card';
import { ChallengeNotesProofCardTitle } from 'features/challengeNotes/components/Card/ProofCardTitle';
import { NOTE_STATUS } from 'features/challengeNotes/config';
import { ChallengeNoteType } from 'features/challengeNotes/config/propTypes';

import { useSidebarContext } from 'shared/components/Sidebar/context';

const ChallengeNotesCardPropTypes = {
  children: PropTypes.node,
  note: ChallengeNoteType,
};

export const ChallengeNotesProofCard = ({ children, note }) => {
  const { sprint } = useSidebarContext();

  const challengeTitle =
    sprint.getChallenge(note.data.challengeId)?.title ?? '';

  return (
    <ChallengeNotesCardContainer
      unread={note.status === NOTE_STATUS.UNREAD}
      dataTestid="user-challenge-proof-note-card"
    >
      <ChallengeNotesCardHeader>
        <ChallengeNotesCardUserInfo
          name={note.authorName || note.authorEmail}
          pictureUrl={getPictureUrl(note.authorPicture)}
          date={note.data.statusDate}
          noteReadStatus={note.status}
        />
        <ChallengeNotesCardMenu
          noteId={note.noteId}
          noteReadStatus={note.status}
          challengeId={note.data.challengeId}
          userEmail={note.userEmail}
        />
      </ChallengeNotesCardHeader>
      <ChallengeNotesCardBody>
        <ChallengeNotesProofCardTitle title={challengeTitle} />
        {note.data.description && (
          <ChallengeNotesCardTextWithHighlight nodeId={note.noteId}>
            {note.data.description}
          </ChallengeNotesCardTextWithHighlight>
        )}
        <Spacer size="sm zr" />
        <Flex flexWrap="wrap" justifyContent="start" gap="var(--spacing04)">
          {note.data?.mediaList.map(({ url, contentType, thumbnail }) => {
            return (
              <Fragment key={url}>
                {isImage(contentType) ? (
                  <ChallengeNotesCardImageAttachment src={url} />
                ) : (
                  <ChallengeNotesCardVideoAttachment
                    src={url}
                    previewSrc={thumbnail.url}
                  />
                )}
              </Fragment>
            );
          })}
        </Flex>
      </ChallengeNotesCardBody>
      <ChallengeNotesCardFooter>{children}</ChallengeNotesCardFooter>
    </ChallengeNotesCardContainer>
  );
};

ChallengeNotesProofCard.propTypes = ChallengeNotesCardPropTypes;
