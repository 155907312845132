import { useQuery } from '@tanstack/react-query';

import { queryKeys } from 'api/config';
import { useQueryHTTPRequest } from 'api/hooks/useHTTPRequest';
import { getQueryString } from 'shared_DEPRECATED/utils';

export const useLibraryChallengesQuery = (queryParams, options) => {
  const queryString = getQueryString(queryParams);
  const { request } = useQueryHTTPRequest();

  return useQuery(
    [queryKeys.libraryChallenges],
    () => request({ url: `/api/challenges?${queryString}` }),
    {
      placeholderData: { curated: [], custom: [] },
      ...options,
    }
  );
};
