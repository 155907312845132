import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { useFormAutosave } from 'shared_DEPRECATED/hooks';

import { useEditGoalMutation, IGoal } from 'features/goal';

type TGoalDeepDiveFormWrapper = {
  goal: IGoal;
  children: React.ReactNode;
};

export const GoalDeepDiveFormWrapper = ({
  children,
  goal,
}: TGoalDeepDiveFormWrapper) => {
  const formMethods = useForm<IGoal>({
    defaultValues: { ...goal },
  });

  const { mutateAsync } = useEditGoalMutation({
    goalId: goal.goalId,
    modifiedColumnId: goal.columnId,
  });

  useFormAutosave({
    formMethods,
    onMutate: mutateAsync,
    storageKey: `goalId-${goal.goalId}`,
  });

  useEffect(() => {
    formMethods.reset({ ...goal });
  }, [goal, formMethods]);

  return <FormProvider {...formMethods}>{children}</FormProvider>;
};
