import { useCallback } from 'react';
import { When } from 'react-if';
import { useNavigate } from 'react-router-dom';

import { COACHING_TOOLS_FEATURE_FLAG, useFeatureFlag } from 'featureFlags';
import { Button } from 'shared';
import Box from 'shared_DEPRECATED/components/Box';

import { ChallengeCard } from 'features/challenge/components/ChallengeCard';
import { CHALLENGE_LOCATIONS } from 'features/challenge/config';
import {
  CoachingToolCard,
  CoachingToolCardMenu,
  CoachingToolCardMenuCopyToSprint,
} from 'features/coachingTool';
import { CoachingTool } from 'features/coachingTool/CoachingTool';
import {
  PlanningSprintHeader,
  PlanningBoardColumn,
  PlanningBoardColumnItemsCount,
  ChallengeType,
  usePlanningAnalyticsEvents,
  DRAGGABLE_ITEM_TYPES,
} from 'features/planning';
import { useCoachingToolDeleteFromActiveFinishedSprint } from 'features/planning/hooks/useCoachingToolDeleteFromActiveFinishedSprint';
import { SPRINT_STATES } from 'features/sprint/config';
import { useSprintLink } from 'features/sprint/hooks';

import {
  BoardColumn,
  BoardColumnHeader,
  BoardColumnWrapper,
} from 'shared/components/Board';

import { useCoachingToolsOnDrop } from '../../hooks/useCoachingToolsOnDrop';
import { CoachingToolsDraggable } from './Backlog/CoachingToolsDraggable';
import { CoachingToolDroppable } from './Backlog/CoachingToolsDroppable';
import { PlanningBoardColumnItemDraggable } from './Column/ItemDraggable';

type PlanningFinishedSprintProps = {
  sprintIndex: number;
  sprintId: string;
  sprintTitle: string;
  challenges: ChallengeType[];
  coachingTools: CoachingTool[];
  sprintFormattedDate: string;
  shouldScrollIntoView: boolean;
  sprintWeeksCount?: number;
};

export const PlanningFinishedSprint__NEW = ({
  sprintIndex,
  sprintId,
  sprintTitle,
  sprintFormattedDate,
  challenges,
  coachingTools,
  shouldScrollIntoView,
  sprintWeeksCount,
}: PlanningFinishedSprintProps) => {
  const isCoachingToolsEnabled = useFeatureFlag(COACHING_TOOLS_FEATURE_FLAG);
  const sprintLink = useSprintLink(sprintId);

  const { sendPlanningViewPastSprintClickedEvent } =
    usePlanningAnalyticsEvents();

  const navigate = useNavigate();
  const onViewButtonClick = useCallback(() => {
    sendPlanningViewPastSprintClickedEvent();

    navigate(sprintLink);
  }, [navigate, sendPlanningViewPastSprintClickedEvent, sprintLink]);

  const handleCoachingToolRemove =
    useCoachingToolDeleteFromActiveFinishedSprint();

  const handleOnDrop = useCoachingToolsOnDrop({ coachingTools });

  return (
    <PlanningBoardColumn shouldScrollIntoView={shouldScrollIntoView}>
      <Box
        style={{
          height: '100%',
          width: '100%',
          borderRadius: 'var(--border-radius12)',
        }}
      >
        <CoachingToolDroppable
          sprintId={sprintId}
          onDrop={async (item: any) => {
            await handleOnDrop(
              item,
              sprintId,
              CHALLENGE_LOCATIONS.FINISHED_SPRINT
            );
          }}
        >
          <BoardColumnWrapper fraction={1}>
            <BoardColumnHeader minHeight="3rem">
              <PlanningSprintHeader
                title={sprintTitle}
                sprintState={SPRINT_STATES.FINISHED}
                sprintFormattedDate={sprintFormattedDate}
                sprintId={sprintId}
                onViewButtonClick={onViewButtonClick}
              />
            </BoardColumnHeader>
            <BoardColumn spacing={1}>
              <When condition={challenges.length > 0}>
                <PlanningBoardColumnItemsCount
                  name="challenge"
                  count={challenges.length}
                />
                {challenges.map((challenge, challengeIndex) => (
                  <PlanningBoardColumnItemDraggable
                    key={`${challenge.challengeId}`}
                    type="challenge"
                    id={challenge.challengeId!}
                    index={challengeIndex}
                    parentId={sprintId}
                    location={CHALLENGE_LOCATIONS.FINISHED_SPRINT}
                  >
                    <ChallengeCard
                      title={challenge.title}
                      picture={challenge.picture}
                      emoji={challenge.emoji}
                      dimensions={challenge.dimensions}
                      target={challenge.target}
                      goals={challenge.goals}
                      sprintWeeksCount={sprintWeeksCount}
                    />
                  </PlanningBoardColumnItemDraggable>
                ))}
              </When>
              <When
                condition={isCoachingToolsEnabled && coachingTools.length > 0}
              >
                <PlanningBoardColumnItemsCount
                  name="coaching tool"
                  count={coachingTools.length}
                />
                {coachingTools.map(({ name, description, picture, id }) => (
                  <CoachingToolsDraggable
                    key={id}
                    item={{
                      id,
                      type: DRAGGABLE_ITEM_TYPES.COACHING_TOOL,
                      fromSprintId: sprintId,
                      fromLocation: CHALLENGE_LOCATIONS.FINISHED_SPRINT,
                    }}
                  >
                    <CoachingToolCard
                      name={name}
                      description={description}
                      url={picture?.url}
                      id={id}
                      key={id}
                    >
                      <CoachingToolCardMenu toolId={id}>
                        <CoachingToolCardMenuCopyToSprint toolId={id} />
                        <Button
                          _focus={{ outline: 'none' }}
                          width="100%"
                          onClick={() => handleCoachingToolRemove(id, sprintId)}
                          justifyContent="flex-start"
                        >
                          Remove
                        </Button>
                      </CoachingToolCardMenu>
                    </CoachingToolCard>
                  </CoachingToolsDraggable>
                ))}
              </When>
            </BoardColumn>
          </BoardColumnWrapper>
        </CoachingToolDroppable>
      </Box>
    </PlanningBoardColumn>
  );
};
