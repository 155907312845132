import Box from 'shared_DEPRECATED/components/Box/Box';
import { FileDownloadButton } from 'shared_DEPRECATED/components/File';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import Image from 'shared_DEPRECATED/components/Image';
import Spacer from 'shared_DEPRECATED/components/Spacer/Spacer';

import { ModalBody, ModalHeader } from 'shared/components/Modal';
import { Typography } from 'shared/components/Typography';

import styles from 'features/journal/components/Card/Card.module.css';

export function JournalCardImageModal({ src }: { src: string }) {
  return (
    <>
      <ModalHeader>
        <Box style={{ width: '90%' }}>
          <Flex justifyContent="space-between" alignItems="center">
            <Typography as="h3" type="body" fontWeight="semiBold" color="black">
              Preview image
            </Typography>
            <>
              <Spacer size="xsm" />
              <FileDownloadButton url={src} />
            </>
          </Flex>
        </Box>
      </ModalHeader>
      <ModalBody>
        <Image
          src={src}
          alt={src}
          className={styles['card-image__preview']}
          dataTestId="challenge-notes-card-image-modal-content"
        />
      </ModalBody>
    </>
  );
}
