import { ButtonGroup } from 'shared';

import {
  JournalNotesBody,
  JournalFilterProvider,
  JournalProvider,
  JournalFilter,
} from 'features/journal';

import { SidebarHeaderWithoutBorder } from 'shared/components/Sidebar';

export const JournalNotes = () => (
  <JournalFilterProvider>
    <JournalProvider>
      <SidebarHeaderWithoutBorder title="Journal">
        <ButtonGroup size="xs">
          <JournalFilter />
        </ButtonGroup>
      </SidebarHeaderWithoutBorder>
      <JournalNotesBody />
    </JournalProvider>
  </JournalFilterProvider>
);
