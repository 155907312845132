import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toastConfig } from 'lib/reactToastify/config';
import { useOutletContext } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useMutationHTTPRequest } from 'api/hooks/useHTTPRequest';
import { logError } from 'lib/sentry/logError';

import {
  CHALLENGE_FIELDS,
  CHALLENGE_TYPE_FIELDS,
  CHALLENGE_TYPES,
} from 'features/challenge/config';
import { QUERY_KEYS } from 'features/sprint/config';

export const useDailyStatusMutation = ({
  challengeId,
  weekDayIndex,
  challengeType,
}) => {
  const {
    sprint: { sprintId, weeks },
    selectedWeekIndex,
  } = useOutletContext();
  const selectedWeekDates = weeks[selectedWeekIndex];
  const formattedDate = selectedWeekDates[weekDayIndex];
  const challengeField =
    CHALLENGE_TYPE_FIELDS[challengeType] || CHALLENGE_FIELDS.CHALLENGES;

  const getRepeatedTarget = (challenge, status) => ({
    ...challenge.target,
    dailyStatus: {
      ...challenge.target.dailyStatus,
      [formattedDate]: {
        ...challenge.target.dailyStatus[formattedDate],
        noteIds: challenge.target.dailyStatus[formattedDate]?.noteIds || [],
        status,
      },
    },
  });
  const getNonRepeatedTarget = (challenge, status) => ({
    ...challenge.target,
    status,
    statusSetOn: formattedDate,
  });
  const mapChallenges = (challenge, status) =>
    challenge.sprintChallengeId === challengeId
      ? {
          ...challenge,
          target:
            challengeType === CHALLENGE_TYPES.REPEATING
              ? getRepeatedTarget(challenge, status)
              : getNonRepeatedTarget(challenge, status),
        }
      : challenge;

  const queryClient = useQueryClient();
  const { request } = useMutationHTTPRequest();

  return useMutation(
    ({ status }) => {
      const challengeDailyStatuses = {
        sprintChallengeId: challengeId,
        dailyStatus: {
          [formattedDate]: status,
        },
      };

      return request({
        url: `/api/sprints/${sprintId}/set-daily-statuses`,
        body: {
          items: [challengeDailyStatuses],
        },
      });
    },
    {
      onMutate: async ({ status }) => {
        const previousSprint = queryClient.getQueryData(
          QUERY_KEYS.SPRINT(sprintId)
        );
        const updateSprint = (oldSprint) => ({
          ...oldSprint,
          [challengeField]: oldSprint[challengeField].map((challenge) =>
            mapChallenges(challenge, status)
          ),
        });

        queryClient.setQueryData(QUERY_KEYS.SPRINT(sprintId), (oldSprint) =>
          updateSprint(oldSprint)
        );

        return { previousSprint };
      },
      onError: (err, _, context) => {
        queryClient.setQueryData(
          QUERY_KEYS.SPRINT(sprintId),
          context.previousSprint
        );

        toast.error(
          "There's an error with changing changing sprint daily status. Please try again later.",
          {
            icon: false,
            ...toastConfig,
          }
        );

        logError(err);
      },
      onSuccess: (res) => {
        if (res?.completionRate) {
          queryClient.setQueryData(
            QUERY_KEYS.SPRINT(sprintId),
            (oldSprint) => ({
              ...oldSprint,
              completionRate: res.completionRate,
            })
          );
        }
      },
    }
  );
};
