import { useMutation, useQueryClient } from '@tanstack/react-query';

import { queryKeys } from 'api/config';
import { useMutationHTTPRequest } from 'shared_DEPRECATED/hooks';
import { updateArrayById } from 'shared_DEPRECATED/utils';

import { IChallenge } from 'features/challenge/config/types';
import {
  IPlanningDraftSprint,
  usePlanningDraftSprintsQueryActions,
} from 'features/planning';
import { useUserId } from 'features/user/hooks/useUserId';

type TUseEditPlanningDraftSprintChallengeMutation = {
  sprint: IPlanningDraftSprint;
};

export const useEditPlanningDraftSprintChallengeMutation = ({
  sprint,
}: TUseEditPlanningDraftSprintChallengeMutation) => {
  const { request } = useMutationHTTPRequest();
  const queryClient = useQueryClient();
  const userId = useUserId();

  const {
    cancelDraftSprintsQueries,
    getDraftSprints,
    updateDraftSprints,
    invalidateDraftSprintsQuery,
  } = usePlanningDraftSprintsQueryActions();

  return useMutation(
    //@ts-ignore
    (challenge: IChallenge) =>
      request({
        url: `/api/web/sprints/${sprint.sprintId}/challenges/edit`,
        body: { challenges: [challenge] },
      }),
    {
      onMutate: async (updatedChallenge: IChallenge) => {
        await cancelDraftSprintsQueries();

        const previousDraftSprintsValue = getDraftSprints();

        if (previousDraftSprintsValue) {
          const updatedSprint = {
            ...sprint,
            challenges: updateArrayById({
              array: sprint.challenges,
              updatedItem: { ...updatedChallenge, goals: [] },
              idProperty: 'challengeId',
            }),
          };

          const updatedItems = updateArrayById({
            array: previousDraftSprintsValue!.items,
            updatedItem: updatedSprint,
            idProperty: 'sprintId',
          });

          updateDraftSprints(updatedItems);
        }

        return { previousDraftSprintsValue };
      },
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.challenges]);
        invalidateDraftSprintsQuery();
      },
      onError: (
        err: Error,
        _: any,
        context: { previousDraftSprintsValue?: IPlanningDraftSprint[] }
      ) => {
        queryClient.setQueryData(
          [queryKeys.draftSprints, userId],
          context?.previousDraftSprintsValue
        );
      },
    }
  );
};
