import { ReactNode } from 'react';

import { Flex } from 'shared';

type TJournalCardBody = {
  children: ReactNode;
};

export const JournalCardBody = ({ children }: TJournalCardBody) => (
  <Flex
    width="100%"
    justifyContent="space-between"
    flexDirection="column"
    gap="4px"
  >
    {children}
  </Flex>
);
