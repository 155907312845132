import { For } from 'react-loops';

import { Flex, useSidebarContext } from 'shared';
import { ReactionEmojiPicker } from 'shared_DEPRECATED/components/Reactions';
import { DEFAULT_REACTIONS_OPTIONS } from 'shared_DEPRECATED/components/Reactions/config';

import {
  groupedReactionsByAuthor,
  JournalReaction,
  JournalCardReactionGroup,
} from 'features/journal';
import { User } from 'features/user/User';

type TJournalCardReactions = {
  reactions: JournalReaction[];
  onAddReaction: (reaction: string) => void;
  onRemoveReaction: (reactionId: number) => void;
  emojiPickerPosition?: 'start' | 'end';
};

export const JournalCardReactions = ({
  reactions,
  onAddReaction,
  onRemoveReaction,
  emojiPickerPosition = 'end',
}: TJournalCardReactions) => {
  const { user } = useSidebarContext() as { user: User };

  return (
    <Flex
      alignItems="flex-end"
      gap="4px"
      direction={emojiPickerPosition === 'start' ? 'row-reverse' : 'row'}
    >
      <Flex gap="4px">
        <For in={groupedReactionsByAuthor(reactions, user.userId)}>
          {({ author, isMy, reactions }) => (
            <JournalCardReactionGroup
              key={author.userId}
              author={author}
              reactions={reactions}
              onRemove={(reactionId) => isMy && onRemoveReaction(reactionId)}
            />
          )}
        </For>
      </Flex>
      <ReactionEmojiPicker
        handleEmojiSelect={(reaction) => onAddReaction(reaction)}
        options={{
          ...DEFAULT_REACTIONS_OPTIONS,
          emojiSize: 20,
        }}
      />
    </Flex>
  );
};
