import { useMutation, useQueryClient } from '@tanstack/react-query';

import { queryKeys } from 'api/config';
import { logError } from 'lib/sentry/logError';
import { useMutationHTTPRequest } from 'shared_DEPRECATED/hooks';

import { useGoalsUpdateQuery } from 'features/goal';
import { useUserId } from 'features/user/hooks/useUserId';

type TUseEditGoalMutation = {
  goalId: string;
  modifiedColumnId: string;
};

export const useDeleteGoalMutation = ({
  goalId,
  modifiedColumnId,
}: TUseEditGoalMutation) => {
  const queryClient = useQueryClient();
  const { request } = useMutationHTTPRequest();

  const userId = useUserId();

  const updateGoalsQuery = useGoalsUpdateQuery({
    userId,
    modifiedColumnId,
  });

  return useMutation(
    () =>
      request({
        url: `/api/web/goals/${goalId}/delete`,
      }),
    {
      onMutate: () => {
        const { previousValue } = updateGoalsQuery.removeGoal(goalId);

        return { previousValue };
      },
      onSettled: () => {
        queryClient.invalidateQueries([queryKeys.goals, userId]);
        queryClient.invalidateQueries([queryKeys.challengeGoals, userId]);
      },
      onError: (err, _, context) => {
        queryClient.setQueryData(
          [queryKeys.goals, userId],
          context?.previousValue
        );
        logError(err);
      },
    }
  );
};
