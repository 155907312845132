import { TabList } from '@chakra-ui/react';

import { COACHING_TOOLS_FEATURE_FLAG } from 'featureFlags';
import { useFeatureFlag } from 'featureFlags/hooks';
import { Flex } from 'shared';

import { PlanningBacklogHeaderTab } from 'features/planning';

export const PlanningBacklogHeader = () => {
  const isCoachingToolsEnabled = useFeatureFlag(COACHING_TOOLS_FEATURE_FLAG);

  return (
    <Flex justifyContent="space-between" width="100%" height="100%" px="8px">
      <TabList>
        <PlanningBacklogHeaderTab>Challenges</PlanningBacklogHeaderTab>
        {isCoachingToolsEnabled && (
          <PlanningBacklogHeaderTab>Coaching tools</PlanningBacklogHeaderTab>
        )}
      </TabList>
    </Flex>
  );
};
