import { Avatar } from '@chakra-ui/react';
import { For } from 'react-loops';

import { Flex } from 'shared';
import { ReactionsButton } from 'shared_DEPRECATED/components/Reactions';

import { JournalAuthor, JournalReaction } from 'features/journal/types';

type TJournalCardReactionGroup = {
  author: JournalAuthor;
  reactions: JournalReaction[];
  onRemove: (reactionId: number) => void;
};

export const JournalCardReactionGroup = ({
  author,
  reactions,
  onRemove,
}: TJournalCardReactionGroup) => (
  <Flex
    align="center"
    gap="4px"
    bg="var(--bgCompSecondaryMid)"
    borderRadius="var(--border-radius16)"
    p="4px"
  >
    <Flex flexWrap="wrap">
      <For of={reactions}>
        {({ reactionId, reaction }) => (
          <ReactionsButton
            key={reactionId}
            onClick={() => onRemove(reactionId)}
            dataTestid="journal-card-reaction-button"
          >
            {reaction}
          </ReactionsButton>
        )}
      </For>
    </Flex>
    <Avatar src={author.picture.url} name={author.fullName} size="xs" />
  </Flex>
);
