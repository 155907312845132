import { CHALLENGE_LOCATIONS } from 'features/challenge/config';
import {
  INonRepeatedChallenge,
  IRepeatedChallenge,
  IChallenge,
  TChallengeLocation,
} from 'features/challenge/config/types';
import {
  IPlanningDraftSprint,
  UNLINKED_CHALLENGES_VALUE,
} from 'features/planning';

export const isItemNextToTheDropZone = (
  itemIndex: number,
  dropZoneIndex: number
) => itemIndex === dropZoneIndex || itemIndex + 1 === dropZoneIndex;

export const canItemBeDroppedInDropZone =
  (currentColumn: 'sprint' | 'backlog', currentSprintId?: string) =>
  (challengeIndex: number) =>
  (
    item: IChallenge & {
      fromSprintId: string;
      index: number;
      fromBacklog: boolean;
    }
  ) => {
    const isNextToItself = isItemNextToTheDropZone(item.index, challengeIndex);

    return item.fromBacklog
      ? !(currentColumn === 'backlog' && isNextToItself)
      : currentColumn !== 'backlog' &&
          !(item.fromSprintId === currentSprintId && isNextToItself);
  };

export const filterChallengesByGoal = ({
  challenges,
  selectedGoalsIds,
}: {
  challenges: (INonRepeatedChallenge | IRepeatedChallenge)[];
  selectedGoalsIds: string[];
}) =>
  challenges.filter((challenge) => {
    if (selectedGoalsIds.includes(UNLINKED_CHALLENGES_VALUE)) {
      return (
        challenge.goals.length === 0 ||
        challenge.goals.some(({ goalId }) => selectedGoalsIds.includes(goalId))
      );
    }

    return challenge.goals.some(({ goalId }) =>
      selectedGoalsIds.includes(goalId)
    );
  });

export const filterSprintsByCoachingTool = (
  sprints: IPlanningDraftSprint[],
  toolId: string
) =>
  sprints?.filter(
    (sprint) => !sprint.coachingTools.some((tool) => tool.id === toolId)
  ) || [];

export const getNextLeftChallengeId = ({
  challenges,
  id,
  newIndex = -1,
  isNew = false,
}: {
  challenges: (INonRepeatedChallenge | IRepeatedChallenge)[];
  id: string;
  newIndex?: number;
  isNew?: boolean;
}) => {
  if (isNew && newIndex === -1) {
    throw new Error('newIndex is required when isNew is true');
  }

  if (newIndex === 0) {
    return null;
  }

  if (isNew) {
    return challenges[newIndex - 1].challengeId;
  }

  const prevItemIndex = challenges.findIndex((item) => item.challengeId === id);

  const isMovedToTheLeft = prevItemIndex > newIndex;

  const nextLeftChallengeIndex = isMovedToTheLeft ? newIndex - 1 : newIndex;

  return challenges[nextLeftChallengeIndex].challengeId;
};

// we can change dnd item props only if it's moved and not copied
export const isDndItemCopied = (location: TChallengeLocation) =>
  location === CHALLENGE_LOCATIONS.ACTIVE_SPRINT ||
  location === CHALLENGE_LOCATIONS.FINISHED_SPRINT;
