import { Flex } from 'shared';

import {
  JournalCard,
  JournalNote,
  JournalNoteCardReactions,
} from 'features/journal';
import { User } from 'features/user/User';

import { useSidebarContext } from 'shared/components/Sidebar/context';

type TJournalRepliesCard = {
  note: JournalNote;
  setEditedNode: () => void;
};

export const JournalRepliesCard = ({
  note,
  setEditedNode,
}: TJournalRepliesCard) => {
  const { user } = useSidebarContext() as { user: User };

  return (
    <JournalCard
      note={note}
      setEditedNote={setEditedNode}
      isAuthor={Boolean(user.userId === note.authorId)}
    >
      <Flex width="100%" justifyContent="flex-end">
        <JournalNoteCardReactions
          noteId={note.noteId}
          reactions={note.reactions}
        />
      </Flex>
    </JournalCard>
  );
};
