import { useQuery } from '@tanstack/react-query';

import { queryKeys } from 'api/config';
import { useQueryHTTPRequest } from 'shared_DEPRECATED/hooks';

export const useNextSessionQuery = (appointmentId: string | undefined) => {
  const { request } = useQueryHTTPRequest();

  return useQuery(
    [queryKeys.coachSessionAppointment, appointmentId],
    () =>
      request({
        url: `/api/coach/coaching-session-appointments/${appointmentId}`,
      }),
    { enabled: !!appointmentId }
  );
};
