import PropTypes from 'prop-types';

import { Flex } from 'shared_DEPRECATED/components/Flex';

const SprintHistoryList = ({ children, itemsCount }) => (
  <Flex
    as="ul"
    flexDirection="column"
    alignItems="center"
    justifyContent={itemsCount === 0 ? 'center' : 'flex-start'}
    height="100%"
  >
    {children}
  </Flex>
);

SprintHistoryList.propTypes = {
  children: PropTypes.node.isRequired,
  itemsCount: PropTypes.number.isRequired,
};

export default SprintHistoryList;
