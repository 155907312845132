import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toastErrorConfig } from 'lib/reactToastify/config';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { queryKeys } from 'api/config';
import { produce } from 'immer';
import { logError } from 'lib/sentry/logError';
import { useMutationHTTPRequest } from 'shared_DEPRECATED/hooks';

import { GoalBreakdownType } from 'features/goal';
import { useGoalBreakdownDataContext } from 'features/goal/context/goalBreakdown';

export const useAddGoalFocusAreaMutation = () => {
  const { goalId } = useParams();
  const queryClient = useQueryClient();
  const { setGoalBreakdownData } = useGoalBreakdownDataContext();

  const { request } = useMutationHTTPRequest();

  return useMutation(
    ({ title }: { title: string; id: string }) =>
      request({
        url: `/api/web/goals/${goalId}/breakdown/focus-areas/create`,
        body: { title },
      }),
    {
      onMutate: async ({ title, id }) => {
        await queryClient.cancelQueries({
          queryKey: [queryKeys.goalBreakdown, goalId],
        });

        const previousValue = queryClient.getQueryData<GoalBreakdownType>([
          queryKeys.goalBreakdown,
          goalId,
        ]);

        queryClient.setQueryData<GoalBreakdownType>(
          [queryKeys.goalBreakdown, goalId],
          (oldVal) =>
            produce(oldVal!, (draft) => {
              draft.focusAreas.unshift({ title, id, challenges: [] });
            })
        );

        return { previousValue };
      },
      onSuccess: (data, variables) => {
        queryClient.setQueryData<GoalBreakdownType>(
          [queryKeys.goalBreakdown, goalId],
          (oldVal) =>
            produce(oldVal!, (draft) => {
              const focusArea = draft.focusAreas.find(
                (area) => area.id === variables.id
              );

              if (focusArea) {
                focusArea.id = data.id;
              }
            })
        );
      },
      onError: (err, _, context) => {
        if (context) {
          context.previousValue &&
            queryClient.setQueryData<GoalBreakdownType>(
              [queryKeys.goalBreakdown, goalId],
              context.previousValue
            );

          setGoalBreakdownData(context.previousValue);
        }

        logError(err);
        toast.error('Failed to add focus area', toastErrorConfig);
      },
    }
  );
};
