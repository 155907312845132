import { toastConfig } from 'lib/reactToastify/config';
import { Id, toast } from 'react-toastify';

import { logError } from 'lib/sentry/logError';

import {
  IRepeatedChallenge,
  INonRepeatedChallenge,
} from 'features/challenge/config/types';
import {
  IPlanningDraftSprint,
  useExploreChallengesDialog,
  usePlanningAnalyticsEvents,
} from 'features/planning';
import { useAddChallengesToSprintMutation } from 'features/sprint/hooks/mutation/useAddChallengesToSprint';

import { getUuId } from 'shared/utils/uuid';

type TUseDraftSprintAddLibraryChallenge = {
  sprint: IPlanningDraftSprint;
};

export const useDraftSprintAddLibraryChallenge = ({
  sprint,
}: TUseDraftSprintAddLibraryChallenge) => {
  const { sendPlanningChallengeAddedEvent } = usePlanningAnalyticsEvents();

  const { mutateAsync: addChallenges } = useAddChallengesToSprintMutation({
    sprint,
  });

  const onSubmit = async (
    newChallenges:
      | (IRepeatedChallenge | INonRepeatedChallenge)[]
      | (INonRepeatedChallenge | IRepeatedChallenge),
    toastId: Id | undefined
  ) => {
    let submitToastId: Id | undefined;
    try {
      if (!toastId) {
        submitToastId = toast.loading(
          'Adding challenges to sprint...',
          toastConfig
        );
      }
      const challenges = Array.isArray(newChallenges)
        ? newChallenges
        : [newChallenges];

      const challengesWithTemporaryChallengeIds = challenges.map(
        (challenge) => ({ ...challenge, challengeId: getUuId() })
      );

      await addChallenges(challengesWithTemporaryChallengeIds);

      sendPlanningChallengeAddedEvent('sprintDraft');

      !toastId && toast.dismiss(submitToastId);
    } catch (error) {
      logError(error);
    }
  };

  const openExploreChallengesModal = useExploreChallengesDialog({
    sprint,
    onSubmit,
  });

  return openExploreChallengesModal;
};
