import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';

import { queryKeys } from 'api/config';
import { useQueryHTTPRequest } from 'api/hooks/useHTTPRequest';

import { User } from 'features/user/User';

export const useUserQuery = () => {
  const { isAuthenticated } = useAuth0();
  const { request } = useQueryHTTPRequest();

  return useQuery(
    [queryKeys.user],
    () => request({ url: '/api/users/me/profile' }),
    {
      placeholderData: {},
      enabled: isAuthenticated,
      select: (data) => new User(data),
      staleTime: 20 * 1000,
    }
  );
};
