import { dateUtils } from 'shared_DEPRECATED/utils';

import {
  JournalReplies,
  JournalNotes,
  JournalNote,
  NotesGroupedBySprintIdAndDate,
  mapNotesToSprintsType,
  JOURNAL_FILTERS,
  JournalReaction,
  JournalAuthor,
  JournalAuthorPicture,
} from 'features/journal';
import { IFinalSprint } from 'features/sprint/config/types';
import { User } from 'features/user/User';

import { sidebar } from 'shared/components/Sidebar';
import { getUuId } from 'shared/utils/uuid';

export const createTemporaryJournalReaction = ({
  user,
  reaction,
}: {
  user: User;
  reaction: string;
}) => {
  const temporaryReactionId = getUuId();

  return {
    reactionId: parseInt(temporaryReactionId),
    author: {
      userId: user.userId,
      picture: { url: user.picture.url } as JournalAuthorPicture,
      fullName: `${user.firstName} ${user.lastName}`,
      firstName: user.firstName,
    },
    reaction,
    createdAt: new Date().toISOString(),
  };
};

export const groupedReactionsByAuthor = (
  reactions: JournalReaction[],
  userId: string
) => {
  return reactions?.reduce((acc, reaction) => {
    const authorId = reaction.author.userId;

    if (!acc[authorId]) {
      acc[authorId] = {
        author: reaction.author,
        isMy: Boolean(userId === authorId),
        reactions: [],
      };
    }

    acc[authorId].reactions.push(reaction);

    return acc;
  }, {} as { [authorId: string]: { author: JournalAuthor; isMy: boolean; reactions: JournalReaction[] } });
};

export const groupNotesBySprintAndDate = (
  notes: JournalNote[]
): NotesGroupedBySprintIdAndDate =>
  notes.reduce((acc, note) => {
    const { sprintId } = note;
    const dateKey = dateUtils(note.createdAt).format('YYYY-MM-DD');

    if (!acc[sprintId]) {
      acc[sprintId] = {};
    }

    if (!acc[sprintId][dateKey]) {
      acc[sprintId][dateKey] = [];
    }

    acc[sprintId][dateKey].push(note);

    return acc;
  }, {} as NotesGroupedBySprintIdAndDate);

export const mapNotesToSprints = (
  notes: JournalNote[],
  sprints: IFinalSprint[]
): mapNotesToSprintsType => {
  const notesGroupedBySprintAndDate = groupNotesBySprintAndDate(notes);

  return sprints
    .filter(({ sprintId }) => Boolean(notesGroupedBySprintAndDate[sprintId]))
    .map(({ sprintId, title: sprintTitle, formattedDate }) => {
      const notesByDate = notesGroupedBySprintAndDate[sprintId];

      return {
        sprintId,
        sprintTitle,
        formattedDate,
        notesByDate,
      };
    });
};

export const createJournalSprintFilterState = (
  selectedSprintIds: string[],
  currentSprint: IFinalSprint,
  pastSprints: IFinalSprint[]
): Record<string, boolean> => {
  const currentSprintId = currentSprint?.sprintId;

  const isOnlyCurrentSprintSelected =
    selectedSprintIds.length === 1 && selectedSprintIds[0] === currentSprintId;

  return pastSprints.reduce<Record<string, boolean>>(
    (acc, { sprintId }) => {
      const isPastSelected = selectedSprintIds.includes(sprintId);

      return {
        ...acc,
        [`${JOURNAL_FILTERS.PAST}-${sprintId}`]: isPastSelected,
      };
    },
    {
      [JOURNAL_FILTERS.ALL]: selectedSprintIds.length === 0,
      [JOURNAL_FILTERS.CURRENT]: isOnlyCurrentSprintSelected,
    }
  );
};

export const openJournalSidebar = ({ user }: { user: User }) =>
  sidebar.open({
    content: <JournalNotes />,
    props: { title: 'Journal Notes Sidebar' },
    context: { user, location: 'notes' },
  });

export const openJournalRepliesSidebar = ({
  noteId,
  user,
}: {
  noteId: string;
  user: User;
}) =>
  sidebar.open({
    content: <JournalReplies />,
    props: { title: 'Journal Replies Sidebar' },
    context: { noteId, user, location: 'replies' },
  });
