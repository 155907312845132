import { DndItem } from '../../Board/types/DndItem';
import { HoveredDndItem } from '../../Board/types/HoveredDndItem';

export const getDraggedItemIndex = <
  T extends DndItem,
  Y extends HoveredDndItem
>(
  draggedItem: T,
  hoveredItem: Y,
  currentItemIndex: number
) => {
  if (draggedItem.id === hoveredItem.id) {
    return currentItemIndex;
  }

  if (draggedItem.index === hoveredItem.index) {
    return hoveredItem.index;
  }

  const isMovedToTheRight = draggedItem.index < hoveredItem.index;

  if (isMovedToTheRight) {
    return hoveredItem.hoverState.isLowerOver
      ? hoveredItem.index
      : hoveredItem.index - 1;
  }

  return hoveredItem.hoverState.isUpperOver
    ? hoveredItem.index
    : hoveredItem.index + 1;
};
