import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect } from 'react';

import { queryKeys } from 'api/config';
import { off, on } from 'app/socket';
import { Nullable } from 'shared_DEPRECATED/types';

import { EVENT_TYPE } from 'features/chat/config/types/Message';
import { Participants } from 'features/participants/config/types';

export const useChatMessagesCountChange = ({
  chatId,
  userEmail,
}: {
  chatId: Nullable<string>;
  userEmail: string;
}) => {
  const queryClient = useQueryClient();

  const onMessagesCountChange = useCallback(
    (unreadMessagesCount: number) => {
      queryClient.setQueryData<Participants>(
        [queryKeys.participants],
        (oldVal) => ({
          ...oldVal,
          items: oldVal!.items.map((participant) => {
            if (participant.userEmail === userEmail) {
              return {
                ...participant,
                unreadChatMessagesNumber: unreadMessagesCount,
              };
            }
            return participant;
          }),
        })
      );
    },
    [queryClient, userEmail]
  );

  const handleIncomingMessages = useCallback(
    (message: { event: EVENT_TYPE; unreadMessagesCount: number }) => {
      if (message.event === EVENT_TYPE.CHAT_UNREAD_MESSAGES_COUNT_UPDATED) {
        onMessagesCountChange(message.unreadMessagesCount);
        queryClient.invalidateQueries([queryKeys.chatMessages, chatId]);
      }
    },
    [onMessagesCountChange, queryClient, chatId]
  );

  useEffect(() => {
    if (chatId) {
      on(chatId, handleIncomingMessages);

      return () => {
        off(chatId, handleIncomingMessages);
      };
    }
  }, [chatId, handleIncomingMessages]);
};
