import { MenuItem } from '@chakra-ui/react';

import { Typography } from 'shared';

import { IChallenge } from 'features/challenge/config/types';
import { useOpenEditDraftSprintChallengeDialog } from 'features/planning';
import { IPlanningDraftSprint } from 'features/planning/types';

type TPlanningDraftSprintMenuEditButton = {
  challenge: IChallenge;
  sprint: IPlanningDraftSprint;
};

export const PlanningDraftSprintMenuEditButton = ({
  challenge,
  sprint,
}: TPlanningDraftSprintMenuEditButton) => {
  const openEditChallengeModal = useOpenEditDraftSprintChallengeDialog({
    challenge,
    sprint,
  });

  return (
    <MenuItem
      onClick={openEditChallengeModal}
      _hover={{ outline: 'none', borderRadius: 'var(--border-radius)' }}
      width="100%"
    >
      <Typography color="black">Edit</Typography>
    </MenuItem>
  );
};
