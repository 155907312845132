import { CheckListPlugin } from '@lexical/react/LexicalCheckListPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin';

import { logError } from 'lib/sentry/logError';

import { namespace } from './constants';
import { editorNodes } from './nodes/editorNodes';
import { CodeHighlightPlugin } from './plugins/CodeHighlightPlugin';
import { ImagePlugin } from './plugins/ImagePlugin';
import { ListMaxIndentLevelPlugin } from './plugins/ListMaxIndentLevelPlugin';
import { ShortcutPlugin } from './plugins/ShortcutPlugin';
import './textEditorStyles.css';
import { theme } from './themes/textEditorTheme';
import { prepareEditorState, tryParseJson } from './utils';

type HtmlViewerProps = {
  rawHtml: string;
};

export const HtmlViewer = ({ rawHtml }: HtmlViewerProps) => {
  const isCurrentValueIsJson = tryParseJson(rawHtml);
  const initialConfig = {
    namespace,
    onError: (error: Error) => {
      logError(error);
    },
    editorState: isCurrentValueIsJson ? rawHtml : prepareEditorState(rawHtml),
    theme,
    editable: false,
    nodes: [...editorNodes],
  };

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <LexicalErrorBoundary
        onError={(error: Error) => {
          logError(error);
        }}
      >
        <>
          <RichTextPlugin
            placeholder={null}
            contentEditable={
              <div className={`${namespace}__container`}>
                <ContentEditable className={`${namespace}__content`} />
              </div>
            }
            ErrorBoundary={LexicalErrorBoundary}
          />
          <ListPlugin />
          <CheckListPlugin />
          <ListMaxIndentLevelPlugin />
          <CodeHighlightPlugin />
          <LinkPlugin />
          <ImagePlugin />
          <TabIndentationPlugin />
          <ShortcutPlugin />
        </>
      </LexicalErrorBoundary>
    </LexicalComposer>
  );
};
