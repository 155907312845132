import { ReactNode } from 'react';

import { Flex } from 'shared';
import Box from 'shared_DEPRECATED/components/Box';
import Button from 'shared_DEPRECATED/components/Button';

type ReactionsButtonPropTypes = {
  children: ReactNode;
  onClick: () => void;
  dataTestid: string;
};

export const ReactionsButton = ({
  children,
  onClick,
  dataTestid = '',
}: ReactionsButtonPropTypes) => (
  <Button onClick={onClick} dataTestid={dataTestid}>
    <Box
      style={{
        backgroundColor: 'transparent',
        borderRadius: '50%',
        width: '25px',
        height: '25px',
      }}
      hoverStyle={{
        backgroundColor: 'var(--bgSecondary)',
      }}
    >
      <Flex
        alignItems="center"
        justifyContent="center"
        height="100%"
        width="100%"
      >
        {children}
      </Flex>
    </Box>
  </Button>
);
