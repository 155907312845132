import {
  MenuButton as ChakraMenuButton,
  MenuButtonProps as ChakraMenuButtonProps,
  forwardRef,
} from '@chakra-ui/react';

export const MenuButton = forwardRef<ChakraMenuButtonProps, 'button'>(
  (props, ref) => (
    <ChakraMenuButton
      _focusVisible={{
        outline: 'none',
      }}
      {...props}
      ref={ref}
    />
  )
);
