import { useColorMode } from '@chakra-ui/react';

import { Flex } from 'shared_DEPRECATED/components/Flex';
import {
  FloatingPopover,
  PLACEMENTS,
} from 'shared_DEPRECATED/components/Floating';
import EmojiPicker from 'shared_DEPRECATED/components/Form/EmojiPicker';
import {
  ReactionsCommon,
  ReactionsPopoverButton,
} from 'shared_DEPRECATED/components/Reactions';
import { DEFAULT_REACTIONS } from 'shared_DEPRECATED/components/Reactions/config';
import useMultipleToggle from 'shared_DEPRECATED/hooks/useMultipleToggle';

const PICKER = {
  SMALL: 'smallPicker',
  BIG: 'bigPicker',
};

type TReactionEmojiPicker = {
  handleEmojiSelect: (emoji: string) => void;
  options?: any;
  emojis?: string[];
  theme?: 'light' | 'dark';
};

export const ReactionEmojiPicker = ({
  emojis = DEFAULT_REACTIONS,
  handleEmojiSelect,
  options,
  theme = 'light',
}: TReactionEmojiPicker) => {
  const { state, toggleAll, toggleOpen, clearAll } = useMultipleToggle({
    toggleNames: [PICKER.SMALL, PICKER.BIG],
  });

  const { colorMode: currentTheme } = useColorMode();

  const isThemeLight = currentTheme === 'light';
  const newTheme = isThemeLight ? 'light' : 'dark';

  return (
    <FloatingPopover
      onClose={clearAll}
      isOpen={state[PICKER.SMALL] || state[PICKER.BIG]}
      placement={PLACEMENTS.TOP}
      anchor={
        <ReactionsPopoverButton
          isClicked={state[PICKER.SMALL]}
          onClick={() => {
            state[PICKER.BIG]
              ? toggleOpen(PICKER.BIG)
              : toggleOpen(PICKER.SMALL);
          }}
          dataTestid="add-new-reaction-button"
          theme={theme}
        />
      }
      offsetTop={4}
      isAutoUpdate={true}
    >
      {state[PICKER.BIG] ? (
        <EmojiPicker
          onEmojiSelect={({ native: emoji }) => {
            toggleOpen(PICKER.BIG);
            handleEmojiSelect(emoji);
          }}
          options={{ ...options, theme: newTheme }}
        />
      ) : (
        <Flex>
          <ReactionsCommon
            emojis={emojis}
            onClick={(emoji) => {
              toggleOpen(PICKER.SMALL);
              handleEmojiSelect(emoji);
            }}
            toggleAll={toggleAll}
          />
        </Flex>
      )}
    </FloatingPopover>
  );
};
