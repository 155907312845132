import { Avatar, SIZES } from 'shared_DEPRECATED/components/Avatar';
import { Flex, FlexItem } from 'shared_DEPRECATED/components/Flex';
import { HotjarSuppression } from 'shared_DEPRECATED/components/Hotjar';
import Spacer from 'shared_DEPRECATED/components/Spacer';
import { dateUtils } from 'shared_DEPRECATED/utils';

import {
  useJournalDeleteReplyMutation,
  JournalRepliesCardHeaderMenu,
} from 'features/journal';

import { Typography } from 'shared/components/Typography';

type TJournalRepliesCardHeader = {
  name: string;
  pictureUrl: string;
  date: string;
  isAuthor: boolean;
  setEditedReply: () => void;
  replyId: string;
  relatedNoteId: string;
};
export const JournalRepliesCardHeader = ({
  name,
  pictureUrl,
  date,
  isAuthor,
  setEditedReply,
  replyId,
  relatedNoteId,
}: TJournalRepliesCardHeader) => {
  const { mutateAsync: deleteReply } = useJournalDeleteReplyMutation({
    noteId: relatedNoteId,
  });

  const handleDeleteReply = async () => {
    await deleteReply(replyId);
  };

  return (
    <Flex>
      <Flex justifyContent="flex-start">
        <FlexItem>
          <Avatar name={name} size={SIZES.SM} src={pictureUrl} />
        </FlexItem>
        <FlexItem>
          <Spacer size="zr zr zr md">
            <Spacer size="zr zr zr sm">
              <HotjarSuppression>
                <Flex justifyContent="flex-start">
                  <Spacer size="zr md zr zr">
                    <Typography
                      as="div"
                      fontWeight="semiBold"
                      type="small"
                      color="black"
                    >
                      {name}
                    </Typography>
                  </Spacer>
                  <Typography as="div" type="small" color="black">
                    {dateUtils.todayTimeAgoOrDate(date)}
                  </Typography>
                </Flex>
              </HotjarSuppression>
            </Spacer>
          </Spacer>
        </FlexItem>
      </Flex>
      {isAuthor && (
        <JournalRepliesCardHeaderMenu
          onDelete={handleDeleteReply}
          onEdit={() => {
            //@ts-ignore
            console.log(replyId) || setEditedReply();
          }}
        />
      )}
    </Flex>
  );
};
