import { useQueryClient } from '@tanstack/react-query';

import { queryKeys } from 'api/config';

export const useUpdateParticipantsQuery = ({ userEmail, fieldKey }) => {
  const queryClient = useQueryClient();

  return (fieldValue) => {
    queryClient.setQueryData(
      [queryKeys.participants],
      (data) =>
        data && {
          items: data.items.map((participant) =>
            participant.userEmail === userEmail
              ? { ...participant, [fieldKey]: fieldValue }
              : participant
          ),
        }
    );
  };
};
