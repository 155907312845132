import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { queryKeys } from 'api/config';
import { useQueryHTTPRequest } from 'api/hooks/useHTTPRequest';
import { getQueryString } from 'shared_DEPRECATED/utils';

import { JournalQueryParamsType, JournalNote } from 'features/journal';
import { useIsCoach } from 'features/user/hooks/useIsCoach';
import { useUserId } from 'features/user/hooks/useUserId';

export const useJournalNotesQuery = (
  filter: Partial<JournalQueryParamsType> = {}
) => {
  const { request } = useQueryHTTPRequest();
  const userId = useUserId();
  const queryParams = getQueryString(filter);
  const isCoach = useIsCoach();

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    refetch,
  } = useInfiniteQuery<JournalNotesResponse>(
    [queryKeys.journal, queryParams],
    async ({ pageParam = 0 }) => {
      const coachPrefix = isCoach ? `/coach/${userId}` : '';

      return await request({
        url: `/api${coachPrefix}/journal/notes?limit=20&offset=${pageParam}&${queryParams}`,
      });
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        const totalFetched = allPages.flatMap((page) => page.notes).length;

        return lastPage.notes.length === 20 ? totalFetched : undefined;
      },
      select: (data) => ({
        pages: data?.pages,
        pageParams: data.pageParams,
      }),
    }
  );

  const notes = useMemo(
    () => data?.pages.flatMap((page) => page.notes) || [],
    [data]
  );

  return {
    notes,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    refetch,
  };
};

type JournalNotesResponse = {
  notes: JournalNote[];
};
