import { Box, Button, Typography } from 'shared';

import { useJournalFilterContext } from 'features/journal/context';

export const JournalFilterHeader = () => {
  const { resetQueryParams } = useJournalFilterContext();

  return (
    <Box width="100%">
      {/* <Button onClick={() => {}} width="50%" size="lg">
      <Typography color="blue" type="meta" fontWeight="medium">
        Read all
      </Typography>
    </Button> */}
      <Button onClick={resetQueryParams} width="100%" size="lg">
        <Typography color="blue" type="meta" fontWeight="medium">
          Reset filters
        </Typography>
      </Button>
    </Box>
  );
};
