import { useMutation } from '@tanstack/react-query';

import { logError } from 'lib/sentry/logError';
import { useMutationHTTPRequest } from 'shared_DEPRECATED/hooks';

import { IChallenge } from 'features/challenge/config/types';
import { useUserId } from 'features/user/hooks/useUserId';

type TUseEditCustomChallengeMutation = {
  options?: any;
};

export const useEditCustomChallengeMutation = ({
  options,
}: TUseEditCustomChallengeMutation) => {
  const { request } = useMutationHTTPRequest();
  const userId = useUserId();

  return useMutation(
    (challenge: IChallenge) => {
      const id =
        'challengeId' in challenge
          ? challenge.challengeId
          : challenge.customChallengeId;

      return request({
        url: `/api/web/challenges/${id}/edit`,
        body: { ...challenge, clientId: userId },
      });
    },
    {
      ...options,
      onError: (err, ...args) => {
        options?.onError?.(err, ...args);
        logError(err);
      },
    }
  );
};
