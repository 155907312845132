import { useQuery } from '@tanstack/react-query';

import { queryKeys } from 'api/config';
import { useQueryHTTPRequest } from 'shared_DEPRECATED/hooks';

import { CoachProfile } from '../../types';

export const useCoachProfileQuery = () => {
  const { request } = useQueryHTTPRequest();
  const { data, isLoading, status } = useQuery<
    { coachProfile: CoachProfile },
    any,
    CoachProfile
  >([queryKeys.coachProfile], () => request({ url: '/api/web/me/profile' }), {
    select: (data) => ({
      ...data.coachProfile,
      knownLanguages: data.coachProfile.knownLanguages || [],
    }),
  });

  return { coachProfile: data, isLoading, status };
};
