import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';

import { queryKeys } from 'api/config';
import { useMutationHTTPRequest } from 'shared_DEPRECATED/hooks';

import { IChallenge } from 'features/challenge/config/types';

type MutationContext = {
  previousChallengeBacklogValue?: { items: IChallenge[] };
};

export const useDeletePlanningBacklogChallengesMutation = (): UseMutationResult<
  any,
  Error,
  string[],
  MutationContext | undefined
> => {
  const { request } = useMutationHTTPRequest();
  const queryClient = useQueryClient();

  return useMutation<any, Error, string[], MutationContext | undefined>(
    async (challengeIds: string[]) =>
      await request({
        url: `/api/web/sprint-planning/backlog/challenges/delete`,
        body: { challengeIds },
      }),
    {
      onMutate: async (challengeIds: string[]) => {
        await queryClient.cancelQueries({
          queryKey: [queryKeys.challenges],
        });

        const previousChallengeBacklogValue = queryClient.getQueryData<{
          items: IChallenge[];
        }>([queryKeys.challenges]);

        if (previousChallengeBacklogValue) {
          queryClient.setQueryData<{ items: IChallenge[] }>(
            [queryKeys.challenges],
            (oldVal) => ({
              ...oldVal,
              items: oldVal!.items.filter(
                (challenge) => !challengeIds.includes(challenge.challengeId!)
              ),
            })
          );
        }

        return { previousChallengeBacklogValue };
      },
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.challenges]);
      },
      onError: (err: Error, _: string[], context?: MutationContext) => {
        if (context?.previousChallengeBacklogValue) {
          queryClient.setQueryData<{ items: IChallenge[] }>(
            [queryKeys.challenges],
            context.previousChallengeBacklogValue
          );
        }
      },
    }
  );
};
