import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { queryKeys } from 'api/config';
import { produce } from 'immer';

import { JournalNote, JournalNoteReply } from 'features/journal/types';

export const useJournalQueryActions = () => {
  const queryClient = useQueryClient();

  const getJournalRelevantQueries = useCallback(() => {
    const journalNotesQueries = queryClient.getQueriesData([queryKeys.journal]);

    return journalNotesQueries.filter(
      ([key]) => Array.isArray(key) && key[1]?.includes('sprint_ids')
    );
  }, [queryClient]);

  const cancelJournalRelevantQueries = useCallback(async () => {
    const relevantQueries = getJournalRelevantQueries();

    await Promise.all(
      relevantQueries.map(([key]) => queryClient.cancelQueries(key))
    );
  }, [getJournalRelevantQueries, queryClient]);

  const updateJournalRelevantQueries = useCallback(
    (updateFn: (data: any) => any) => {
      const relevantQueries = getJournalRelevantQueries();

      relevantQueries.forEach(([key, data]: [any, any]) => {
        if (!data) {
          return;
        }

        queryClient.setQueryData(key, (oldData) =>
          produce(oldData, (draft) => {
            updateFn(draft);
          })
        );
      });

      return relevantQueries.map(([key, data]) => ({ key, data }));
    },
    [getJournalRelevantQueries, queryClient]
  );

  const cancelJournalNoteQueries = useCallback(
    async (noteId?: string) => {
      const queryKey = noteId
        ? [queryKeys.journal, noteId]
        : [queryKeys.journal];

      await queryClient.cancelQueries(queryKey);
    },
    [queryClient]
  );

  const getPreviousJournalNoteValue = useCallback(
    (noteId: string) => {
      return queryClient.getQueryData<{
        note: JournalNote;
        replies: JournalNoteReply[];
      }>([queryKeys.journal, noteId]);
    },
    [queryClient]
  );

  const updateJournalNoteQueryData = useCallback(
    (noteId: string, updateFn: (oldData: any) => any) => {
      queryClient.setQueryData([queryKeys.journal, noteId], (oldData) =>
        produce(oldData, (draft) => {
          if (draft) {
            updateFn(draft);
          }
        })
      );
    },
    [queryClient]
  );

  const updateNotesList = useCallback(
    (updateFn: (oldData: any) => any) => {
      queryClient.setQueryData([queryKeys.journal], (oldData) =>
        produce(oldData, (draft) => {
          updateFn(draft);
        })
      );
    },
    [queryClient]
  );

  return {
    getJournalRelevantQueries,
    cancelJournalRelevantQueries,
    updateJournalRelevantQueries,
    cancelJournalNoteQueries,
    getPreviousJournalNoteValue,
    updateJournalNoteQueryData,
    updateNotesList,
  };
};
