import { useCallback } from 'react';

import { useSprintsQueryActions } from 'features/sprint/hooks/query/sprints/useSprintsActions';

import {
  usePlanningBacklogQueryActions,
  usePlanningDraftSprintsQueryActions,
} from './query';

export const usePreviousPlanningValues = () => {
  const { cancelDraftSprintsQueries, getDraftSprints } =
    usePlanningDraftSprintsQueryActions();
  const { cancelBacklogQueries, getBacklog } = usePlanningBacklogQueryActions();
  const { cancelSprintsQueries, getSprints } = useSprintsQueryActions();

  return useCallback(() => {
    cancelDraftSprintsQueries();
    cancelBacklogQueries();
    cancelSprintsQueries();

    const previousBacklogValue = getBacklog();
    const previousDraftSprintsValue = getDraftSprints();
    const previousSprintsValue = getSprints();

    return {
      previousBacklogValue,
      previousDraftSprintsValue,
      previousSprintsValue,
    };
  }, [
    cancelDraftSprintsQueries,
    cancelBacklogQueries,
    cancelSprintsQueries,
    getDraftSprints,
    getBacklog,
    getSprints,
  ]);
};
