import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';

import { queryKeys } from 'api/config';
import { useQueryHTTPRequest } from 'api/hooks/useHTTPRequest';
import { useSidebarContext } from 'shared';

import { ChallengeTargetProgressType } from 'features/challenge/config/types';
import { useGetChallengeFrequencyText } from 'features/challenge/hooks/useGetFrequencyText';
import { useMomentumFeedContext } from 'features/momentum/context/feed';
import { selectMomentumFeed } from 'features/momentum/utils';
import { Sprint } from 'features/sprint';

export const useMomentumFeedQuery = () => {
  const { sprints } = useSidebarContext() as { sprints: Sprint[] };
  const { sprintId } = useMomentumFeedContext();
  const { request } = useQueryHTTPRequest();
  const getChallengeFrequencyText = useGetChallengeFrequencyText();
  const sprint = sprints.find((sprint) => sprint.sprintId === sprintId);

  return useQuery(
    [queryKeys.momentum, sprintId],
    async () =>
      await request({ url: `/api/sprints/${sprintId}/momentum/feed` }),
    {
      select: useCallback(
        (data: {
          challenges: {
            momentumDifference: Record<
              string,
              {
                currentValue: number;
                changeValue: number;
              }
            >;
            target: ChallengeTargetProgressType;
            title: string;
          }[];
        }) =>
          selectMomentumFeed(
            data,
            getChallengeFrequencyText,
            sprint?.weeksCount
          ),
        [getChallengeFrequencyText, sprint]
      ),
    }
  );
};
