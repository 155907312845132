import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { queryKeys } from 'api/config';
import { useQueryHTTPRequest } from 'shared_DEPRECATED/hooks';

import { CHALLENGE_TYPES } from 'features/challenge/config';
import { GoalBreakdownType } from 'features/goal/types';

export const useGoalBreakdownQuery = () => {
  const { goalId } = useParams();
  const { request } = useQueryHTTPRequest();

  return useQuery<GoalBreakdownType>(
    [queryKeys.goalBreakdown, goalId],
    () => request({ url: `/api/web/goals/${goalId}/breakdown` }),
    {
      enabled: !!goalId,
      select: (data) => ({
        ...data,
        challenges: data.challenges.map((challenge) => ({
          ...challenge,
          type:
            'dueDate' in challenge.target
              ? CHALLENGE_TYPES.NON_REPEATING
              : CHALLENGE_TYPES.REPEATING,
        })),
        focusAreas: data.focusAreas.map((focusArea) => ({
          ...focusArea,
          challenges: focusArea.challenges.map((challenge) => ({
            ...challenge,
            type:
              'dueDate' in challenge.target
                ? CHALLENGE_TYPES.NON_REPEATING
                : CHALLENGE_TYPES.REPEATING,
          })),
        })),
      }),
    }
  );
};
