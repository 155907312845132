import { ReactNode } from 'react';

import { Flex } from 'shared_DEPRECATED/components/Flex';

type TJournalCardFooter = {
  children: ReactNode;
};

export const JournalCardFooter = ({ children }: TJournalCardFooter) => (
  <Flex justifyContent="space-between" width="100%">
    {children}
  </Flex>
);
