import { IconButton } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import { Menu, MenuButton, MenuList, MenuItem, VARIANTS } from 'shared';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

import { MenuReadStatusButton } from 'features/challengeNotes/components/Card/MenuReadStatusButton';
import { NOTE_STATUS } from 'features/challengeNotes/config';
import { useChallengeEditModal } from 'features/challengeNotes/hooks/useChallengeEditModal';

const ChallengeNotesCardMenuPropTypes = {
  noteId: PropTypes.string.isRequired,
  noteReadStatus: PropTypes.oneOf(Object.values(NOTE_STATUS)),
  challengeId: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
};

export const ChallengeNotesCardMenu = ({
  noteId,
  noteReadStatus,
  challengeId = '',
  userEmail,
}) => {
  const openChallengeModal = useChallengeEditModal({ challengeId });

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<Icon name={iconNames.ellipsis} />}
        variant={VARIANTS.TERTIARY}
        size="sm"
        data-testid="user-challenge-note-menu"
      />
      <MenuList fontSize="small">
        <MenuReadStatusButton
          noteId={noteId}
          noteReadStatus={noteReadStatus}
          userEmail={userEmail}
        />
        {Boolean(challengeId) && (
          <MenuItem onClick={openChallengeModal}>Edit challenge</MenuItem>
        )}
      </MenuList>
    </Menu>
  );
};

ChallengeNotesCardMenu.propTypes = ChallengeNotesCardMenuPropTypes;
