import { openJournalSidebar } from 'features/journal';

import { SidebarSubHeader, useSidebarContext } from 'shared/components/Sidebar';

export const JournalRepliesHeader = () => {
  const { user } = useSidebarContext();

  const handleBackBtnClick = () => {
    openJournalSidebar({ user });
  };

  return (
    <SidebarSubHeader
      mainSidebarTitle="Journal"
      onBackButtonClick={handleBackBtnClick}
    />
  );
};
