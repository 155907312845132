import Spacer from 'shared_DEPRECATED/components/Spacer';

import { Typography } from 'shared/components/Typography';

type TPlanningBoardColumnItemsCountProps = {
  count: number;
  name: 'challenge' | 'coaching tool';
};

export const PlanningBoardColumnItemsCount = ({
  count,
  name,
}: TPlanningBoardColumnItemsCountProps) => {
  return count > 0 ? (
    <Spacer size="md">
      <Typography type="small" color="gray">
        {count} {name}
        {count > 1 ? 's' : ''}
      </Typography>
    </Spacer>
  ) : null;
};
