import { Fragment } from 'react';

import { Box } from 'shared';
import { isImage } from 'shared_DEPRECATED/components/File/utils';

import { NoteMediaType } from 'features/challenge/config/types';
import {
  JournalCardImageAttachment,
  JournalCardVideoAttachment,
} from 'features/journal';

type TJournalCardMediaList = {
  mediaList: NoteMediaType[];
};
export const JournalCardMediaList = ({ mediaList }: TJournalCardMediaList) => (
  <Box
    as="div"
    style={{
      float: 'right',
      marginRight: '0',
    }}
  >
    {mediaList.map(({ url, contentType, thumbnail }) => {
      return (
        <Fragment key={url}>
          {isImage(contentType) ? (
            <JournalCardImageAttachment src={url} />
          ) : (
            <JournalCardVideoAttachment
              src={url}
              previewSrc={thumbnail?.url ?? ''}
            />
          )}
        </Fragment>
      );
    })}
  </Box>
);
