import { ReactNode } from 'react';

import { Typography } from 'shared';

type TJournalFilterSectionLabel = {
  children: ReactNode;
};

export const JournalFilterSectionLabel = ({
  children,
}: TJournalFilterSectionLabel) => (
  <Typography color="gray" type="meta">
    {children}
  </Typography>
);
