import { useCallback } from 'react';

import { useMutationHTTPRequest } from 'api/hooks/useHTTPRequest';

export const useAddCoachingToolMutateFunction = () => {
  const { request } = useMutationHTTPRequest();

  return useCallback(
    ({ toolId, sprintId }: { toolId: string; sprintId: string }) =>
      request({
        url: `/api/web/sprint-planning/coaching-tools/add`,
        body: { toolId, sprintId },
      }),
    [request]
  );
};
