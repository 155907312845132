import { useQuery } from '@tanstack/react-query';

import { queryKeys } from 'api/config';
import { useQueryHTTPRequest } from 'api/hooks/useHTTPRequest';

const useSprintHistoryQuery = ({ sprintId }) => {
  const { request } = useQueryHTTPRequest();
  const { data, isFetching } = useQuery(
    [queryKeys.history, sprintId],
    () => request({ url: `/api/sprints/${sprintId}/history` }),
    { initialData: { items: [] } }
  );

  return { history: data.items, isFetching };
};

export default useSprintHistoryQuery;
