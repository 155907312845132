import { ReactNode } from 'react';

import { Typography } from 'shared/components/Typography';

type TJournalCardTitle = {
  children: ReactNode;
};

export const JournalCardTitle = ({ children }: TJournalCardTitle) => (
  <Typography type="small" fontWeight="semiBold" color="black">
    {children}
  </Typography>
);
