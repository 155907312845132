import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';
import { toastErrorConfig } from 'lib/reactToastify/config';
import { toast } from 'react-toastify';

import { HTTP_ERROR_STATUS_CODES } from 'api/config';
import { Nullable } from 'shared_DEPRECATED/types';

let toastId: Nullable<string | number> = null;

export const initReactQuery = () =>
  new QueryClient({
    queryCache: new QueryCache({
      onError: () => {
        dismissErrorToast();
      },
      onSuccess: () => {
        dismissErrorToast();
      },
    }),
    mutationCache: new MutationCache({
      onError: () => {
        dismissErrorToast();
      },
      onSuccess: () => {
        dismissErrorToast();
      },
    }),
    defaultOptions: {
      mutations: {
        // this settings is the closest to the v3 behaviour https://tkdodo.eu/blog/offline-react-query#offlinefirst
        networkMode: 'offlineFirst',
        retry: (failureCount, error) => {
          if (error instanceof Error && isInfrastructureError(error)) {
            renderInfrastructureErrorToast();

            // Considering that react-query uses exponential backoff using timeout formula Math.min(1000 * 2 ** attemptIndex, 30000),
            // 10 retries will take 3.5 minutes which should be enough since the server should be back up by approximately 2 minutes
            return failureCount < 10;
          }

          return failureCount < 3;
        },
      },
      queries: {
        // this settings is the closest to the v3 behaviour
        networkMode: 'offlineFirst',
        useErrorBoundary: true,
        retry: (failureCount, error) => {
          if (
            error instanceof Error &&
            error?.message === HTTP_ERROR_STATUS_CODES.NOT_FOUND
          ) {
            return false;
          }

          if (error instanceof Error && isInfrastructureError(error)) {
            renderInfrastructureErrorToast();

            // Considering that react-query uses exponential backoff using timeout formula Math.min(1000 * 2 ** attemptIndex, 30000),
            // 10 retries will take 3.5 minutes which should be enough since the server should be back up by approximately 2 minutes
            return failureCount < 10;
          }

          return failureCount < 3;
        },
      },
    },
  });

const isInfrastructureError = (error: Error) =>
  error.message === HTTP_ERROR_STATUS_CODES.GATEWAY_TIMEOUT ||
  error.message === HTTP_ERROR_STATUS_CODES.SERVICE_UNAVAILABLE ||
  error.message === HTTP_ERROR_STATUS_CODES.BAD_GATEWAY;

const dismissErrorToast = () => {
  if (toastId) {
    toast.dismiss(toastId);
    toastId = null;
  }
};

const renderInfrastructureErrorToast = () => {
  if (!toast.isActive('error-toast')) {
    toast.error('Server is not responding. Please try again later.', {
      ...toastErrorConfig,
      autoClose: false,
    });
  }
};
