import { useQuery } from '@tanstack/react-query';

import { useQueryHTTPRequest } from 'shared_DEPRECATED/hooks';

import { Tool } from 'features/sessions/config/types';

export const useCoachingToolsQuery = () => {
  const { request } = useQueryHTTPRequest();

  return useQuery<{ coachingTools: Tool[] }, unknown, Tool[]>(
    ['coaching-tools'],
    () => request({ url: '/api/bootstrap' }),
    {
      select: (data) => data.coachingTools,
    }
  );
};
