import { ReactNode } from 'react';

import Loader from 'shared_DEPRECATED/components/Loader/Loader';

import { JournalNotesEmptyState, useJournalContext } from 'features/journal';

type TJournalNotesContainer = {
  children: ReactNode;
};

export const JournalNotesContainer = ({ children }: TJournalNotesContainer) => {
  const { isLoading, notes } = useJournalContext();

  if (isLoading || !notes) {
    return <Loader />;
  }

  if (notes.length === 0) {
    return <JournalNotesEmptyState text="No matches found" />;
  }

  return <>{children}</>;
};
