import React, { ReactNode, useContext, useEffect, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';

import {
  JournalNote,
  useJournalFilterContext,
  useJournalNotesQuery,
} from 'features/journal';

type JournalContextType = {
  notes: JournalNote[];
  isLoading: boolean;
  noNotes: boolean;
  scrollRef: any;
  isFetchingNextPage: boolean;
};

const JournalContext = React.createContext<JournalContextType>({
  notes: [],
  isLoading: false,
  noNotes: true,
  scrollRef: null,
  isFetchingNextPage: false,
});

type JournalProviderProps = { children: ReactNode };

export const JournalProvider = ({ children }: JournalProviderProps) => {
  const { queryParams } = useJournalFilterContext();
  const { ref: scrollRef, inView } = useInView();
  const {
    notes,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    refetch,
  } = useJournalNotesQuery(queryParams);

  const noNotes = !notes || notes.length === 0;

  useEffect(() => {
    refetch();
  }, [queryParams, refetch]);

  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, isFetchingNextPage, fetchNextPage]);

  const providerValue = useMemo(
    () => ({
      notes,
      isLoading,
      noNotes,
      isFetchingNextPage,
      scrollRef,
    }),
    [notes, isLoading, noNotes, scrollRef, isFetchingNextPage]
  );

  return (
    <JournalContext.Provider value={providerValue}>
      {children}
    </JournalContext.Provider>
  );
};

export const useJournalContext = () => {
  const useJournalContext = useContext(JournalContext);

  if (!useJournalContext) {
    throw new Error(
      'useJournalContext must be used in JournalContext.Provider'
    );
  }

  return useJournalContext;
};
