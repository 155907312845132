import { useMutation } from '@tanstack/react-query';

import { useMutationHTTPRequest } from 'api/hooks/useHTTPRequest';
import { logError } from 'lib/sentry/logError';

export const useReplyReactionsMutation = ({
  noteId,
  replyId,
  noteOwnerId,
  onMutate,
  onSuccess,
  onError,
}) => {
  const { request } = useMutationHTTPRequest();

  return useMutation(
    async ({ emoji, type }) =>
      request({
        url: `/api/notes/${noteId}/replies/${replyId}/reactions/${type.toLowerCase()}`,
        body: {
          emoji,
          noteOwnerId,
        },
      }),
    {
      onMutate,
      onSuccess,
      onError: (err, __, context) => {
        onError(err, __, context);
        logError(err);
      },
    }
  );
};
