import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { queryKeys } from 'api/config';
import { useMutationHTTPRequest } from 'shared_DEPRECATED/hooks';

import { SessionRecapType } from 'features/sessions/config/types';

export const useSessionRecapMutation = (sessionId: string) => {
  const { email: userEmail } = useParams();
  const queryClient = useQueryClient();
  const { request } = useMutationHTTPRequest();

  return useMutation(
    (recapData: SessionRecapType) =>
      request({
        url: `/api/coach/coaching-sessions/${sessionId}/send-recap`,
        body: recapData,
      }),
    {
      onSuccess: (recap) => {
        queryClient.setQueryData(
          [queryKeys.coachSession, sessionId],
          (oldSession: any) =>
            oldSession && {
              ...oldSession,
              recap,
            }
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries([queryKeys.coachSessions, userEmail]);
      },
    }
  );
};
