import { useQuery } from '@tanstack/react-query';

import { queryKeys } from 'api/config';
import { useQueryHTTPRequest } from 'shared_DEPRECATED/hooks';

import { ColumnsType } from 'features/goal/types';
import { useUserId } from 'features/user/hooks/useUserId';

export const useGoalsTableQuery = () => {
  const { request } = useQueryHTTPRequest();
  const userId = useUserId();

  return useQuery<{ columns: ColumnsType }, Error, { columns: ColumnsType }>(
    [queryKeys.goals, userId],
    () => request({ url: `/api/web/roadmaps/${userId}` }),
    {
      enabled: !!userId,
    }
  );
};
