import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useMutationHTTPRequest } from 'api/hooks/useHTTPRequest';
import { logError } from 'lib/sentry/logError';
import { useSidebarContext } from 'shared';

import {
  createTemporaryJournalReaction,
  JournalNote,
  useJournalQueryActions,
  useReactionLoadingState,
} from 'features/journal';
import { User } from 'features/user/User';

import { showErrorToast } from 'shared/components/Toast';

export const useCreateJournalNoteReaction = () => {
  const { request } = useMutationHTTPRequest();
  const { user } = useSidebarContext() as { user: User };

  const queryClient = useQueryClient();
  const {
    getJournalRelevantQueries,
    cancelJournalRelevantQueries,
    cancelJournalNoteQueries,
    getPreviousJournalNoteValue,
    updateJournalNoteQueryData,
    updateJournalRelevantQueries,
  } = useJournalQueryActions();

  const { setReactionLoadingState } = useReactionLoadingState();

  return useMutation(
    async ({ reaction, noteId }: MutationFnPropType) =>
      await request({
        url: `/api/journal/notes/${noteId}/reactions/create`,
        body: { reaction },
      }),
    {
      onMutate: ({ reaction, noteId }: MutationFnPropType) => {
        setReactionLoadingState(noteId, true);

        const updatedReaction = createTemporaryJournalReaction({
          user,
          reaction,
        });

        const relevantQueries = getJournalRelevantQueries();

        cancelJournalRelevantQueries();

        const previousJournalNotesData = relevantQueries.map(([key, data]) => ({
          key,
          data,
        }));

        updateJournalRelevantQueries((oldData) => {
          oldData.pages = oldData.pages.map((page: any) => ({
            ...page,
            notes: page.notes.map((note: JournalNote) => {
              if (note.noteId === noteId) {
                note.reactions.push(updatedReaction);
              }
              return note;
            }),
          }));
        });

        cancelJournalNoteQueries(noteId);

        const previousJournalNoteData = getPreviousJournalNoteValue(noteId);

        updateJournalNoteQueryData(noteId, (old) => {
          old.note.reactions = [...old.note.reactions, updatedReaction];
        });

        return { previousJournalNotesData, previousJournalNoteData };
      },
      onError: (err, { noteId }, context) => {
        setReactionLoadingState(noteId, false);

        showErrorToast(
          "There's an error with adding reaction. Please try again later."
        );

        context?.previousJournalNotesData.forEach(({ key, data }: any) => {
          queryClient.setQueryData(key, data);
        });

        updateJournalNoteQueryData(
          noteId,
          () => context?.previousJournalNoteData
        );

        logError(err);
      },

      onSuccess: (newNote: JournalNote, { noteId }) => {
        setReactionLoadingState(noteId, false);

        updateJournalRelevantQueries((oldData) => {
          oldData.pages = oldData.pages.map(
            (page: { notes: JournalNote[] }) => ({
              ...page,
              notes: page.notes.map((note) => {
                if (note.noteId === noteId) {
                  return newNote;
                }

                return note;
              }),
            })
          );
        });

        updateJournalNoteQueryData(noteId, () => newNote);
      },
    }
  );
};

type MutationFnPropType = { reaction: string; noteId: string };
