import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { queryKeys } from 'api/config';
import { logError } from 'lib/sentry/logError';
import { useMutationHTTPRequest } from 'shared_DEPRECATED/hooks';

import { GoalBreakdownType } from 'features/goal';

export const useEditGoalFocusAreasMutation = () => {
  const { goalId } = useParams();

  const { request } = useMutationHTTPRequest();
  const queryClient = useQueryClient();

  return useMutation(
    ({ focusAreaId, title }: { focusAreaId: string; title: string }) =>
      request({
        url: `/api/web/goals/${goalId}/breakdown/focus-areas/edit`,
        body: { focusAreaId, title },
      }),
    {
      onMutate: async ({ title, focusAreaId }) => {
        await queryClient.cancelQueries({
          queryKey: [queryKeys.goalBreakdown, goalId],
        });

        const previousValue = queryClient.getQueryData([
          queryKeys.goalBreakdown,
          goalId,
        ]);

        queryClient.setQueryData<GoalBreakdownType>(
          [queryKeys.goalBreakdown, goalId],
          (oldVal) => ({
            ...oldVal!,
            focusAreas: oldVal!.focusAreas.map((focusArea) =>
              focusAreaId === focusArea.id ? { ...focusArea, title } : focusArea
            ),
          })
        );

        return { previousValue };
      },
      onSettled: () => {
        queryClient.invalidateQueries([queryKeys.goalBreakdown, goalId]);
      },
      onError: (err) => {
        logError(err);
      },
    }
  );
};
