export const COACH_SEARCH_ENABLED = 'coachSearchEnabled';
export const SPRINT_DURATION = 'sprintDuration';
export const PLANNING_FEATURE_FLAG = 'sprintPlanning';
export const SPRINT_AUTOSAVE_FEATURE_FLAG = 'webSprintAutosave';
export const COACH_INSIGHTS_FEATURE_FLAG = 'coachInsights';
export const CHAT_MESSAGES_SCHEDULING = 'chatMessagesScheduling';
export const COACHING_TOOLS_FEATURE_FLAG = 'coachingTools';
export const GOALS_DND_FEATURE_FLAG = 'webGoalsDnd';
export const SESSION_RECAP_PREVIEW_FEATURE_FLAG = 'webSessionRecapPreview';
export const GOAL_BREAKDOWN_DND_FEATURE_FLAG = 'goalBreakdownDnd';
export const SPRINT_PLANNING_DND = 'webDragndropForSprintPlanning';
export const IMPROVED_GROUP_VIEW_FEATURE_FLAG = 'improvedGroupView';
export const SESSIONS_IN_CHAT_FEATURE_FLAG = 'sessionsInChat';
export const GOAL_DEEP_DIVE_MARKDOWN_FEATURE_FLAG = 'goalDeepDiveMarkdown';
export const JOURNAL_FEATURE_FLAG = 'webJournal';
