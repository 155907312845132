import { useMutation } from '@tanstack/react-query';

import { useMutationHTTPRequest } from 'api/hooks/useHTTPRequest';
import { logError } from 'lib/sentry/logError';
import { ACTION_TYPES } from 'shared_DEPRECATED/components/Reactions/config';

export const useNoteReactionsMutation = ({
  noteId,
  userEmail,
  onMutate,
  onError,
  onSuccess,
}) => {
  const { request } = useMutationHTTPRequest();

  return useMutation(
    async ({ emoji, type }) =>
      request({
        url: `/api/challenges/notes/${noteId}/reactions/${type.toLowerCase()}`,
        body: {
          emoji,
          ownerEmail: userEmail,
        },
      }),
    {
      onMutate,
      onError: (err, __, context) => {
        onError(err, __, context);
        logError(err);
      },
      onSuccess: (data, context) => {
        // used for sending analytics event for note and reply reactions only when reaction is added
        if (context.type.toUpperCase() === ACTION_TYPES.ADD) {
          onSuccess();
        }
      },
    }
  );
};
