import {
  Popover,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  VStack,
} from '@chakra-ui/react';

import {
  JournalFilterAnchor,
  JournalFilterBySprint,
  JournalFilterHeader,
  JournalSprintFilterProvider,
} from 'features/journal';

export const JournalFilter = () => (
  <Popover placement="bottom-start">
    <JournalFilterAnchor />
    <PopoverContent
      minWidth="22rem"
      maxWidth="22rem"
      overflow="scroll"
      maxH="636px"
    >
      <PopoverHeader p={0}>
        <JournalFilterHeader />
      </PopoverHeader>
      <PopoverBody>
        <VStack alignItems="flex-start" gap="8px">
          <JournalSprintFilterProvider>
            <JournalFilterBySprint />
          </JournalSprintFilterProvider>
        </VStack>
      </PopoverBody>
    </PopoverContent>
  </Popover>
);
