import { Button as ChakraButton, ButtonProps } from '@chakra-ui/react';
import { forwardRef, ReactNode } from 'react';

import { VARIANTS } from 'shared/components/Button/config';

export type ButtonPropTypes = {
  ariaLabel?: string;
  children?: ReactNode;
  variant?: typeof VARIANTS[keyof typeof VARIANTS];
  dataTestid?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  onMouseEnter?: React.MouseEventHandler<HTMLButtonElement>;
  role?: string;
  type?: 'button' | 'reset' | 'submit';
  value?: string | number | readonly string[];
} & ButtonProps;

export const Button = forwardRef<HTMLButtonElement, ButtonPropTypes>(
  (
    {
      ariaLabel,
      children,
      disabled = false,
      onClick,
      onMouseEnter,
      role = 'button',
      type = 'button',
      value = 'default',
      variant = VARIANTS.TERTIARY,
      dataTestid = '',
      ...props
    }: ButtonPropTypes,
    ref
  ) => {
    return (
      <ChakraButton
        ref={ref}
        aria-label={ariaLabel}
        type={type}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        value={value}
        data-testid={dataTestid}
        role={role}
        variant={variant}
        isDisabled={disabled}
        {...props}
      >
        {children}
      </ChakraButton>
    );
  }
);
