import { useMutation } from '@tanstack/react-query';

import { useMutationHTTPRequest } from 'shared_DEPRECATED/hooks';

import {
  useGoalsUpdateQuery,
  useGoalUpdateQuery,
  IGoal,
  useGoalPageMatcher,
} from 'features/goal';
import { useUserId } from 'features/user/hooks/useUserId';

type TUseEditGoalMutation = {
  goalId: string;
  modifiedColumnId: string;
};

export const useEditGoalMutation = ({
  goalId,
  modifiedColumnId,
}: TUseEditGoalMutation) => {
  const { request } = useMutationHTTPRequest();

  const isUserInTheGoalPage = useGoalPageMatcher();

  const userId = useUserId();

  const updateGoalQuery = useGoalUpdateQuery({ goalId });
  const updateGoalsQuery = useGoalsUpdateQuery({
    userId,
    modifiedColumnId,
  });

  return useMutation(
    (goal: IGoal) =>
      request({
        url: `/api/web/goals/${goal.goalId}/edit`,
        body: { ...goal, userId },
      }),
    {
      onMutate: (updatedGoal: IGoal) => {
        const updateQuery = isUserInTheGoalPage
          ? updateGoalQuery
          : updateGoalsQuery.updateGoal;

        return updateQuery(updatedGoal);
      },
    }
  );
};
