import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import TextareaAutosize from 'react-textarea-autosize';

import { Flex, Box, Button, Typography } from 'shared';
import { Avatar, SIZES } from 'shared_DEPRECATED/components/Avatar';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { User } from 'features/user/User';

type TJournalEditForm = {
  text: string;
  onCancel: () => void;
  onSave: ({ text }: { text: string }) => Promise<void>;
  user: User;
  itemType: 'note' | 'reply';
};

export const JournalEditForm = ({
  itemType,
  text,
  onCancel,
  onSave,
  user,
}: TJournalEditForm) => {
  const { handleSubmit, register, setValue, reset, formState } = useForm({
    defaultValues: { text },
    mode: 'onChange',
  });

  const handleSave = (formValues: { text: string }) => {
    if (formState.errors.text) return;
    onSave({ text: formValues.text });
    reset();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (!event.shiftKey && event.key === 'Enter') {
      event.preventDefault();
      handleSubmit(handleSave)();
    }
  };

  useEffect(() => {
    setValue('text', text);
  }, [text, setValue]);

  return (
    <Box>
      <Flex justifyContent="space-between" alignItems="center" mb={2}>
        <Typography type="meta" color="blue">
          Editing {itemType}
        </Typography>
        <Button onClick={onCancel}>
          <Icon
            name={iconNames.cross}
            width={12}
            height={12}
            stroke="var(--fgPrimary)"
          />
        </Button>
      </Flex>
      <Flex alignItems="flex-end">
        <Spacer size="sm">
          <Avatar
            size={SIZES.SM}
            name={user.name || user.email}
            src={user.picture.url}
          />
        </Spacer>
        <Box flex="1">
          <TextareaAutosize
            style={{
              width: '100%',
              border: 'none',
              outline: 'none',
              resize: 'none',
              fontSize: 'var(--font-size-medium)',
              backgroundColor: 'transparent',
              color: 'var(--fgPrimary)',
            }}
            placeholder="Edit text"
            maxRows={4}
            onKeyDown={handleKeyDown}
            {...register('text', {
              required: 'Text is required',
              setValueAs: (value) => value.trim(),
            })}
          />
        </Box>
        <Button onClick={handleSubmit(handleSave)}>Save</Button>
      </Flex>
    </Box>
  );
};
