import { useMutation, useQueryClient } from '@tanstack/react-query';

import { queryKeys } from 'api/config';
import { produce } from 'immer';
import { logError } from 'lib/sentry/logError';

import { ICoachingTool } from 'features/coachingTool';
import {
  IPlanningDraftSprint,
  usePlanningDraftSprintsQueryActions,
} from 'features/planning';
import { useUserId } from 'features/user/hooks/useUserId';

import { showErrorToast } from 'shared/components/Toast';

import { useAddCoachingToolMutateFunction } from './useAddCoachingToolMutateFunction';

export const useAddPlanningCoachingToolToDraftSprintMutation = () => {
  const queryClient = useQueryClient();
  const userId = useUserId();

  const mutateFunc = useAddCoachingToolMutateFunction();

  const {
    cancelDraftSprintsQueries,
    getDraftSprints,
    updateDraftSprints,
    invalidateDraftSprintsQuery,
  } = usePlanningDraftSprintsQueryActions();

  return useMutation(mutateFunc, {
    onMutate: async ({ toolId, sprintId }) => {
      await cancelDraftSprintsQueries();

      const previousDraftSprintsValue = getDraftSprints();
      const prevSprintItems = previousDraftSprintsValue!.items;

      const coachingTools = queryClient.getQueryData<{
        items: ICoachingTool[];
      }>([queryKeys.coachingTools]);

      const coachingTool = coachingTools?.items.find(({ id }) => id === toolId);

      if (previousDraftSprintsValue && coachingTool) {
        const sprintIndex = prevSprintItems.findIndex(
          (sprint) => sprint.sprintId === sprintId
        );

        if (sprintIndex !== -1) {
          const updatedSprints = produce(prevSprintItems, (draft) => {
            draft[sprintIndex].coachingTools.push(coachingTool);
          });

          updateDraftSprints(updatedSprints);
        }
      }

      return { previousDraftSprintsValue };
    },
    onError: (
      err: Error,
      __: any,
      context:
        | {
            previousDraftSprintsValue?: {
              items: IPlanningDraftSprint[];
            };
          }
        | undefined
    ) => {
      if (context?.previousDraftSprintsValue) {
        queryClient.setQueryData(
          [queryKeys.draftSprints, userId],
          context.previousDraftSprintsValue
        );
      }
      logError(err);
      showErrorToast('Failed to add coaching tool.');
    },
    onSuccess: () => {
      invalidateDraftSprintsQuery();
    },
  });
};
