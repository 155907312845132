import { toastConfig, toastErrorConfig } from 'lib/reactToastify/config';
import { toast, ToastContent, ToastOptions } from 'react-toastify';

export const showSuccessToast = (
  content: ToastContent<unknown>,
  options?: ToastOptions<{}> | undefined
) => {
  return toast.success(content, {
    ...toastConfig,
    ...options,
  });
};

export const showErrorToast = (
  content: ToastContent<unknown>,
  options?: ToastOptions<{}> | undefined
) => {
  return toast.error(content, {
    ...toastErrorConfig,
    ...options,
  });
};
