import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { queryKeys } from 'api/config';

export const useParticipantsFeatureFlag = (featureFlagKey: string) => {
  const { email } = useParams();
  const queryClient = useQueryClient();

  const participant = queryClient.getQueryData<{
    featureFlags: {
      [key: string]: any;
    };
  }>([queryKeys.participant, email]);

  return participant?.featureFlags?.[featureFlagKey] ?? false;
};
