import { IconButton, Menu, MenuList } from '@chakra-ui/react';
import { createPortal } from 'react-dom';

import { MenuButton, VARIANTS } from 'shared';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { IChallenge } from 'features/challenge/config/types';
import {
  PlanningDraftSprintMenuEditButton,
  PlanningDraftSprintMenuRemoveButton,
  IPlanningDraftSprint,
} from 'features/planning';

type TPlanningChallengeCardMenu = {
  challenge: IChallenge;
  sprint: IPlanningDraftSprint;
};

export const PlanningDraftSprintsItemMenu = ({
  challenge,
  sprint,
}: TPlanningChallengeCardMenu) => (
  <Menu placement="bottom-end" offset={[-10, 0]} autoSelect={false}>
    <MenuButton
      as={IconButton}
      icon={<Icon name={iconNames.ellipsisHorizontal} />}
      size="sm"
      variant={VARIANTS.TERTIARY}
    />
    {createPortal(
      <MenuList p="0">
        <Spacer size="sm" />
        <PlanningDraftSprintMenuEditButton
          sprint={sprint}
          challenge={challenge}
        />
        <PlanningDraftSprintMenuRemoveButton
          sprint={sprint}
          challengeId={challenge.challengeId!}
        />
        <Spacer size="sm" />
      </MenuList>,
      document.body
    )}
  </Menu>
);
