import { useMutation, useQueryClient } from '@tanstack/react-query';

import { queryKeys } from 'api/config';
import { useMutationHTTPRequest } from 'shared_DEPRECATED/hooks';
import { removeFromArrayById } from 'shared_DEPRECATED/utils';

import { usePlanningDraftSprintsQueryActions } from 'features/planning';
import { useUserId } from 'features/user/hooks/useUserId';

type TUseDeletePlanningDraftSprint = {
  sprintId: string;
};

export const useDeletePlanningDraftSprint = ({
  sprintId,
}: TUseDeletePlanningDraftSprint) => {
  const { request } = useMutationHTTPRequest();
  const queryClient = useQueryClient();
  const userId = useUserId();

  const {
    cancelDraftSprintsQueries,
    getDraftSprints,
    updateDraftSprints,
    invalidateDraftSprintsQuery,
  } = usePlanningDraftSprintsQueryActions();

  return useMutation(
    () =>
      request({
        url: `/api/draft-sprints/${sprintId}/delete`,
        body: {},
      }),
    {
      onMutate: async () => {
        await cancelDraftSprintsQueries();

        const previousDraftSprintsValue = getDraftSprints();

        if (previousDraftSprintsValue) {
          const updatedItems = removeFromArrayById({
            array: previousDraftSprintsValue!.items,
            id: sprintId,
            idProperty: 'sprintId',
          });

          updateDraftSprints(updatedItems);
        }

        return { previousDraftSprintsValue };
      },
      onError: (err, _, context) => {
        if (context?.previousDraftSprintsValue) {
          queryClient.setQueryData(
            [queryKeys.draftSprints, userId],
            context.previousDraftSprintsValue
          );
        }
      },
      onSettled: () => {
        invalidateDraftSprintsQuery();
      },
    }
  );
};
