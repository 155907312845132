import {
  SESSIONS_IN_CHAT_FEATURE_FLAG,
  useFeatureFlag,
  useParticipantsFeatureFlag,
} from 'featureFlags';

import { TextEditorFormats } from 'shared/components/TextEditor/constants';

//TODO remove this hook after we completely migrate to json
export const useSessionTextEditorFormat = () => {
  const isSessionsInChatFeatureFlagEnabledForCoach = useFeatureFlag(
    SESSIONS_IN_CHAT_FEATURE_FLAG
  );
  const isSessionsInChatFeatureFlagEnabledForParticipant =
    useParticipantsFeatureFlag(SESSIONS_IN_CHAT_FEATURE_FLAG);

  return isSessionsInChatFeatureFlagEnabledForCoach &&
    isSessionsInChatFeatureFlagEnabledForParticipant
    ? TextEditorFormats.json
    : TextEditorFormats.html;
};
