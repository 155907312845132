import { useQueryClient } from '@tanstack/react-query';
import { toastConfig } from 'lib/reactToastify/config';
import { toast } from 'react-toastify';

import { queryKeys } from 'api/config';

import {
  DEFAULT_DRAFT_SPRINTS_COUNT,
  useDeletePlanningDraftSprint,
  useOpenPlanningDeleteDraftSprintDialog,
  usePlanningAnalyticsEvents,
} from 'features/planning';
import { IPlanningDraftSprint } from 'features/planning/types';
import { useUserId } from 'features/user/hooks/useUserId';

type TUsePlanningDraftSprintDelete = {
  sprint: IPlanningDraftSprint;
};

export const usePlanningDraftSprintDelete = ({
  sprint,
}: TUsePlanningDraftSprintDelete) => {
  const { sendPlanningSprintDraftDeletedEvent } = usePlanningAnalyticsEvents();
  const userId = useUserId();

  const queryClient = useQueryClient();

  const previousDraftSprintsValue = queryClient.getQueryData<{
    items: IPlanningDraftSprint[];
  }>([queryKeys.draftSprints, userId]);

  const isMinimumDraftSprintsCountLeft =
    previousDraftSprintsValue &&
    previousDraftSprintsValue.items?.length <= DEFAULT_DRAFT_SPRINTS_COUNT;

  const isSprintWithoutChallenges = !sprint.challenges.length;

  const { mutateAsync: deleteDraftSprint } = useDeletePlanningDraftSprint({
    sprintId: sprint.sprintId,
  });

  const onSubmit = async () => {
    if (isMinimumDraftSprintsCountLeft) {
      toast.error(
        'Please note that a minimum of 3 draft sprints is required',
        toastConfig
      );

      return;
    }

    const toastId = toast.loading('Deleting draft sprint...', toastConfig);

    try {
      await deleteDraftSprint();
      sendPlanningSprintDraftDeletedEvent();

      toast.update(toastId, {
        render: 'Draft sprint has been successfully deleted.',
        type: 'success',
        isLoading: false,
        icon: false,
        ...toastConfig,
      });
    } catch (err) {
      toast.error(
        "There's an error with deleting the Draft sprint. Please try again later.",
        toastConfig
      );
    }
  };

  const openDeleteDraftDialog = useOpenPlanningDeleteDraftSprintDialog();

  return () =>
    isSprintWithoutChallenges
      ? onSubmit()
      : openDeleteDraftDialog({
          onSubmit,
        });
};
