import {
  GoalDeepDiveFormSection,
  GoalDeepDiveFormEditor,
  GoalDeepDiveFormDescription,
} from 'features/goal';

export const GoalDeepDiveFormObstaclesSection = () => (
  <GoalDeepDiveFormSection step={3} title={'Obstacles'}>
    <GoalDeepDiveFormDescription
      highlightedText="Defining the obstacles:"
      text="What might make this hard or get in the way?"
    />
    <GoalDeepDiveFormEditor keyField="deepDive.obstacles" />
    <GoalDeepDiveFormDescription
      highlightedText="Dealing with obstacles:"
      text="Think about how you are going to deal with these obstacles using the
          template: When “obstacle”, then “solution”."
    />
    <GoalDeepDiveFormEditor keyField="deepDive.dealingWithObstacles" />
  </GoalDeepDiveFormSection>
);
