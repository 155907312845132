import { useState } from 'react';
import Truncate from 'react-truncate-markup';

import Button from 'shared_DEPRECATED/components/Button';

import { Typography } from 'shared/components/Typography';

//TODO: create TextTruncate shared component 1204004602812536

type TJournalCardText = {
  text: string;
};

export const JournalCardText = ({ text }: TJournalCardText) => {
  const [isTruncated, setIsTruncated] = useState(true);
  const ReadMoreEllipsis = (
    <>
      ...
      <br />
      <Button onClick={() => setIsTruncated(false)}>
        <Typography color="blue">View more</Typography>
      </Button>
    </>
  );

  return (
    <Typography
      type="meta"
      color="gray"
      whiteSpace="pre-wrap"
      overflowWrap="break-word"
    >
      {isTruncated ? (
        <Truncate lines={3} ellipsis={ReadMoreEllipsis}>
          <span>{text}</span>
        </Truncate>
      ) : (
        <>
          <span>{text}</span>
          <br />
          <Button onClick={() => setIsTruncated(true)}>
            <Typography color="blue">View less</Typography>
          </Button>
        </>
      )}
    </Typography>
  );
};
