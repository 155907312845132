import { useMutation, useQueryClient } from '@tanstack/react-query';

import { queryKeys } from 'api/config';
import { produce } from 'immer';
import { logError } from 'lib/sentry/logError';
import { useMutationHTTPRequest } from 'shared_DEPRECATED/hooks';

import { usePlanningDraftSprintsQueryActions } from 'features/planning';
import { IPlanningDraftSprint } from 'features/planning/types';
import { useUserId } from 'features/user/hooks/useUserId';

import { showErrorToast } from 'shared/components/Toast';

export const useDeleteCoachingToolFromDraftSprintMutation = () => {
  const queryClient = useQueryClient();
  const userId = useUserId();
  const { request } = useMutationHTTPRequest();

  const { updateDraftSprints, cancelDraftSprintsQueries, getDraftSprints } =
    usePlanningDraftSprintsQueryActions();

  return useMutation(
    ({ toolId, sprintId }: { toolId: string; sprintId: string }) =>
      request({
        url: `/api/web/sprint-planning/coaching-tools/remove`,
        body: { toolId, sprintId },
      }),
    {
      onMutate: async ({ toolId, sprintId }) => {
        await cancelDraftSprintsQueries();

        const previousDraftSprintsValue = getDraftSprints();
        const prevSprintItems = previousDraftSprintsValue!.items;

        if (previousDraftSprintsValue) {
          const sprintIndex = prevSprintItems.findIndex(
            (sprint) => sprint.sprintId === sprintId
          );

          if (sprintIndex !== -1) {
            const updatedSprints = produce(prevSprintItems, (draft) => {
              const coachingToolIndex = draft[
                sprintIndex
              ].coachingTools.findIndex((tool) => tool.id === toolId);
              draft[sprintIndex].coachingTools.splice(coachingToolIndex, 1);
            });

            updateDraftSprints(updatedSprints);
          }
        }

        return { previousDraftSprintsValue };
      },
      onError: (
        err: Error,
        __: any,
        context:
          | {
              previousDraftSprintsValue?: {
                items: IPlanningDraftSprint[];
              };
            }
          | undefined
      ) => {
        if (context?.previousDraftSprintsValue) {
          queryClient.setQueryData(
            [queryKeys.draftSprints, userId],
            context.previousDraftSprintsValue
          );
        }
        logError(err);
        showErrorToast('Failed to remove coaching tool.');
      },
    }
  );
};
