import Box from 'shared_DEPRECATED/components/Box';
import Button from 'shared_DEPRECATED/components/Button';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { openJournalRepliesSidebar } from 'features/journal';
import { useUserContext } from 'features/user/context';

import { Typography } from 'shared/components/Typography';

type TJournalCardReplies = {
  repliesCount: number;
  noteId: string;
};

export const JournalCardReplies = ({
  repliesCount,
  noteId,
}: TJournalCardReplies) => {
  const { user } = useUserContext();

  const handleNoteClick = () => {
    openJournalRepliesSidebar({
      noteId,
      user,
    });
  };

  return (
    <Button
      onClick={handleNoteClick}
      dataTestid="user-challenge-note-replies-btn"
    >
      <Box>
        <Flex alignItems="flex-start" justifyContent="flex-start">
          <Spacer as="span" size="zr sm zr zr">
            <Icon
              name={iconNames.message}
              stroke="var(--fgSecondary)"
              hoverStroke="var(--fgPrimary)"
            />
          </Spacer>
          <Typography
            color="gray"
            //@ts-ignore
            hoverColor="black"
            type="small"
            dataTestid="user-challenge-note-replies-label"
          >
            {`${repliesCount} ${repliesCount === 1 ? 'reply' : 'replies'}`}
          </Typography>
        </Flex>
      </Box>
    </Button>
  );
};
