import { useFormContext, useWatch } from 'react-hook-form';

import { Box, TextEditor } from 'shared';

import { useGoalDeepDiveTextEditorFormat } from 'features/goal/hooks/useGoalDeepDiveTextEditorFormat';

type TGoalDeepDiveFormEditor = {
  keyField: string;
};

export const GoalDeepDiveFormEditor = ({
  keyField,
}: TGoalDeepDiveFormEditor) => {
  const textFormat = useGoalDeepDiveTextEditorFormat();
  const { setValue } = useFormContext();
  const content = useWatch({ name: keyField });

  const handleContentChange = (value: string) => {
    setValue(keyField, value, { shouldDirty: true });
  };

  return (
    <Box h="100%">
      <TextEditor
        value={content}
        onChange={handleContentChange}
        format={textFormat}
      />
    </Box>
  );
};
