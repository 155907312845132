import {
  GoalDeepDiveFormSection,
  GoalDeepDiveFormDescription,
  GoalDeepDiveFormEditor,
} from 'features/goal';

export const GoalDeepDiveFormSupportSection = () => (
  <GoalDeepDiveFormSection step={4} title={'Support'}>
    <GoalDeepDiveFormDescription text="Accountability, staging surroundings, people, additional resources, etc." />
    <GoalDeepDiveFormEditor keyField="deepDive.support" />
  </GoalDeepDiveFormSection>
);
