import { useDrop } from 'react-dnd';

type DroppableProps<T> = {
  acceptType: string[] | string;
  onDrop?: (item: T) => void;
  children?: React.ReactNode;
  onHover?: (item: T) => void;
  height?: string;
  width?: string;
};

export const Droppable = <T,>({
  acceptType,
  children,
  onDrop,
  onHover,
  height = '100%',
  width = '100%',
}: DroppableProps<T>) => {
  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: acceptType,
      drop(item: T, monitor) {
        if (monitor.isOver({ shallow: true })) {
          onDrop?.(item);
        }
      },
      hover(item: T, monitor) {
        if (monitor.isOver({ shallow: true })) {
          onHover?.(item);
        }
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
      }),
    }),
    [onDrop]
  );

  return (
    <div
      ref={drop}
      style={{
        height,
        width,
        borderRadius: '1rem',
        border: `1px solid ${isOver ? 'var(--brdAccent)' : 'transparent'}`,
      }}
    >
      {children}
    </div>
  );
};
