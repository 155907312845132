import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';

import { queryKeys } from 'api/config';
import { useQueryHTTPRequest } from 'api/hooks/useHTTPRequest';

import { NonRepeatedChallenge } from 'features/challenge/NonRepeatedChallenge';
import { RepeatedChallenge } from 'features/challenge/RepeatedChallenge';
import { CHALLENGE_LOCATIONS } from 'features/challenge/config';
import {
  INonRepeatedChallenge,
  IRepeatedChallenge,
} from 'features/challenge/config/types';
import { useUserId } from 'features/user/hooks/useUserId';

export type ChallengeType = IRepeatedChallenge | INonRepeatedChallenge;

export const usePlanningBacklogQuery = () => {
  const { request } = useQueryHTTPRequest();
  const userId = useUserId();

  return useQuery<{ items: ChallengeType[] }, unknown, ChallengeType[]>(
    [queryKeys.challenges],
    () =>
      request({
        url: `/api/sprint-planning/backlog/challenges?user_id=${userId}`,
      }),
    {
      select: useCallback(
        (data: { items: ChallengeType[] }) =>
          mapPlanningBacklogQueryData(data?.items),
        []
      ),
      staleTime: 20 * 1000,
    }
  );
};

const mapPlanningBacklogQueryData = (
  challenges: ChallengeType[]
): ChallengeType[] =>
  challenges.map((challenge) =>
    (challenge as INonRepeatedChallenge).target?.dueDate
      ? new NonRepeatedChallenge({
          ...challenge,
          location: CHALLENGE_LOCATIONS.BACKLOG,
        } as INonRepeatedChallenge)
      : new RepeatedChallenge({
          ...challenge,
          location: CHALLENGE_LOCATIONS.BACKLOG,
        } as IRepeatedChallenge)
  );
