import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { queryKeys } from 'api/config';
import { useQueryHTTPRequest } from 'shared_DEPRECATED/hooks';

import { Goal } from 'features/goal';

export const useGoalQuery = () => {
  const { goalId } = useParams();
  const { request } = useQueryHTTPRequest();

  return useQuery(
    [queryKeys.goal, goalId],
    () => request({ url: `/api/web/goals/${goalId}` }),
    { enabled: !!goalId, select: (data) => new Goal(data) }
  );
};
