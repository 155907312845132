// should be moved to a separate feature folder e.g. upload
import { useQuery } from '@tanstack/react-query';

import { queryKeys } from '../config';
import { useQueryHTTPRequest } from './useHTTPRequest';

export const useFilePresignedUrlQuery = ({ file = {} }) => {
  const { request } = useQueryHTTPRequest();

  const { data, refetch, status } = useQuery(
    [queryKeys.file],
    () =>
      request({
        url: '/api/upload-link',
        headers: {
          'object-content-type': file.type,
        },
      }),
    { enabled: false }
  );

  return { data, refetch, status };
};
