import { memo, useCallback } from 'react';

import { COACHING_TOOLS_FEATURE_FLAG } from 'featureFlags';
import { useFeatureFlag } from 'featureFlags/hooks';

import { CHALLENGE_LOCATIONS } from 'features/challenge/config';
import {
  CoachingToolCard,
  CoachingToolCardMenu,
  CoachingToolCardMenuCopyToSprint,
  CoachingToolCardMenuMoveToSprint,
  CoachingToolCardMenuRemoveButton,
} from 'features/coachingTool';
import {
  PlanningDraftSprintHeader,
  PlanningBoardItems,
  canItemBeDroppedInDropZone,
  PlanningBoardColumn,
  PlanningBoardColumnItemsCount,
  usePlanningDraftSprintsQuery,
  IPlanningDraftSprint,
  useCoachingToolMoveMutation,
} from 'features/planning';
import { useFilterDraftSprintsByCoachingToolId } from 'features/planning/hooks/useFilterDraftSprintsByCoachingToolId';

import {
  BoardColumn,
  BoardColumnHeader,
  BoardColumnWrapper,
  usePinnedColumnsContext,
} from 'shared/components/Board';

export const PlanningDraftSprints = memo(({ pinned }: { pinned: boolean }) => {
  const { pinnedColumns } = usePinnedColumnsContext();
  const isCoachingToolsEnabled = useFeatureFlag(COACHING_TOOLS_FEATURE_FLAG);
  const { data: draftSprintsData } = usePlanningDraftSprintsQuery<
    IPlanningDraftSprint[]
  >({
    options: {
      select: (data) => {
        return data.filter((sprint) => {
          return pinned
            ? pinnedColumns.has(sprint.sprintId)
            : !pinnedColumns.has(sprint.sprintId);
        });
      },
    },
  });

  const coachingToolsOnSprintsMap =
    useFilterDraftSprintsByCoachingToolId(draftSprintsData);
  const { mutateAsync: moveCoachingTool } = useCoachingToolMoveMutation();
  const handleCoachingToolMove = useCallback(
    (toolId: string, fromSprintId: string) => async (toSprintId: string) => {
      await moveCoachingTool({
        toolId,
        fromSprintId,
        toSprintId,
        fromLocation: CHALLENGE_LOCATIONS.DRAFT_SPRINT,
        toLocation: CHALLENGE_LOCATIONS.DRAFT_SPRINT,
      });
    },
    [moveCoachingTool]
  );

  return (
    <>
      {draftSprintsData?.map((sprint) => (
        <PlanningBoardColumn key={sprint.sprintId}>
          <BoardColumnWrapper
            fraction={draftSprintsData.length}
            key={sprint.sprintId}
          >
            <BoardColumnHeader key={sprint.sprintId} minHeight="3rem">
              <PlanningDraftSprintHeader sprint={sprint} />
            </BoardColumnHeader>
            <BoardColumn>
              {sprint.challenges.length > 0 && (
                <PlanningBoardColumnItemsCount
                  name="challenge"
                  count={sprint.challenges.length}
                />
              )}
              <PlanningBoardItems
                challenges={sprint.challenges}
                canDrop={canItemBeDroppedInDropZone('sprint', sprint.sprintId)}
                sprint={sprint}
              />
              {isCoachingToolsEnabled && sprint.coachingTools.length > 0 && (
                <>
                  <PlanningBoardColumnItemsCount
                    name="coaching tool"
                    count={sprint.coachingTools.length}
                  />
                  {sprint.coachingTools.map(
                    ({ name, description, picture, id }) => (
                      <CoachingToolCard
                        name={name}
                        description={description}
                        url={picture?.url}
                        id={id}
                        key={id}
                      >
                        <CoachingToolCardMenu toolId={id}>
                          <CoachingToolCardMenuCopyToSprint toolId={id} />
                          <CoachingToolCardMenuMoveToSprint
                            sprints={coachingToolsOnSprintsMap[id]}
                            onCoachingToolMove={handleCoachingToolMove(
                              id,
                              sprint.sprintId
                            )}
                          />
                          <CoachingToolCardMenuRemoveButton
                            toolId={id}
                            sprintId={sprint.sprintId}
                          />
                        </CoachingToolCardMenu>
                      </CoachingToolCard>
                    )
                  )}
                </>
              )}
            </BoardColumn>
          </BoardColumnWrapper>
        </PlanningBoardColumn>
      ))}
    </>
  );
});
