import { useMutation } from '@tanstack/react-query';

import { useMutationHTTPRequest } from 'shared_DEPRECATED/hooks';

import {
  INonRepeatedChallenge,
  IRepeatedChallenge,
} from 'features/challenge/config/types';
import { useUserId } from 'features/user/hooks/useUserId';

import { usePlanningBacklogQueryActions } from '../query';

export const useAddChallengesToBacklogMutation = () => {
  const { request } = useMutationHTTPRequest();
  const { updateBacklog } = usePlanningBacklogQueryActions();

  const userId = useUserId();

  return useMutation(
    async (challenges: TChallenges) =>
      await request({
        url: `/api/web/sprint-planning/backlog/challenges/create`,
        body: { challenges, userId },
      }),
    {
      onSuccess: async (upatedBacklog) => {
        updateBacklog(upatedBacklog.items);
      },
    }
  );
};

type TChallenges = (IRepeatedChallenge | INonRepeatedChallenge)[];
