import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';

import { logError } from 'lib/sentry/logError';
import { Box, Divider } from 'shared';

import { TextEditorFormat } from 'shared/components/TextEditor/types';

import { Placeholder } from './components/Placeholder';
import { namespace, TextEditorFormats, TOOLBAR_HEIGHT } from './constants';
import { editorNodes } from './nodes/editorNodes';
import { BasicToolbarPlugin } from './plugins/BasicToolbarPlugin';
import { ShortcutPlugin } from './plugins/ShortcutPlugin';
import { TextEditorOnChangePlugin } from './plugins/TextEditorOnChangePlugin';
import './textEditorStyles.css';
import { theme } from './themes/textEditorTheme';
import {
  prepareEditorState,
  reformatLineBreaksIfAny,
  tryParseJson,
} from './utils';

type BasicTextEditorProps = {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  format?: TextEditorFormat;
};

const basicTextEditorNamespace = 'hos-basic-text-editor';

export const BasicTextEditor = ({
  value,
  onChange,
  placeholder = '',
  format = TextEditorFormats.html,
}: BasicTextEditorProps) => {
  const isCurrentValueIsJson = tryParseJson(value);
  const formattedValue =
    format === TextEditorFormats.html ? reformatLineBreaksIfAny(value) : value;

  const initialConfig = {
    namespace,
    onError: (error: Error) => {
      logError(error);
    },
    editorState: isCurrentValueIsJson
      ? value
      : prepareEditorState(formattedValue),
    theme,
    nodes: [...editorNodes],
  };

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <HistoryPlugin />
      <Box
        style={{
          height: '100%',
          borderRadius: 'var(--border-radius) var(--border-radius)',
          border: 'var(--border-secondary)',
        }}
      >
        <Box
          position="relative"
          style={{
            height: `calc(100% - ${TOOLBAR_HEIGHT}px)`,
          }}
        >
          <RichTextPlugin
            placeholder={<Placeholder>{placeholder}</Placeholder>}
            contentEditable={
              <ContentEditable
                className={`${basicTextEditorNamespace}__content`}
              />
            }
            ErrorBoundary={LexicalErrorBoundary}
          />
        </Box>
        <Divider />
        <BasicToolbarPlugin />
        <ShortcutPlugin />
      </Box>
      <TextEditorOnChangePlugin onChange={onChange} format={format} />
    </LexicalComposer>
  );
};
