import {
  GOAL_DEEP_DIVE_MARKDOWN_FEATURE_FLAG,
  useFeatureFlag,
} from 'featureFlags';

import { TextEditorFormats } from 'shared/components/TextEditor/constants';

//TODO remove this hook after we completely migrate to markdown
export const useGoalDeepDiveTextEditorFormat = () => {
  const isGoalDeepDiveMarkdownFeatureFlagEnabled = useFeatureFlag(
    GOAL_DEEP_DIVE_MARKDOWN_FEATURE_FLAG
  );

  return isGoalDeepDiveMarkdownFeatureFlagEnabled
    ? TextEditorFormats.json
    : TextEditorFormats.html;
};
