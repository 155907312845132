import { VStack } from '@chakra-ui/react';
import { ReactNode } from 'react';

type TBoardColumn = {
  children: ReactNode;
  spacing?: number | string;
};

export const BoardColumn = ({ children, spacing = 0 }: TBoardColumn) => (
  <VStack
    align="stretch"
    flex="1"
    spacing={spacing}
    overflowY="auto"
    overflowX="hidden"
    p="8px"
    data-testid="board-column"
  >
    {children}
  </VStack>
);
