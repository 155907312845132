import { IconButton } from '@chakra-ui/react';

import {
  Menu,
  MenuButton,
  MenuList,
  Typography,
  VARIANTS,
  MenuItem,
  useSidebarContext,
} from 'shared';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

import { useJournalDeleteNoteMutation } from 'features/journal/hooks';
import { openJournalSidebar } from 'features/journal/utils';

type TJournalCardMenu = {
  onEdit: () => void;
  noteId: string;
};

export const JournalCardMenu = ({ onEdit, noteId }: TJournalCardMenu) => {
  const { mutateAsync: deleteNote } = useJournalDeleteNoteMutation({
    noteId,
  });

  const { location, user } = useSidebarContext();

  const handleDeleteNote = async () => {
    await deleteNote();

    location === 'replies' && openJournalSidebar({ user });
  };

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<Icon name={iconNames.ellipsisHorizontal} />}
        variant={VARIANTS.TERTIARY}
        size="sm"
      />
      <MenuList fontSize="small">
        <MenuItem onClick={onEdit}>Edit</MenuItem>
        <MenuItem onClick={handleDeleteNote}>
          <Typography color="red">Delete</Typography>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
