import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';

import { useQueryHTTPRequest } from 'api/hooks/useHTTPRequest';
import { getParticipantsSprintsQueryKey } from 'api/utils';

import { SPRINT_TYPES } from 'features/sprint/config';
import { useCreateDraftSprintInstanceWithWeeksCount } from 'features/sprint/hooks/useCreateDraftSprintInstanceWithWeeksCount';
import { mapSprints } from 'features/sprint/utils';

export const useParticipantsSprintsQuery = (email, options = {}) => {
  const { request } = useQueryHTTPRequest();
  const createDraftSprintWithWeeksCount =
    useCreateDraftSprintInstanceWithWeeksCount();

  return useQuery(
    getParticipantsSprintsQueryKey(email),
    async () => await request({ url: `/api/coach/clients/${email}/sprints` }),
    {
      ...options,
      select: useCallback(
        (data) => {
          const sprints = data?.items
            ? mapSprints({
                sprints: data.items,
                sprintType: SPRINT_TYPES.COACHED,
              })
            : [];
          const draftSprint =
            data?.draftSprint &&
            createDraftSprintWithWeeksCount(data.draftSprint);

          return draftSprint ? [draftSprint, ...sprints] : sprints;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
      ),
    }
  );
};
