import {
  CoachingToolCard,
  CoachingToolCardMenu,
  CoachingToolCardMenuCopyToSprint,
  ICoachingTool,
} from 'features/coachingTool';
import { DRAGGABLE_ITEM_TYPES } from 'features/planning/config';

import { CoachingToolsDraggable } from './CoachingToolsDraggable';

type CoachingToolItemsProps = {
  coachingTools: ICoachingTool[];
};

export const CoachingToolItems = ({
  coachingTools,
}: CoachingToolItemsProps) => {
  return (
    <>
      {coachingTools.map(({ name, description, picture, id }) => {
        return (
          <CoachingToolsDraggable
            key={id}
            item={{
              id,
              type: DRAGGABLE_ITEM_TYPES.COACHING_TOOL,
            }}
          >
            <CoachingToolCard
              name={name}
              description={description}
              url={picture?.url}
              id={id}
            >
              <CoachingToolCardMenu toolId={id}>
                <CoachingToolCardMenuCopyToSprint toolId={id} />
              </CoachingToolCardMenu>
            </CoachingToolCard>
          </CoachingToolsDraggable>
        );
      })}
    </>
  );
};
