import { useMemo } from 'react';

import { CoachingToolsInSprintsMap, IPlanningDraftSprint } from '../types';

const DEFAULT_SPRINTS = [] as IPlanningDraftSprint[];

export const useFilterDraftSprintsByCoachingToolId = (
  sprints: IPlanningDraftSprint[] = DEFAULT_SPRINTS
) => {
  return useMemo(() => {
    const allTools = sprints.map((sprint) => sprint.coachingTools).flat();
    return allTools.reduce((acc, val) => {
      const toolId = val.id;
      acc[toolId] = sprints
        .filter(
          (sprint) => !sprint.coachingTools.find((item) => item.id === toolId)
        )
        .map(({ sprintId, title }) => ({
          sprintId,
          title,
        }));

      return acc;
    }, {} as CoachingToolsInSprintsMap);
  }, [sprints]);
};
