import {
  JournalCardReactions,
  useCreateJournalReplyReaction,
  useDeleteJournalReplyReaction,
  JournalReaction,
} from 'features/journal';

type TJournalReplyCardReactions = {
  replyId: string;
  noteId: string;
  reactions: JournalReaction[];
};

export const JournalReplyCardReactions = ({
  replyId,
  noteId,
  reactions,
}: TJournalReplyCardReactions) => {
  const { mutateAsync: addReaction } = useCreateJournalReplyReaction();
  const { mutateAsync: removeReaction } = useDeleteJournalReplyReaction();

  const handleAddReaction = async (reaction: string) =>
    await addReaction({ noteId, replyId, reaction });

  const handleRemoveReaction = async (reactionId: number) =>
    await removeReaction({ noteId, replyId, reactionId });

  return (
    <JournalCardReactions
      emojiPickerPosition="start"
      reactions={reactions}
      onAddReaction={(reaction: string) => handleAddReaction(reaction)}
      onRemoveReaction={(reactionId: number) =>
        handleRemoveReaction(reactionId)
      }
    />
  );
};
