import { Spacer } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';

import { queryKeys } from 'api/config';
import { Typography } from 'shared';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import { HotjarSuppression } from 'shared_DEPRECATED/components/Hotjar';

import {
  CoachingToolDialogBanner,
  CoachingToolDialogInfo,
  ICoachingTool,
} from 'features/coachingTool';

import { ModalBody, ModalHeader } from 'shared/components/Modal';

type TCoachingToolDialog = {
  toolId: string;
};

const CoachingToolDialog = ({ toolId }: TCoachingToolDialog) => {
  const queryClient = useQueryClient();

  const coachingTools = queryClient.getQueryData<{
    items: ICoachingTool[];
  }>([queryKeys.coachingTools]);

  const coachingTool = coachingTools?.items?.find(({ id }) => id === toolId);

  return coachingTool ? (
    <>
      <ModalHeader />
      <ModalBody>
        <HotjarSuppression>
          <Flex
            width="100%"
            height="100%"
            flexDirection="column"
            alignItems="flex-start"
            gap="1rem"
          >
            <CoachingToolDialogInfo
              name={coachingTool.name}
              url={coachingTool.picture?.url}
            />
            <CoachingToolDialogBanner />
            {coachingTool.description && (
              <>
                <Typography
                  color="black"
                  fontWeight="medium"
                  type="smallHeading"
                >
                  Description
                </Typography>
                <Typography
                  color="black"
                  fontWeight="regular"
                  type="meta"
                  whiteSpace="pre-wrap"
                >
                  {coachingTool.description}
                </Typography>
              </>
            )}
            <Spacer />
          </Flex>
        </HotjarSuppression>
      </ModalBody>
    </>
  ) : null;
};

export default CoachingToolDialog;
