import { useMutation, useQueryClient } from '@tanstack/react-query';

import { queryKeys } from 'api/config';
import { useMutationHTTPRequest } from 'api/hooks/useHTTPRequest';
import { logError } from 'lib/sentry/logError';

export const useNotificationStatusMutation = (notificationId) => {
  const { request } = useMutationHTTPRequest();
  const queryClient = useQueryClient();

  return useMutation(
    () => {
      return request({
        url: `/api/coach/notifications/mark-read`,
        body: { notificationId },
      });
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries([queryKeys.notifications]);
      },
      onError: (err) => {
        logError(err);
      },
    }
  );
};
