import { IChallenge } from 'features/challenge/config/types';
import {
  IPlanningDraftSprint,
  useEditPlanningDraftSprintChallengeMutation,
} from 'features/planning';

import { useOpenChallengeDialog } from './useOpenChallengeDialog';

type TUseOpenEditDraftSprintChallengeDialog = {
  challenge: IChallenge;
  sprint: IPlanningDraftSprint;
};

export const useOpenEditDraftSprintChallengeDialog = ({
  challenge,
  sprint,
}: TUseOpenEditDraftSprintChallengeDialog) => {
  const { mutateAsync: onChallengeSubmit } =
    useEditPlanningDraftSprintChallengeMutation({
      sprint,
    });

  const openChallengeModal = useOpenChallengeDialog();

  return openChallengeModal({
    onChallengeSubmit,
    challenge,
    sprint,
  });
};
