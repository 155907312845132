import { useCallback } from 'react';

import { Box, Image } from 'shared';
import Button from 'shared_DEPRECATED/components/Button/Button';

import { JournalCardImageModal } from 'features/journal';

import { SIZES, modal } from 'shared/components/Modal';

import styles from 'features/journal/components/Card/Card.module.css';

export const JournalCardImageAttachment = ({ src }: { src: string }) => {
  const handleImageClick = useCallback(
    (src: string) =>
      modal.open({
        content: <JournalCardImageModal src={src} />,
        props: {
          title: '',
          size: SIZES.SM,
        },
      }),
    []
  );

  if (!src) {
    return null;
  }

  return (
    <Button onClick={() => handleImageClick(src)}>
      <Box w="3rem" h="3rem">
        <Image
          alt={src}
          objectFit="cover"
          width="100%"
          height="100%"
          src={src}
          className={styles['card-image']}
        />
      </Box>
    </Button>
  );
};
