import { VStack } from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import { When } from 'react-if';
import { For } from 'react-loops';

import { Flex, SidebarBody, Typography, useSidebarContext, Box } from 'shared';
import Loader from 'shared_DEPRECATED/components/Loader';
import Spacer from 'shared_DEPRECATED/components/Spacer';
import { Nullable } from 'shared_DEPRECATED/types';
import { dateUtils } from 'shared_DEPRECATED/utils';

import {
  JournalCardReplies,
  JournalNotesContainer,
  useJournalContext,
  mapNotesToSprints,
  JournalNote,
  useJournalEditNoteMutation,
  JournalEditForm,
  useJournalMetadataQuery,
  JournalNoteCardReactions,
} from 'features/journal';
import { JournalCard } from 'features/journal/components/Card';
import { User } from 'features/user/User';

export const JournalNotesBody = () => {
  const [editedNote, setEditedNote] = useState<Nullable<JournalNote>>(null);
  const { notes, scrollRef, isFetchingNextPage } = useJournalContext();
  const { mutateAsync: editNote } = useJournalEditNoteMutation();

  const { user } = useSidebarContext() as { user: User };

  const { data: { sprints } = { sprints: [] } } = useJournalMetadataQuery();

  const groupedNotes = useMemo(
    () =>
      Boolean(notes && !!sprints?.length)
        ? mapNotesToSprints(notes, sprints)
        : [],
    [notes, sprints]
  );

  const handleEditNote = async ({ text }: { text: string }) => {
    const noteId = editedNote?.noteId ?? '';
    setEditedNote(null);

    await editNote({ text, noteId });
  };

  return (
    <SidebarBody dataTestid="notes-sidebar-body">
      <JournalNotesContainer>
        <When condition={Boolean(notes && !!sprints.length)}>
          {() => (
            <>
              <For of={groupedNotes}>
                {({ sprintTitle, formattedDate, notesByDate }) => (
                  <Box as="section">
                    <Flex flexDirection="column" alignItems="center" gap="4px">
                      <Typography color="black" fontWeight="semiBold">
                        {sprintTitle}
                      </Typography>
                      <Typography color="gray" type="small">
                        {formattedDate}
                      </Typography>
                    </Flex>
                    <For in={notesByDate}>
                      {(notes, { key: notesDate }) => (
                        <Flex
                          flexDirection="column"
                          width="100%"
                          key={notesDate}
                        >
                          <Spacer size="sm">
                            <Typography
                              textAlign="center"
                              color="gray"
                              type="small"
                            >
                              {dateUtils(notesDate as string).format('MMM D')}
                            </Typography>
                          </Spacer>
                          <VStack px="0.5rem">
                            <For of={notes}>
                              {(note: JournalNote) => (
                                <Flex
                                  flexDirection="column"
                                  width="100%"
                                  gap="1rem"
                                >
                                  <JournalCard
                                    note={note}
                                    setEditedNote={setEditedNote}
                                    isAuthor={Boolean(
                                      note.authorId === user.userId
                                    )}
                                  >
                                    <JournalCardReplies
                                      repliesCount={note.repliesCount}
                                      noteId={note.noteId}
                                    />
                                    <JournalNoteCardReactions
                                      noteId={note.noteId}
                                      reactions={note.reactions}
                                    />
                                  </JournalCard>
                                </Flex>
                              )}
                            </For>
                          </VStack>
                        </Flex>
                      )}
                    </For>
                  </Box>
                )}
              </For>
              <div ref={scrollRef}></div>
              <Spacer size="md">
                {isFetchingNextPage ? <Loader size="sm" /> : null}
              </Spacer>
            </>
          )}
        </When>
      </JournalNotesContainer>
      {editedNote && (
        <Box
          position="sticky"
          bottom="0"
          bg="white"
          p="1rem"
          boxShadow="0 -2px 4px rgba(0, 0, 0, 0.1)"
        >
          <JournalEditForm
            itemType="note"
            text={editedNote.text}
            onCancel={() => setEditedNote(null)}
            onSave={handleEditNote}
            user={user}
          />
        </Box>
      )}
    </SidebarBody>
  );
};
