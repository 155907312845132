import { useMutation, useQueryClient } from '@tanstack/react-query';

import { queryKeys } from 'api/config';
import { produce } from 'immer';
import { logError } from 'lib/sentry/logError';

import { ICoachingTool } from 'features/coachingTool';
import { IPlanningSprint } from 'features/planning';
import { useSprintsQueryActions } from 'features/sprint/hooks/query/sprints/useSprintsActions';
import { useUserId } from 'features/user/hooks/useUserId';

import { showErrorToast } from 'shared/components/Toast';

import { useAddCoachingToolMutateFunction } from './useAddCoachingToolMutateFunction';

export const useAddPlanningCoachingToolToSprintMutation = () => {
  const queryClient = useQueryClient();
  const userId = useUserId();

  const mutateFunc = useAddCoachingToolMutateFunction();

  const { cancelSprintsQueries, getSprints, updateSprints } =
    useSprintsQueryActions();

  return useMutation(mutateFunc, {
    onMutate: async ({ toolId, sprintId }) => {
      await cancelSprintsQueries();

      const previousSprintsValue = getSprints();
      const prevSprintItems = previousSprintsValue!.items;

      const coachingTools = queryClient.getQueryData<{
        items: ICoachingTool[];
      }>([queryKeys.coachingTools]);

      const coachingTool = coachingTools?.items.find(({ id }) => id === toolId);

      if (previousSprintsValue && coachingTool) {
        const sprintIndex = prevSprintItems.findIndex(
          (sprint) => sprint.sprintId === sprintId
        );

        if (sprintIndex !== -1) {
          const updatedSprints = produce(prevSprintItems, (draft) => {
            draft[sprintIndex].coachingTools.push(coachingTool);
          });

          updateSprints(updatedSprints);
        }
      }

      return { previousSprintsValue };
    },
    onError: (
      err: Error,
      __: any,
      context:
        | {
            previousSprintsValue?: {
              items: IPlanningSprint[];
            };
          }
        | undefined
    ) => {
      if (context?.previousSprintsValue) {
        queryClient.setQueryData(
          [queryKeys.sprints, userId],
          context.previousSprintsValue
        );
      }
      logError(err);
      showErrorToast('Failed to add coaching tool.');
    },
  });
};
