import { JournalRepliesContent, JournalRepliesHeader } from 'features/journal';

import { SidebarBody } from 'shared/components/Sidebar';

export const JournalReplies = () => (
  <>
    <JournalRepliesHeader />
    <SidebarBody>
      <JournalRepliesContent />
    </SidebarBody>
  </>
);
