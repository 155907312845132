import { useMutation, useQueryClient } from '@tanstack/react-query';

import { queryKeys } from 'api/config';
import { produce } from 'immer';
import { logError } from 'lib/sentry/logError';
import { useMutationHTTPRequest } from 'shared_DEPRECATED/hooks';

import { usePlanningDraftSprintsQueryActions } from 'features/planning';
import { useUserId } from 'features/user/hooks/useUserId';

export const useEditDraftSprintTitle = () => {
  const queryClient = useQueryClient();
  const userId = useUserId();
  const { request } = useMutationHTTPRequest();
  const {
    cancelDraftSprintsQueries,
    getDraftSprints,
    updateDraftSprints,
    invalidateDraftSprintsQuery,
  } = usePlanningDraftSprintsQueryActions();

  return useMutation(
    ({ title, sprintId }: { title: string; sprintId: string }) =>
      request({
        url: `/api/draft-sprints/${sprintId}/title`,
        body: { title },
      }),
    {
      onMutate: async ({ title, sprintId }) => {
        await cancelDraftSprintsQueries();

        const previousDraftSprintsValue = getDraftSprints();

        if (previousDraftSprintsValue) {
          const updatedSprints = produce(
            previousDraftSprintsValue.items,
            (draft) => {
              const sprintIndex = draft.findIndex(
                (sprint) => sprint.sprintId === sprintId
              );

              draft[sprintIndex].title = title;
            }
          );

          updateDraftSprints(updatedSprints);
        }

        return { previousDraftSprintsValue };
      },
      onError: (err: Error, __: any, context) => {
        if (context?.previousDraftSprintsValue) {
          queryClient.setQueryData(
            [queryKeys.draftSprints, userId],
            context.previousDraftSprintsValue
          );
        }
        logError(err);
      },
      onSuccess: () => {
        invalidateDraftSprintsQuery();
      },
    }
  );
};
