export const namespace = 'hos-text-editor';

export const TOOLBAR_HEIGHT = 32;

export const blockTypes = {
  paragraph: {
    key: 'paragraph',
    label: 'Normal',
  },
  h1: {
    key: 'h1',
    label: 'Heading 1',
  },
  h2: {
    key: 'h2',
    label: 'Heading 2',
  },
  bullet: {
    key: 'bullet',
    label: 'Bulleted List',
  },
  number: {
    key: 'number',
    label: 'Numbered List',
  },
} as const;

export type BlockType = keyof typeof blockTypes;

export const blockTypeKeys = Object.keys(blockTypes);

export const TextEditorFormats = {
  html: 'html',
  markdown: 'markdown',
  json: 'json',
} as const;
