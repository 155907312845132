import { useQuery } from '@tanstack/react-query';

import { queryKeys } from 'api/config';
import { useQueryHTTPRequest } from 'api/hooks/useHTTPRequest';
import { Nullable } from 'shared_DEPRECATED/types';
import { Picture } from 'shared_DEPRECATED/types/Picture';

import { FinalSprint } from 'features/sprint';
import { IFinalSprint } from 'features/sprint/config/types';
import { useIsCoach } from 'features/user/hooks/useIsCoach';
import { useUserId } from 'features/user/hooks/useUserId';

export const useJournalMetadataQuery = () => {
  const { request } = useQueryHTTPRequest();

  const isCoach = useIsCoach();
  const userId = useUserId();

  return useQuery<{
    sprints: IFinalSprint[];
    challenges: {
      challengeId: string;
      emoji: string;
      picture: Nullable<Picture>;
      sprintId: string;
      title: string;
    }[];
  }>(
    [queryKeys.journal, 'metadata'],
    async () => {
      const coachPrefix = isCoach ? `/coach/${userId}` : '';

      return await request({
        url: `/api${coachPrefix}/journal/metadata`,
      });
    },
    {
      select: (data) => ({
        sprints: data?.sprints.map((sprint) => new FinalSprint(sprint)) ?? [],
        challenges: data?.challenges ?? [],
      }),
    }
  );
};
