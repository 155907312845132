import { Badge, Flex } from 'shared';

type TGoalYears = {
  years: string;
};

export const GoalYears = ({ years }: TGoalYears) => (
  <Badge size="sm">
    <Flex alignItems="center" gap="var(--spacing00)">
      {years}
    </Flex>
  </Badge>
);
