import { useEffect, useRef } from 'react';

import Box from 'shared_DEPRECATED/components/Box';
import { FlexItem } from 'shared_DEPRECATED/components/Flex';

import { BOARD_COLUMN_WIDTH } from 'features/planning/config';

type PlanningBoardColumnProps = {
  children: React.ReactElement;
  shouldScrollIntoView?: boolean;
};

export const PlanningBoardColumn = ({
  children,
  shouldScrollIntoView,
}: PlanningBoardColumnProps) => {
  const columnRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (columnRef.current && shouldScrollIntoView) {
      columnRef.current.scrollIntoView({ inline: 'start' });
    }
  }, [shouldScrollIntoView]);

  return (
    <FlexItem
      flexShrink={0}
      height="100%"
      width={BOARD_COLUMN_WIDTH}
      alignSelf="stretch"
    >
      <Box
        ref={shouldScrollIntoView ? columnRef : undefined}
        style={{
          height: '100%',
          scrollMarginLeft: '4rem',
        }}
      >
        {children}
      </Box>
    </FlexItem>
  );
};
