import { useOutletContext, useMatch } from 'react-router-dom';

import { WEEK_TARGET_ABSENT } from 'shared_DEPRECATED/utils/config';

import { TARGET_PERIOD } from 'features/challenge/config/types';
import { ACCOUNTABILITY_PARTNER_SPRINT_ROUTE_PATH } from 'features/router/config';
import { SPRINT_STATES } from 'features/sprint/config';
import {
  getIsDailyStatusDisabled,
  getIsDailyStatusDisabledByTargetType,
} from 'features/sprint/utils';

export const useIsDailyStatusDisabled = ({ index, target }) => {
  const { sprint, selectedWeekIndex } = useOutletContext();
  const selectedWeekDates = sprint.weeks[selectedWeekIndex];
  const formattedDate = selectedWeekDates[index];
  const selectedWeekTarget = target.weekly?.[selectedWeekIndex];
  const accountabilityPartnerSprintRouteMatch = useMatch(
    ACCOUNTABILITY_PARTNER_SPRINT_ROUTE_PATH
  );

  if (
    sprint.state === SPRINT_STATES.FINISHED ||
    accountabilityPartnerSprintRouteMatch
  ) {
    return true;
  }

  const isButtonDisabledByNoTarget = selectedWeekTarget === WEEK_TARGET_ABSENT;

  if (isButtonDisabledByNoTarget) {
    return true;
  }

  const isButtonDisabledBySprintDate = getIsDailyStatusDisabled({
    dayFormattedDate: formattedDate,
    sprintEndDate: sprint.endDate,
    sprintStartDate: sprint.startDate,
  });

  if (isButtonDisabledBySprintDate) {
    return true;
  }

  const isButtonDisabledByTargetType =
    target.period === TARGET_PERIOD.PER_SPRINT ||
    target.period === TARGET_PERIOD.PER_WEEK
      ? false
      : getIsDailyStatusDisabledByTargetType({
          target,
          dayIndex: index,
          dayFormattedDate: formattedDate,
        });

  if (isButtonDisabledByTargetType) {
    return true;
  }

  return false;
};
