import { useCallback } from 'react';

import { COACHING_TOOLS_FEATURE_FLAG, useFeatureFlag } from 'featureFlags';
import { BoardColumn, BoardColumnHeader, BoardColumnWrapper } from 'shared';

import {
  CoachingToolCard,
  CoachingToolCardMenu,
  CoachingToolCardMenuCopyToSprint,
  CoachingToolCardMenuMoveToSprint,
  CoachingToolCardMenuRemoveButton,
} from 'features/coachingTool';
import {
  PlanningBoardColumnItemsCount,
  useCoachingToolMoveMutation,
} from 'features/planning';
import { CHALLENGE_LOCATIONS } from 'features/planning/../challenge/config';
import { DRAGGABLE_ITEM_TYPES } from 'features/planning/config';
import { useMovePlanningChallengeMutation__NEW } from 'features/planning/hooks/mutation/useMovePlanningChallenge__NEW';
import { useCoachingToolsOnDrop } from 'features/planning/hooks/useCoachingToolsOnDrop';
import {
  CoachingToolsInSprintsMap,
  IPlanningDraftSprint,
  PlanningDraggableItem,
} from 'features/planning/types';
import {
  getNextLeftChallengeId,
  isDndItemCopied,
} from 'features/planning/utils';

import { Droppable } from 'shared/components/DragNDrop/Droppable';

import { CoachingToolsDraggable } from './Backlog/CoachingToolsDraggable';
import { CoachingToolDroppable } from './Backlog/CoachingToolsDroppable';
import { PlanningBoardColumn } from './Column';
import { PlanningDraftSprintHeader } from './DraftSprint';
import { PlanningBoardItems__NEW } from './Items__NEW';

type DraftSprint__NEWProps = {
  sprint: IPlanningDraftSprint;
  sprintsCount: number;
  coachingToolsInSprintsMap: CoachingToolsInSprintsMap;
};

export const PlanningDraftSprint__NEW = ({
  sprint,
  sprintsCount,
  coachingToolsInSprintsMap,
}: DraftSprint__NEWProps) => {
  const isCoachingToolsEnabled = useFeatureFlag(COACHING_TOOLS_FEATURE_FLAG);
  const { mutate } = useMovePlanningChallengeMutation__NEW();

  const { sprintId, coachingTools = [], challenges = [] } = sprint;

  const { mutateAsync: moveCoachingTool } = useCoachingToolMoveMutation();
  const handleCoachingToolMove = useCallback(
    (toolId: string, fromSprintId: string) => async (toSprintId: string) => {
      await moveCoachingTool({
        toolId,
        fromSprintId,
        toSprintId,
        fromLocation: CHALLENGE_LOCATIONS.DRAFT_SPRINT,
        toLocation: CHALLENGE_LOCATIONS.DRAFT_SPRINT,
      });
    },
    [moveCoachingTool]
  );

  const handleOnDrop = useCoachingToolsOnDrop({ coachingTools });

  return (
    <Droppable
      key={sprint.sprintId}
      acceptType={[DRAGGABLE_ITEM_TYPES.CHALLENGE]}
      onDrop={(item: PlanningDraggableItem) => {
        const { id, parentId, location } = item;

        mutate({
          id,
          fromBacklog: parentId === CHALLENGE_LOCATIONS.BACKLOG,
          fromSprintId:
            parentId === CHALLENGE_LOCATIONS.BACKLOG ? undefined : parentId,
          toBacklog: false,
          toSprintId: sprint.sprintId,
          underChallengeId: getNextLeftChallengeId({
            challenges: sprint.challenges,
            id,
            newIndex: sprint.challenges.length,
            isNew: true,
          }),
          prevLocation: location,
          newLocation: CHALLENGE_LOCATIONS.DRAFT_SPRINT,
        });

        if (!isDndItemCopied(location)) {
          item.parentId = sprint.sprintId;
          item.location = CHALLENGE_LOCATIONS.DRAFT_SPRINT;
        }
      }}
      onHover={(item: PlanningDraggableItem) => {
        item.index = sprint.challenges.length;
      }}
    >
      <CoachingToolDroppable
        sprintId={sprintId}
        onDrop={async (item: any) => {
          await handleOnDrop(item, sprintId, CHALLENGE_LOCATIONS.DRAFT_SPRINT);
        }}
      >
        <PlanningBoardColumn>
          <BoardColumnWrapper fraction={sprintsCount}>
            <BoardColumnHeader minHeight="3rem">
              <PlanningDraftSprintHeader sprint={sprint} />
            </BoardColumnHeader>

            <BoardColumn spacing={1}>
              {challenges.length > 0 && (
                <PlanningBoardColumnItemsCount
                  name="challenge"
                  count={challenges.length}
                />
              )}

              {challenges.length > 0 ? (
                // eslint-disable-next-line
                <PlanningBoardItems__NEW
                  challenges={sprint.challenges}
                  sprint={sprint}
                  location={CHALLENGE_LOCATIONS.DRAFT_SPRINT}
                />
              ) : null}
              {isCoachingToolsEnabled && coachingTools.length > 0 && (
                <>
                  <PlanningBoardColumnItemsCount
                    name="coaching tool"
                    count={coachingTools.length}
                  />
                  {coachingTools.map(({ name, description, picture, id }) => (
                    <CoachingToolsDraggable
                      key={id}
                      item={{
                        id,
                        type: DRAGGABLE_ITEM_TYPES.COACHING_TOOL,
                        fromSprintId: sprintId,
                        fromLocation: CHALLENGE_LOCATIONS.DRAFT_SPRINT,
                      }}
                    >
                      <CoachingToolCard
                        name={name}
                        description={description}
                        url={picture?.url}
                        id={id}
                        key={id}
                      >
                        <CoachingToolCardMenu toolId={id}>
                          {coachingToolsInSprintsMap[id].length > 0 && (
                            <CoachingToolCardMenuCopyToSprint toolId={id} />
                          )}
                          {coachingToolsInSprintsMap[id].length > 0 && (
                            <CoachingToolCardMenuMoveToSprint
                              sprints={coachingToolsInSprintsMap[id]}
                              onCoachingToolMove={handleCoachingToolMove(
                                id,
                                sprintId
                              )}
                            />
                          )}
                          <CoachingToolCardMenuRemoveButton
                            toolId={id}
                            sprintId={sprintId}
                          />
                        </CoachingToolCardMenu>
                      </CoachingToolCard>
                    </CoachingToolsDraggable>
                  ))}
                </>
              )}
            </BoardColumn>
          </BoardColumnWrapper>
        </PlanningBoardColumn>
      </CoachingToolDroppable>
    </Droppable>
  );
};
