import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { queryKeys } from 'api/config';
import { logError } from 'lib/sentry/logError';
import { useMutationHTTPRequest } from 'shared_DEPRECATED/hooks';

import { FocusAreaType, GoalBreakdownType, moveFocusArea } from 'features/goal';

export const useMoveGoalFocusAreaMutation = () => {
  const { goalId } = useParams();
  const queryClient = useQueryClient();
  const { request } = useMutationHTTPRequest();

  return useMutation(
    ({ id: focusAreaId, index }: FocusAreaType & { index: number }) =>
      request({
        url: `/api/web/goals/${goalId}/breakdown/focus-areas/move`,
        body: { focusAreaId, index },
      }),
    {
      onMutate: async (item) => {
        await queryClient.cancelQueries({
          queryKey: [queryKeys.goalBreakdown, goalId],
        });

        const previousValue = queryClient.getQueryData([
          queryKeys.goalBreakdown,
          goalId,
        ]);

        queryClient.setQueryData<GoalBreakdownType>(
          [queryKeys.goalBreakdown, goalId],
          (oldVal) =>
            moveFocusArea({
              goalBreakdown: oldVal!,
              updatedFocusArea: item,
            })
        );

        return { previousValue };
      },
      onError: (err, _, context) => {
        queryClient.setQueryData(
          [queryKeys.goalBreakdown, goalId],
          context?.previousValue
        );
        logError(err);
      },
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.goalBreakdown, goalId]);
      },
    }
  );
};
