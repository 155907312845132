import { Flex, Typography } from 'shared';

type TJournalNotesEmptyState = {
  text: string;
};

export const JournalNotesEmptyState = ({ text }: TJournalNotesEmptyState) => (
  <Flex
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    height="100%"
    gap="1rem"
  >
    <Typography type="largeHeading" textAlign="center">
      👋
    </Typography>
    <Typography type="body" color="gray" textAlign="center">
      {text}
    </Typography>
  </Flex>
);
