import { useMutation } from '@tanstack/react-query';

import { useMutationHTTPRequest } from 'shared_DEPRECATED/hooks';
import { updateArrayById } from 'shared_DEPRECATED/utils';

import {
  INonRepeatedChallenge,
  IRepeatedChallenge,
} from 'features/challenge/config/types';
import {
  IPlanningDraftSprint,
  usePlanningDraftSprintsQueryActions,
} from 'features/planning';

type TUseAddChallengesToSprintMutation = {
  sprint: IPlanningDraftSprint;
  options?: any;
};

export const useAddChallengesToSprintMutation = ({
  sprint,
  options,
}: TUseAddChallengesToSprintMutation) => {
  const { request } = useMutationHTTPRequest();

  const { getDraftSprints, updateDraftSprints } =
    usePlanningDraftSprintsQueryActions();

  return useMutation(
    async (challenges: (IRepeatedChallenge | INonRepeatedChallenge)[]) =>
      await request({
        url: `/api/web/sprints/${sprint.sprintId}/challenges/create`,
        body: { challenges },
      }),
    {
      onSuccess: async (updatedSprint) => {
        const previousDraftSprintsValue = getDraftSprints();

        const updatedItems = updateArrayById({
          array: previousDraftSprintsValue!.items,
          updatedItem: updatedSprint,
          idProperty: 'sprintId',
        });

        updateDraftSprints(updatedItems);
      },
      ...options,
    }
  );
};
