import { IconButton } from '@chakra-ui/react';

import { Menu, MenuButton, MenuList, MenuItem, VARIANTS } from 'shared';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

import { Typography } from 'shared/components/Typography';

type TJournalRepliesCardHeaderMenu = {
  onEdit: () => void;
  onDelete: () => void;
};

export const JournalRepliesCardHeaderMenu = ({
  onEdit,
  onDelete,
}: TJournalRepliesCardHeaderMenu) => (
  <Menu>
    <MenuButton
      as={IconButton}
      aria-label="Options"
      icon={<Icon name={iconNames.ellipsis} />}
      variant={VARIANTS.TERTIARY}
      size="sm"
    />
    <MenuList fontSize="small">
      <MenuItem
        //@ts-ignore
        onClick={onEdit}
      >
        Edit
      </MenuItem>
      <MenuItem
        //@ts-ignore
        onClick={onDelete}
      >
        <Typography color="red">Delete</Typography>
      </MenuItem>
    </MenuList>
  </Menu>
);
