import { useCallback } from 'react';

import { IChallenge } from 'features/challenge/config/types';
import { useOpenChallengeModal } from 'features/challenge/hooks';
import {
  IPlanningDraftSprint,
  usePlanningAnalyticsEvents,
} from 'features/planning';

type TUseOpenEditBacklogChallengeDialog = {
  challenge: IChallenge;
  sprint?: IPlanningDraftSprint;
  onChallengeSubmit: (challenge: IChallenge) => void;
};

export const useOpenChallengeDialog = () => {
  const { sendPlanningChallengeEditedEvent } = usePlanningAnalyticsEvents();

  const openChallengeModal = useOpenChallengeModal();

  return useCallback(
    ({
        challenge,
        sprint,
        onChallengeSubmit,
      }: TUseOpenEditBacklogChallengeDialog) =>
      () =>
        openChallengeModal({
          challenge,
          onChallengeSubmit: (challenge: IChallenge) => {
            sendPlanningChallengeEditedEvent();

            onChallengeSubmit(challenge);
          },
          title: 'Create Challenge',
          sprint: sprint || {
            //The challenge might not be tied to any specific sprint and does not impose limits on the number of challenges.
            availableChallengeFields: {
              challenges: 100,
              oneTimeChallenges: 100,
            },
          },
        }),
    [openChallengeModal, sendPlanningChallengeEditedEvent]
  );
};
