import { useMutation, useQueryClient } from '@tanstack/react-query';

import { queryKeys } from 'api/config';
import { useMutationHTTPRequest } from 'api/hooks/useHTTPRequest';
import { logError } from 'lib/sentry/logError';

import { NOTE_STATUS } from 'features/challengeNotes/config';
import { useChallengeNotesQueryKey } from 'features/challengeNotes/hooks/useQueryKey';

import { useSidebarContext } from 'shared/components/Sidebar/context';

export const useReadAllMutation = () => {
  const { request } = useMutationHTTPRequest();
  const queryClient = useQueryClient();
  const { sprint } = useSidebarContext();
  const challengeNotesQueryKey = useChallengeNotesQueryKey();

  return useMutation(
    ['add-note-read-all'],
    () =>
      request({
        url: '/api/notes/mark-read',
        body: {
          noteOwnerEmail: sprint.userEmail,
          sprintId: sprint.sprintId,
        },
      }),
    {
      onMutate: () => {
        const previousNotes = queryClient.getQueryData(challengeNotesQueryKey);

        queryClient.setQueryData(challengeNotesQueryKey, (old) => {
          return old.map((noteItem) => {
            return {
              ...noteItem,
              status: NOTE_STATUS.READ,
            };
          });
        });
        queryClient.setQueryData(
          [queryKeys.participant, sprint.userEmail],
          (old) => ({
            ...old,
            unreadChallengeNotesNumber: 0,
            unreadSessionRecapNotesNumber: 0,
          })
        );

        return { previousNotes };
      },
      onError: (err, _, context) => {
        queryClient.setQueryData(challengeNotesQueryKey, context.previousNotes);

        logError(err);
      },
    }
  );
};
