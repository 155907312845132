import { useQuery } from '@tanstack/react-query';

import { queryKeys } from 'api/config';
import { useQueryHTTPRequest } from 'api/hooks/useHTTPRequest';

import { JournalNote, JournalNoteReply } from 'features/journal';

type TUseJournalNoteQuery = {
  noteId: string;
};

export const useJournalNoteQuery = ({ noteId }: TUseJournalNoteQuery) => {
  const { request } = useQueryHTTPRequest();

  return useQuery<{ note: JournalNote; replies: JournalNoteReply[] }>(
    [queryKeys.journal, noteId],
    async () => await request({ url: `/api/journal/notes/${noteId}` }),
    {
      select: (data) => ({
        note: data?.note ?? {},
        replies: data?.replies ?? [],
      }),
    }
  );
};
