import { toastConfig } from 'lib/reactToastify/config';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import useSprintStartMutation from 'features/sprint/hooks/mutation/useSprintStart';

type useSprintStartSubmitParams = {
  sprintId: string;
  sprintType?: string;
};

export const useSprintStartSubmit = ({
  sprintId,
  sprintType,
}: useSprintStartSubmitParams) => {
  const { email } = useParams() as { email: string };

  const { mutateAsync: startSprint } = useSprintStartMutation({
    email,
    sprintType,
  });

  return async () => {
    let toastId = toast.loading('Starting sprint...', toastConfig);

    try {
      await startSprint({ sprintId });

      toast.update(toastId, {
        render: 'Sprint started!',
        type: 'success',
        isLoading: false,
        icon: false,
        ...toastConfig,
      });
    } catch (err) {
      toast.update(toastId, {
        render:
          "There's an error with starting sprint. Please try again later.",
        type: 'error',
        isLoading: false,
        icon: false,
        ...toastConfig,
      });
    }
  };
};
