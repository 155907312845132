import { memo, ReactNode, useMemo } from 'react';

import { TChallengeLocation } from 'features/challenge/config/types';

import { Draggable } from 'shared/components/DragNDrop/Draggable';

type PlanningBoardColumnItemDraggableProps = {
  type: string;
  id: string;
  index: number;
  children: ReactNode;
  parentId: string;
  location: TChallengeLocation;
};

export const PlanningBoardColumnItemDraggable = memo(
  ({
    type,
    id,
    index,
    children,
    parentId,
    location,
  }: PlanningBoardColumnItemDraggableProps) => {
    const item = useMemo(
      () => ({
        type,
        id,
        index,
        parentId,
        location,
      }),
      [parentId, id, index, type, location]
    );

    return <Draggable item={item}>{children}</Draggable>;
  }
);
