import {
  BoardColumnPinButton,
  Button,
  ButtonGroup,
  VARIANTS,
  Flex,
} from 'shared';

import { SPRINT_STATES, SPRINT_STATUS_LABELS } from 'features/sprint/config';
import { SprintStateValues } from 'features/sprint/config/types';

import { Typography } from 'shared/components/Typography';

type TPlanningSprintHeader = {
  title: string;
  sprintState: typeof SPRINT_STATES[keyof typeof SPRINT_STATES];
  sprintFormattedDate: string;
  sprintId: string;
  onViewButtonClick: () => void;
};

export const PlanningSprintHeader = ({
  title,
  sprintState,
  sprintFormattedDate,
  sprintId,
  onViewButtonClick,
}: TPlanningSprintHeader) => {
  return (
    <Flex
      data-testid="planning-sprint-board-header"
      alignItems="center"
      flexDirection="column"
      width="100%"
    >
      <Flex
        justifyContent="space-between"
        width="100%"
        p="8px 16px"
        alignItems="center"
      >
        <Flex alignItems="flex-start" flexDirection="column">
          <Typography color="black" fontWeight="medium" type="meta">
            {title}
          </Typography>
        </Flex>
        <ButtonGroup spacing="0.5rem" size="sm">
          <BoardColumnPinButton columnId={sprintId} />
          <Button onClick={onViewButtonClick} variant={VARIANTS.TERTIARY}>
            <Typography
              color="black"
              type="meta"
              fontWeight="medium"
              textDecoration="underline"
              sx={{
                textUnderlineOffset: '4px',
              }}
            >
              View
            </Typography>
          </Button>
        </ButtonGroup>
      </Flex>
      <Flex
        justifyContent="center"
        alignItems="center"
        width="100%"
        bg={PLANNING_SPRINT_HEADER_STYLES_MAP[sprintState]}
        h="24px"
      >
        <Typography color="black" fontWeight="regular" type="small">
          {`${sprintFormattedDate} • ${
            sprintState === SPRINT_STATES.FINISHED
              ? 'Past'
              : SPRINT_STATUS_LABELS[sprintState]
          }`}
        </Typography>
      </Flex>
    </Flex>
  );
};

const PLANNING_SPRINT_HEADER_STYLES_MAP: Record<SprintStateValues, string> = {
  [SPRINT_STATES.ACTIVE]: 'var(--bgCompSuccess)',
  [SPRINT_STATES.FINISHED]: 'var(--bgCompAccent)',
};
