import { CHALLENGE_LOCATIONS } from 'features/challenge/config';
import {
  INonRepeatedChallenge,
  IRepeatedChallenge,
} from 'features/challenge/config/types';
import { useCreateChallengeModal } from 'features/challenge/hooks/useCreateChallengeModal';
import {
  useAddChallengesToBacklogMutation,
  usePlanningAnalyticsEvents,
} from 'features/planning';

export const useAddBacklogChallenge = () => {
  const { sendPlanningChallengeAddedEvent } = usePlanningAnalyticsEvents();
  const { mutateAsync: addChallenge } = useAddChallengesToBacklogMutation();

  const openAddChallengeModal = useCreateChallengeModal({
    mutateFunc: async (challenge) => {
      await addChallenge([
        challenge as IRepeatedChallenge | INonRepeatedChallenge,
      ]);

      sendPlanningChallengeAddedEvent(CHALLENGE_LOCATIONS.BACKLOG);
    },
  });

  return openAddChallengeModal;
};
