import { PopoverTrigger } from '@chakra-ui/react';

import { Button, Typography, Box } from 'shared';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

import { useJournalFilterContext } from 'features/journal';

export const JournalFilterAnchor = () => {
  const { queryParams } = useJournalFilterContext();
  const isFilterApplied = queryParams.sprintIds.length;
  return (
    <PopoverTrigger>
      <Box position="relative">
        <Button
          leftIcon={
            <Icon
              name={iconNames.controls}
              height="14"
              width="14"
              strokeWidth={0.2}
              stroke="var(--fgInteractive)"
              fill="var(--fgInteractive)"
            />
          }
        >
          <Typography color="blue">Filters</Typography>
        </Button>
        <Box
          as="span"
          style={{
            position: 'absolute',
            top: 4,
            left: 4,
            display: 'inline-block',
            width: '0.5rem',
            height: '0.5rem',
            borderRadius: '50%',
            backgroundColor: isFilterApplied ? 'var(--accent)' : 'transparent',
          }}
        />
      </Box>
    </PopoverTrigger>
  );
};
