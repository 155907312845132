import Box from 'shared_DEPRECATED/components/Box/Box';
import { FileDownloadButton } from 'shared_DEPRECATED/components/File';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import Spacer from 'shared_DEPRECATED/components/Spacer/Spacer';
import { Video } from 'shared_DEPRECATED/components/Video/Video';

import { ModalBody, ModalHeader } from 'shared/components/Modal';
import { Typography } from 'shared/components/Typography';

export function JournalCardVideoModal({ src }: { src: string }) {
  return (
    <>
      <ModalHeader>
        <Box style={{ width: '90%' }}>
          <Flex justifyContent="space-between" alignItems="center">
            <Typography as="h3" type="body" fontWeight="semiBold" color="black">
              Preview video
            </Typography>
            <>
              <Spacer size="xsm" />
              <FileDownloadButton url={src} />
            </>
          </Flex>
        </Box>
      </ModalHeader>
      <ModalBody>
        <Video
          playing={true}
          src={src}
          data-testId="challenge-notes-card-video-modal-content"
        />
      </ModalBody>
    </>
  );
}
