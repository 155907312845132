import PropTypes from 'prop-types';

import Box from 'shared_DEPRECATED/components/Box';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { ChallengeTargetType } from 'features/challenge/config/propTypes';
import { useGetChallengeFrequencyText } from 'features/challenge/hooks/useGetFrequencyText';

import { Typography } from 'shared/components/Typography';

const ChallengeFrequencyChangedPropTypes = {
  challengeTitle: PropTypes.string.isRequired,
  before: ChallengeTargetType.isRequired,
  after: ChallengeTargetType.isRequired,
  sprintWeeksCount: PropTypes.number,
};

const ChallengeFrequencyChanged = ({
  challengeTitle,
  before: frequencyBefore,
  after: frequencyAfter,
  sprintWeeksCount,
}) => {
  const getFrequencyText = useGetChallengeFrequencyText();

  return (
    <Typography>
      Changed frequency in the challenge{' '}
      <Typography as="strong" fontWeight="medium" color="black">
        {challengeTitle}
      </Typography>{' '}
      from{' '}
      <Box as="span" style={{ backgroundColor: 'var(--bgCompSecondaryMid)' }}>
        <Spacer as="span" size="zr zr zr sm" />
        <Typography as="strong" fontWeight="medium" color="black">
          {getFrequencyText(frequencyBefore, sprintWeeksCount)}
        </Typography>
        <Spacer as="span" size="zr sm zr zr" />
      </Box>{' '}
      to{' '}
      <Box as="span" style={{ backgroundColor: 'var(--bgCompMinorSuccess)' }}>
        <Spacer as="span" size="zr zr zr sm" />
        <Typography as="strong" fontWeight="medium" color="black">
          {getFrequencyText(frequencyAfter, sprintWeeksCount)}
        </Typography>
        <Spacer as="span" size="zr sm zr zr" />
      </Box>
    </Typography>
  );
};

ChallengeFrequencyChanged.propTypes = ChallengeFrequencyChangedPropTypes;

export default ChallengeFrequencyChanged;
