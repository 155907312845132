import { useCallback } from 'react';

import { CHALLENGE_LOCATIONS } from 'features/challenge/config';
import { TChallengeLocation } from 'features/challenge/config/types';
import { ICoachingTool } from 'features/coachingTool';

import { showErrorToast } from 'shared/components/Toast';
import { findIndexByProp } from 'shared/utils/array';

import {
  useAddPlanningCoachingToolToDraftSprintMutation,
  useCoachingToolMoveMutation,
} from './mutation';
import { useAddPlanningCoachingToolToSprintMutation } from './mutation/useCoachingToolAddToSprint';

export const useCoachingToolsOnDrop = ({
  coachingTools = [],
}: {
  coachingTools: ICoachingTool[];
}) => {
  const { mutateAsync: moveCoachingTool } = useCoachingToolMoveMutation();
  const { mutateAsync: copyCoachingToolToSprint } =
    useAddPlanningCoachingToolToSprintMutation();
  const { mutateAsync: copyCoachingToolToDraftSprint } =
    useAddPlanningCoachingToolToDraftSprintMutation();

  const canDropCoachingToolIntoSprint = useCallback(
    (toolId: string) => {
      const itemIndex = findIndexByProp(coachingTools, 'id', toolId);

      return itemIndex === -1;
    },
    [coachingTools]
  );

  return useCallback(
    async (item: any, sprintId: string, toLocation: TChallengeLocation) => {
      if (!canDropCoachingToolIntoSprint(item.id)) {
        showErrorToast('Selected coaching tool is already in sprint');
        return;
      }
      if (
        item.fromLocation &&
        item.fromLocation === CHALLENGE_LOCATIONS.DRAFT_SPRINT &&
        toLocation === CHALLENGE_LOCATIONS.DRAFT_SPRINT
      ) {
        await moveCoachingTool({
          toolId: item.id,
          fromSprintId: item.fromSprintId,
          toSprintId: sprintId,
          fromLocation: item.fromLocation,
          toLocation,
        });
      } else {
        if (toLocation === CHALLENGE_LOCATIONS.DRAFT_SPRINT) {
          await copyCoachingToolToDraftSprint({ sprintId, toolId: item.id });
        } else {
          await copyCoachingToolToSprint({ sprintId, toolId: item.id });
        }
      }
    },
    [
      moveCoachingTool,
      copyCoachingToolToSprint,
      copyCoachingToolToDraftSprint,
      canDropCoachingToolIntoSprint,
    ]
  );
};
