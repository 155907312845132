import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { queryKeys } from 'api/config';
import { logError } from 'lib/sentry/logError';
import { useMutationHTTPRequest } from 'shared_DEPRECATED/hooks';
import { removeFromArrayById } from 'shared_DEPRECATED/utils';

import { GoalBreakdownType } from 'features/goal';

type TUseDeleteGoalFocusAreaMutation = {
  focusAreaId: string;
};

export const useDeleteGoalFocusAreaMutation = ({
  focusAreaId,
}: TUseDeleteGoalFocusAreaMutation) => {
  const { goalId } = useParams();
  const queryClient = useQueryClient();

  const { request } = useMutationHTTPRequest();

  return useMutation(
    () =>
      request({
        url: `/api/web/goals/${goalId}/breakdown/focus-areas/delete`,
        body: { focusAreaId },
      }),
    {
      onMutate: async () => {
        await queryClient.cancelQueries({
          queryKey: [queryKeys.goalBreakdown, goalId],
        });

        const previousValue = queryClient.getQueryData([
          queryKeys.goalBreakdown,
          goalId,
        ]);

        queryClient.setQueryData<GoalBreakdownType>(
          [queryKeys.goalBreakdown, goalId],
          (oldVal) => ({
            ...oldVal!,
            focusAreas: removeFromArrayById({
              array: oldVal!.focusAreas,
              id: focusAreaId,
              idProperty: 'id',
            }),
          })
        );

        return { previousValue };
      },
      onSettled: () => {
        queryClient.invalidateQueries([queryKeys.goalBreakdown, goalId]);
      },
      onError: (err) => {
        logError(err);
      },
    }
  );
};
