import { Button, Typography } from 'shared';

import { useDeleteCoachingToolFromDraftSprintMutation } from 'features/planning';

type TCoachingToolCardMenuRemoveButton = {
  toolId: string;
  sprintId: string;
};

export const CoachingToolCardMenuRemoveButton = ({
  toolId,
  sprintId,
}: TCoachingToolCardMenuRemoveButton) => {
  const { mutateAsync: removeCoachingTool } =
    useDeleteCoachingToolFromDraftSprintMutation();

  const handleCoachingToolRemove = async () => {
    await removeCoachingTool({ toolId, sprintId });
  };

  return (
    <Button
      _focusVisible={{ outline: 'none' }}
      width="100%"
      onClick={() => handleCoachingToolRemove()}
      justifyContent="flex-start"
    >
      <Typography color="black">Remove</Typography>
    </Button>
  );
};
