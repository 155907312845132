import { useMutation } from '@tanstack/react-query';

import { useMutationHTTPRequest } from 'api/hooks/useHTTPRequest';
import { logError } from 'lib/sentry/logError';

import {
  JournalNote,
  JournalNoteReply,
  useJournalQueryActions,
} from 'features/journal';

import { showErrorToast } from 'shared/components/Toast';

type TUseJournalEditReplyMutation = {
  noteId: string;
};
export const useJournalEditReplyMutation = ({
  noteId,
}: TUseJournalEditReplyMutation) => {
  const { request } = useMutationHTTPRequest();

  const {
    cancelJournalNoteQueries,
    getPreviousJournalNoteValue,
    updateJournalNoteQueryData,
  } = useJournalQueryActions();

  return useMutation(
    async ({ text, replyId }: { text: string; replyId: string }) =>
      request({
        url: `/api/journal/replies/${replyId}/edit`,
        body: { text },
      }),
    {
      onMutate: async ({
        text,
        replyId,
      }: {
        text: string;
        replyId: string;
      }) => {
        cancelJournalNoteQueries(noteId);

        const previousJournalNoteData = getPreviousJournalNoteValue(noteId);

        updateJournalNoteQueryData(
          noteId,
          (draft: { note: JournalNote; replies: JournalNoteReply[] }) => {
            draft.replies = draft.replies.map((reply) =>
              reply.replyId === replyId ? { ...reply, text } : reply
            );
          }
        );

        return { previousJournalNoteData };
      },
      onError: (err, _, context) => {
        showErrorToast(
          "There's an error with editing reply. Please try again later."
        );

        logError(err);

        updateJournalNoteQueryData(
          noteId,
          () => () => context?.previousJournalNoteData
        );
      },
    }
  );
};
