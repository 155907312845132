import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { queryKeys } from 'api/config';

import { IPlanningSprint } from 'features/planning';
import { useUserId } from 'features/user/hooks/useUserId';

export const useSprintsQueryActions = () => {
  const queryClient = useQueryClient();
  const userId = useUserId();

  const cancelSprintsQueries = useCallback(async () => {
    await queryClient.cancelQueries({
      queryKey: [queryKeys.sprints, userId],
    });
  }, [queryClient, userId]);

  const getSprints = useCallback(() => {
    return queryClient.getQueryData<{ items: IPlanningSprint[] }>([
      queryKeys.sprints,
      userId,
    ]);
  }, [queryClient, userId]);

  const updateSprints = useCallback(
    (newItems: IPlanningSprint[]) => {
      queryClient.setQueryData<{ items: IPlanningSprint[] }>(
        [queryKeys.sprints, userId],
        (old) => {
          return {
            ...old,
            items: newItems,
          };
        }
      );
    },
    [queryClient, userId]
  );

  const invalidateSprintsQuery = useCallback(() => {
    queryClient.invalidateQueries([queryKeys.sprints, userId]);
  }, [queryClient, userId]);

  return {
    cancelSprintsQueries,
    getSprints,
    updateSprints,
    invalidateSprintsQuery,
  };
};
