import { Button, Typography } from 'shared';

import { useOpenCoachingToolDetails } from 'features/coachingTool/hooks';

type TCoachingToolCardMenuRemoveButton = {
  toolId: string;
};

export const CoachingToolCardMenuOpenButton = ({
  toolId,
}: TCoachingToolCardMenuRemoveButton) => {
  const { openCoachingToolModal } = useOpenCoachingToolDetails({ toolId });

  return (
    <Button
      _focusVisible={{ outline: 'none' }}
      width="100%"
      onClick={openCoachingToolModal}
    >
      <Typography color="black">Open tool details</Typography>
    </Button>
  );
};
