import { useMutation, useQueryClient } from '@tanstack/react-query';

import { queryKeys } from 'api/config';
import { logError } from 'lib/sentry/logError';
import { useMutationHTTPRequest } from 'shared_DEPRECATED/hooks';

export const useParticipantProfileNoteUpdateMutation = ({
  noteId,
  appointmentId,
  coachingSessionId,
}: {
  noteId: string;
  appointmentId: string;
  coachingSessionId: string;
}) => {
  const queryClient = useQueryClient();
  const { request } = useMutationHTTPRequest();

  return useMutation(
    ({ text, dashboardLink }: { text: string; dashboardLink: string }) => {
      return request({
        url: `/api/coach/coaching-client-notes/${noteId}/update`,
        body: { text, dashboardLink },
      });
    },
    {
      onSuccess: (coachingClientNote) => {
        if (
          queryClient.getQueryData([queryKeys.coachSession, coachingSessionId])
        ) {
          queryClient.setQueryData(
            [queryKeys.coachSession, coachingSessionId],
            (oldSession: any) =>
              oldSession && {
                ...oldSession,
                nextCoachingSessionAppointment: {
                  //@ts-ignore
                  ...oldSession.nextCoachingSessionAppointment,
                  coachingClientNote,
                },
              }
          );
        }

        queryClient.setQueryData(
          [queryKeys.coachSessionAppointment, appointmentId],
          //@ts-ignore
          (oldNextSession) => ({ ...oldNextSession, coachingClientNote })
        );
      },
      onError: (err) => {
        logError(err);
      },
    }
  );
};
