import { useMutation, useQueryClient } from '@tanstack/react-query';

import { queryKeys } from 'api/config';
import { useMutationHTTPRequest } from 'api/hooks/useHTTPRequest';

import { CoachProfile } from 'features/coach/settings/types';

export const useCoachProfileUpdateMutation = () => {
  const { request } = useMutationHTTPRequest();
  const queryClient = useQueryClient();

  return useMutation(
    async (coachProfile: CoachProfile) => {
      await request({
        url: `/api/web/me/profile/edit`,
        body: { coachProfile },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.user]);
        queryClient.invalidateQueries([queryKeys.coachProfile]);
      },
    }
  );
};
