import { ReactNode } from 'react';

import { Typography } from 'shared';

type TJournalFilterLabel = {
  children: ReactNode;
};

export const JournalFilterLabel = ({ children }: TJournalFilterLabel) => (
  <Typography fontWeight="medium" type="body">
    {children}
  </Typography>
);
