import { useOutletContext } from 'react-router-dom';

import { IDraftSprint } from 'features/sprint/config/types';

import { useSprintStartSubmit } from './useSprintStartSubmit';

const useSprintStart = () => {
  const { sprint } = useOutletContext<{ sprint: IDraftSprint }>();

  const { sprintId, sprintType } = sprint;

  const onStartSprintButtonClick = useSprintStartSubmit({
    sprintId,
    sprintType,
  });

  const challengesCount = sprint.challengesCount;

  const isSprintReadyToStart = challengesCount > 0 && sprint.title;

  return {
    isSprintReadyToStart,
    onStartSprintButtonClick,
  };
};

export default useSprintStart;
