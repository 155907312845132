import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useCallback } from 'react';

import { queryKeys } from 'api/config';
import { useQueryHTTPRequest } from 'api/hooks/useHTTPRequest';

import {
  IPlanningDraftSprint,
  useCreatePlanningDraftSprintInstanceWithWeeksCount,
} from 'features/planning';
import { useUserId } from 'features/user/hooks/useUserId';

export const usePlanningDraftSprintsQuery = <T>({
  options,
}: {
  options?: Omit<
    UseQueryOptions<
      IPlanningDraftSprint[],
      unknown,
      T,
      [typeof queryKeys.draftSprints]
    >,
    'queryKey' | 'queryFn'
  >;
} = {}) => {
  const { request } = useQueryHTTPRequest();
  const userId = useUserId();
  const createDraftSprintWithWeeksCount =
    useCreatePlanningDraftSprintInstanceWithWeeksCount();
  const { select, ...otherOptions } = options || {};

  return useQuery<{ items: IPlanningDraftSprint[] }, unknown, T>(
    [queryKeys.draftSprints, userId],
    () => request({ url: `/api/web/draft-sprints?user_id=${userId}` }),
    {
      // @ts-ignore
      select: useCallback(
        (data: { items: IPlanningDraftSprint[] }) => {
          let selectedData = mapPlanningDraftSprintsQueryData(
            data?.items,
            createDraftSprintWithWeeksCount
          );

          return select ? select(selectedData) : selectedData;
        },
        [createDraftSprintWithWeeksCount, select]
      ),
      staleTime: 1000 * 20,
      ...otherOptions,
    }
  );
};

const mapPlanningDraftSprintsQueryData = (
  sprints: IPlanningDraftSprint[],
  createDraftSprint: (sprint: IPlanningDraftSprint) => IPlanningDraftSprint
): IPlanningDraftSprint[] => sprints.map((sprint) => createDraftSprint(sprint));
