import { toastConfig } from 'lib/reactToastify/config';
import { Id, toast } from 'react-toastify';

import { logError } from 'lib/sentry/logError';

import { CHALLENGE_LOCATIONS } from 'features/challenge/config';
import {
  IRepeatedChallenge,
  INonRepeatedChallenge,
} from 'features/challenge/config/types';
import {
  useExploreChallengesDialog,
  useAddChallengesToBacklogMutation,
  usePlanningAnalyticsEvents,
} from 'features/planning';

export const useBacklogAddLibraryChallenge = () => {
  const { mutateAsync: addChallenges } = useAddChallengesToBacklogMutation();
  const { sendPlanningChallengeAddedEvent } = usePlanningAnalyticsEvents();

  const onSubmit = async (
    newChallenges:
      | (IRepeatedChallenge | INonRepeatedChallenge)[]
      | (INonRepeatedChallenge | IRepeatedChallenge),
    toastId: Id | undefined
  ) => {
    let submitToastId: Id | undefined;
    try {
      if (!toastId) {
        submitToastId = toast.loading(
          'Adding challenges to sprint...',
          toastConfig
        );
      }
      const challenges = Array.isArray(newChallenges)
        ? newChallenges
        : [newChallenges];

      await addChallenges(challenges);

      sendPlanningChallengeAddedEvent(CHALLENGE_LOCATIONS.BACKLOG);

      !toastId && toast.dismiss(submitToastId);
    } catch (error) {
      logError(error);
    }
  };

  const openExploreChallengesModal = useExploreChallengesDialog(
    // @ts-ignore
    {
      onSubmit,
    }
  );

  return openExploreChallengesModal;
};
