import { useQueryClient } from '@tanstack/react-query';

import { queryKeys } from 'api/config';
import { dateUtils } from 'shared_DEPRECATED/utils';

import { IGoal } from 'features/goal';

type TUseGoalUpdateQuery = { goalId: string };

export const useGoalUpdateQuery = ({ goalId }: TUseGoalUpdateQuery) => {
  const queryClient = useQueryClient();

  const previousValue = queryClient.getQueryData([queryKeys.goal, goalId]);

  return (updatedGoal: IGoal) => {
    queryClient.cancelQueries([queryKeys.goal, goalId]);

    queryClient.setQueryData([queryKeys.goal, goalId], () => ({
      ...updatedGoal,
      deepDive: {
        ...updatedGoal.deepDive!,
        updatedAt: dateUtils.utc().format('YYYY-MM-DDTHH:mm:ss'),
      },
    }));

    return { previousValue };
  };
};
