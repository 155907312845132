import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useMutationHTTPRequest } from 'api/hooks/useHTTPRequest';
import { logError } from 'lib/sentry/logError';

import { JournalNote, useJournalQueryActions } from 'features/journal';

import { showErrorToast } from 'shared/components/Toast';

type TUseJournalDeleteNoteMutation = {
  noteId: string;
};

export const useJournalDeleteNoteMutation = ({
  noteId,
}: TUseJournalDeleteNoteMutation) => {
  const { request } = useMutationHTTPRequest();
  const queryClient = useQueryClient();

  const {
    getJournalRelevantQueries,
    cancelJournalRelevantQueries,
    cancelJournalNoteQueries,
    getPreviousJournalNoteValue,
    updateJournalNoteQueryData,
    updateJournalRelevantQueries,
  } = useJournalQueryActions();

  return useMutation(
    async () =>
      request({
        url: `/api/journal/notes/${noteId}/delete`,
      }),
    {
      onMutate: async () => {
        // When we fetch notes via api/journal/notes, we use a dynamic [queryKeys.journal, queryParams] key to apply filters.
        // That's why we need to update every state.
        const relevantQueries = getJournalRelevantQueries();

        cancelJournalRelevantQueries();

        const previousJournalNotesData = relevantQueries.map(([key, data]) => ({
          key,
          data,
        }));

        updateJournalRelevantQueries((oldData) => {
          oldData.pages = oldData.pages.map((page: any) => ({
            ...page,
            notes: page.notes.filter(
              (note: JournalNote) => note.noteId !== noteId
            ),
          }));
        });

        cancelJournalNoteQueries(noteId);

        const previousJournalNoteData = getPreviousJournalNoteValue(noteId);

        updateJournalNoteQueryData(noteId, () => undefined);

        return { previousJournalNotesData, previousJournalNoteData };
      },
      onError: (err, _, context) => {
        showErrorToast(
          "There's an error with deleting note. Please try again later."
        );
        context?.previousJournalNotesData.forEach(({ key, data }: any) => {
          queryClient.setQueryData(key, data);
        });

        updateJournalNoteQueryData(
          noteId,
          () => context?.previousJournalNoteData
        );

        logError(err);
      },
    }
  );
};
