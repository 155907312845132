import { useQuery } from '@tanstack/react-query';

import { useQueryHTTPRequest } from 'api/hooks/useHTTPRequest';
import { getNoteRepliesQueryKey } from 'api/utils';
import { sortByDateAsc } from 'shared_DEPRECATED/utils';

import { NOTE_STATUS } from 'features/challengeNotes/config';
import { useNoteStatusMutation } from 'features/challengeNotes/hooks/mutation/useNoteStatus';

export const useNoteRepliesQuery = ({ noteId, userEmail }) => {
  const { request } = useQueryHTTPRequest();

  const statusMutation = useNoteStatusMutation({
    noteId,
    userEmail,
  });

  const { data, isFetching } = useQuery(
    getNoteRepliesQueryKey(noteId),
    async () => {
      const response = await request({
        url: `/api/notes/${noteId}/replies?note_owner_email=${userEmail}`,
      });
      if (response.note.status === NOTE_STATUS.UNREAD) {
        statusMutation.mutate({
          currentStatus: response.note.status,
          noteType: response.note.noteType,
        });
      }

      sortByDateAsc(response.replies, 'createdAt');

      return response;
    },
    {
      initialData: { note: null, replies: [] },
      enabled: !!noteId,
    }
  );

  return { note: data.note, replies: data.replies, isFetching };
};
