import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { queryKeys } from 'api/config';
import { useMutationHTTPRequest } from 'shared_DEPRECATED/hooks';
import { dateUtils } from 'shared_DEPRECATED/utils';

import { SessionConfigType, SessionType } from 'features/sessions/config/types';

export const useSessionCreateMutation = () => {
  const { email: clientId } = useParams();
  const queryClient = useQueryClient();
  const { request } = useMutationHTTPRequest();

  return useMutation(
    ({ coachingSessionDate, ...sessionConfig }: SessionConfigType) =>
      request({
        url: '/api/coach/coaching-sessions/create',
        body: {
          clientId,
          coachingSessionDate: dateUtils(coachingSessionDate).format(),
          ...sessionConfig,
        },
      }),
    {
      onSuccess: (newSession) => {
        queryClient.setQueryData<SessionType>(
          ['coach-session', newSession.coachingSessionId],
          () => newSession
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries([queryKeys.coachSessions, clientId]);
      },
    }
  );
};
