import { Typography } from 'shared';
import { dateUtils } from 'shared_DEPRECATED/utils';

type TJournalCardCreatedAtTime = {
  date: string;
};
export const JournalCardCreatedAtTime = ({
  date,
}: TJournalCardCreatedAtTime) => (
  <Typography as="div" type="meta" color="black">
    {dateUtils(date).format('h:mm a')}
  </Typography>
);
