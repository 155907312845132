import { ReactNode, useMemo } from 'react';

import { ObjectValues } from 'types/common';

import { TChallengeLocation } from 'features/challenge/config/types';
import { DRAGGABLE_ITEM_TYPES } from 'features/planning/config';
import { PlanningDraggableItem } from 'features/planning/types';

import { Sortable__NEW } from 'shared/components/DragNDrop/Sortable__NEW';

type PlanningBoardColumnItemProps = {
  type: ObjectValues<typeof DRAGGABLE_ITEM_TYPES>;
  id: string;
  parentId: string;
  index: number;
  children: ReactNode;
  onDrop: (item: PlanningDraggableItem) => void;
  onHover: (
    draggedItem: PlanningDraggableItem,
    hoveredItem: PlanningDraggableItem
  ) => void;
  location: TChallengeLocation;
};

export const PlanningBoardColumnItem = ({
  type,
  id,
  index,
  children,
  parentId,
  onDrop,
  onHover,
  location,
}: PlanningBoardColumnItemProps) => {
  const item = useMemo(
    () => ({
      type,
      id,
      index,
      hoverState: {
        isUpperOver: false,
        isLowerOver: false,
      },
      parentId,
      location,
    }),
    [type, id, index, parentId, location]
  );
  return (
    // eslint-disable-next-line react/jsx-pascal-case
    <Sortable__NEW item={item} onHover={onHover} onDrop={onDrop}>
      {children}
    </Sortable__NEW>
  );
};
