import PropTypes from 'prop-types';

import Box from 'shared_DEPRECATED/components/Box';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import Spacer from 'shared_DEPRECATED/components/Spacer';
import { firstLetterUpperCase } from 'shared_DEPRECATED/utils';

import { Button } from 'shared/components/Button';
import SidebarHeaderTitle from 'shared/components/Sidebar/Header/SidebarHeaderTitle';

const SidebarBackButtonPropTypes = {
  mainSidebarTitle: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

const SidebarBackButton = ({ mainSidebarTitle, onClick }) => (
  <Box
    style={{
      position: 'absolute',
      top: '5px',
      left: '10px',
    }}
  >
    <Button onClick={onClick} dataTestid="notes-sidebar-back-btn">
      <Flex alignItems="center" justifyContent="flex-start">
        <Icon name={iconNames.arrowBigLeft} />
        <Spacer size="zr md zr zr" />
        <SidebarHeaderTitle>
          Back to {firstLetterUpperCase(mainSidebarTitle)}
        </SidebarHeaderTitle>
      </Flex>
    </Button>
  </Box>
);

SidebarBackButton.propTypes = SidebarBackButtonPropTypes;

export default SidebarBackButton;
