import { useState } from 'react';

import { Flex } from 'shared';

import { IPlanningDraftSprint } from 'features/planning';

import { PlanningDraftSprintHeaderEditor } from './HeaderEditor';
import { PlanningDraftSprintHeaderViewer } from './HeaderViewer';

type TPlanningDraftSprintHeader = {
  sprint: IPlanningDraftSprint;
};

export const PlanningDraftSprintHeader = ({
  sprint,
}: TPlanningDraftSprintHeader) => {
  const [isEditing, setIsEditing] = useState(false);

  return (
    <Flex
      data-testid="sprint-planning-board-header"
      height="100%"
      p="0px 16px"
      width="100%"
      justifyContent="space-between"
      alignItems="center"
    >
      {!isEditing ? (
        <PlanningDraftSprintHeaderViewer
          sprint={sprint}
          onEdit={() => setIsEditing(true)}
        />
      ) : (
        <PlanningDraftSprintHeaderEditor
          sprint={sprint}
          onDone={() => setIsEditing(false)}
        />
      )}
    </Flex>
  );
};
