import { ReactNode } from 'react';

import { PinnedColumnsProvider } from 'shared';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import { Spacers } from 'shared_DEPRECATED/components/Spacer';

type TPlanningWrapper = {
  children: ReactNode;
};

export const PlanningWrapper = ({ children }: TPlanningWrapper) => (
  <PinnedColumnsProvider>
    <Spacers sizes={['lg', 'md']} height="100%">
      <Flex
        flexDirection="column"
        alignItems="stretch"
        justifyContent="flex-start"
        height="100%"
      >
        {children}
      </Flex>
    </Spacers>
  </PinnedColumnsProvider>
);
