import Box from 'shared_DEPRECATED/components/Box';
import { Flex } from 'shared_DEPRECATED/components/Flex';

import { Typography } from 'shared/components/Typography';

export const GoalDeepDiveStepNumber = ({ step }: { step: number }) => (
  <Box
    style={{
      width: '32px',
      height: '32px',
      borderRadius: 'var(--border-radius16)',
      backgroundColor: 'var(--bgSecondary)',
    }}
  >
    <Flex
      alignItems="center"
      justifyContent="center"
      height="100%"
      width="100%"
    >
      <Typography color="black" dataTestid={`goal-deep-dive-step-${step}`}>
        {step}
      </Typography>
    </Flex>
  </Box>
);
