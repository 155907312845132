import { useCallback } from 'react';

import { useSprintDurationFeatureFlag } from 'featureFlags/hooks/useSprintDurationFeatureFlag';

import { IPlanningDraftSprint, PlanningDraftSprint } from 'features/planning';

export const useCreatePlanningDraftSprintInstanceWithWeeksCount = () => {
  const weeksCount = useSprintDurationFeatureFlag();

  return useCallback(
    (sprint: IPlanningDraftSprint): PlanningDraftSprint =>
      new PlanningDraftSprint({ ...sprint, weeksCount }),
    [weeksCount]
  );
};
