import { useQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';

import { queryKeys } from 'api/config';

const baseVersion: string = process.env.REACT_APP_RELEASE || 'dev';

async function fetchVersion() {
  const response = await fetch(`/static/version?key=${Date.now()}`);
  return (await response.text()).trim();
}

export function useIsLatestBuildVersion() {
  const { pathname } = useLocation();
  const { data: isLatestVersion } = useQuery(
    [queryKeys.version, pathname],
    () => fetchVersion(),
    {
      onError: console.error,
      select: (data) => baseVersion === data,
    }
  );

  return isLatestVersion;
}
