import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { queryKeys } from 'api/config';

import { IPlanningSprint } from 'features/planning/Sprint';
import { useUserId } from 'features/user/hooks/useUserId';

export const usePlanningActiveFinishedSprintsQueryActions = () => {
  const queryClient = useQueryClient();
  const userId = useUserId();

  const cancelActiveFinishedSprintsQueries = useCallback(async () => {
    await queryClient.cancelQueries({
      queryKey: [queryKeys.sprints, userId],
    });
  }, [queryClient, userId]);

  const getActiveFinishedSprints = useCallback(() => {
    return queryClient.getQueryData<{ items: IPlanningSprint[] }>([
      queryKeys.sprints,
      userId,
    ]);
  }, [queryClient, userId]);

  const updateActiveFinishedSprints = useCallback(
    (newItems: IPlanningSprint[]) => {
      queryClient.setQueryData<{ items: IPlanningSprint[] }>(
        [queryKeys.sprints, userId],
        (old) => ({
          ...old,
          items: newItems,
        })
      );
    },
    [queryClient, userId]
  );

  const invalidateActiveFinishedSprintsQuery = useCallback(() => {
    queryClient.invalidateQueries([queryKeys.sprints, userId]);
  }, [queryClient, userId]);

  return {
    cancelActiveFinishedSprintsQueries,
    getActiveFinishedSprints,
    updateActiveFinishedSprints,
    invalidateActiveFinishedSprintsQuery,
  };
};
