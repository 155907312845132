import { useQuery } from '@tanstack/react-query';

import { queryKeys } from 'api/config';
import { useQueryHTTPRequest } from 'api/hooks/useHTTPRequest';

import { PlanningSprintModel, IPlanningSprint } from 'features/planning';
import { useUserId } from 'features/user/hooks/useUserId';

export const useSprintsQuery = <T>() => {
  const { request } = useQueryHTTPRequest();
  const userId = useUserId();

  return useQuery<{ items: IPlanningSprint[] }, unknown, T>(
    [queryKeys.sprints, userId],
    () => request({ url: `/api/web/sprints?user_id=${userId}` }),
    {
      enabled: !!userId,
      // @ts-ignore
      select,
      staleTime: 1000 * 20,
    }
  );
};

const select = (data: { items: IPlanningSprint[] }) =>
  data.items.map((sprint) => new PlanningSprintModel(sprint));
