import Linkify from 'linkify-react';
import { ReactNode } from 'react';

import { Flex } from 'shared';
import { HotjarSuppression } from 'shared_DEPRECATED/components/Hotjar';
import { Spacers } from 'shared_DEPRECATED/components/Spacer';

import { JournalCardFooter, JournalRepliesCardHeader } from 'features/journal';

import { Typography } from 'shared/components/Typography';

type TJournalReply = {
  pictureUrl: string;
  userName: string;
  date: string;
  text: string;
  setEditedReply: () => void;
  replyId: string;
  relatedNoteId: string;
  children: ReactNode;
  isAuthor: boolean;
};

export const JournalReply = ({
  pictureUrl,
  userName,
  date,
  text,
  replyId,
  relatedNoteId,
  isAuthor,
  children,
  setEditedReply,
}: TJournalReply) => (
  <Flex width="100%" p="0.5rem" gap="4px" flexDirection="column">
    <JournalRepliesCardHeader
      name={userName}
      pictureUrl={pictureUrl}
      date={date}
      isAuthor={isAuthor}
      setEditedReply={setEditedReply}
      replyId={replyId}
      relatedNoteId={relatedNoteId}
    />
    <Spacers sizes={['zr zr zr lg', 'zr zr zr lg', 'sm zr zr sm']}>
      <HotjarSuppression>
        <Typography type="small" color="black">
          <Linkify options={{ target: '_blank' }}>{text}</Linkify>
        </Typography>
      </HotjarSuppression>
    </Spacers>
    <JournalCardFooter>{children}</JournalCardFooter>
  </Flex>
);
