import { Box, TabPanel, TabPanelsProps, VStack } from '@chakra-ui/react';

import Loader from 'shared_DEPRECATED/components/Loader';

import { useCoachingToolsQuery } from 'features/coachingTool';
import { PlanningBoardColumnItemsCount } from 'features/planning';

import { zIndexes } from 'shared/config/zIndexes';

import { CoachingToolItems } from './CoachingToolItems';

const PlanningBacklogCoachingTools = () => {
  const { data: coachingTools, isLoading } = useCoachingToolsQuery();

  if (isLoading) {
    return <Loader />;
  }

  return coachingTools?.length ? (
    <>
      <Box
        style={{
          position: 'sticky',
          top: '0',
          zIndex: zIndexes.planningColumnHeader,
          backgroundColor: 'var(--bgPrimary)',
        }}
      >
        <Box bg="var(--bgCompSecondaryBig)">
          <PlanningBoardColumnItemsCount
            name="coaching tool"
            count={coachingTools.length}
          />
        </Box>
      </Box>
      <VStack align="stretch" flex={1} spacing={0.5} p={2} gap={1}>
        <CoachingToolItems coachingTools={coachingTools} />
      </VStack>
    </>
  ) : null;
};

type TPlanningBacklogCoachingToolsTab = TabPanelsProps;
// TabPanelsProps need to be passed in order to make TabPanel component work

export const PlanningBacklogCoachingToolsTab = (
  props: TPlanningBacklogCoachingToolsTab
) => (
  <TabPanel
    {...props}
    m={0}
    p={0}
    height="100%"
    width="100%"
    overflowY="auto"
    overflowX="hidden"
  >
    <PlanningBacklogCoachingTools />
  </TabPanel>
);
