import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toastErrorConfig } from 'lib/reactToastify/config';
import { toast } from 'react-toastify';

import { queryKeys } from 'api/config';
import { useMutationHTTPRequest } from 'api/hooks/useHTTPRequest';
import { produce } from 'immer';
import { logError } from 'lib/sentry/logError';

import { CHALLENGE_LOCATIONS } from 'features/challenge/config';
import { useCoachingToolsQuery } from 'features/coachingTool';
import {
  usePlanningBacklogQueryActions,
  usePlanningDraftSprintsQueryActions,
} from 'features/planning';
import { useSprintsQueryActions } from 'features/sprint/hooks/query/sprints/useSprintsActions';
import { useUserId } from 'features/user/hooks/useUserId';

import { usePreviousPlanningValues } from '../usePreviousPlanningValues';

type CoachingToolMove = {
  toolId: string;
  fromSprintId: string;
  toSprintId: string;
  fromLocation?: string;
  toLocation?: string;
};

export const useCoachingToolMoveMutation = () => {
  const queryClient = useQueryClient();
  const { request } = useMutationHTTPRequest();
  const userId = useUserId();

  const { invalidateDraftSprintsQuery, updateDraftSprints, getDraftSprints } =
    usePlanningDraftSprintsQueryActions();
  const { updateBacklog } = usePlanningBacklogQueryActions();
  const { getSprints, updateSprints } = useSprintsQueryActions();
  const getPreviousPlanningValues = usePreviousPlanningValues();
  const { data: coachingTools = [] } = useCoachingToolsQuery();

  return useMutation(
    ({ toolId, fromSprintId, toSprintId }: CoachingToolMove) =>
      request({
        url: `/api/web/sprint-planning/coaching-tools/move`,
        body: { toolId, fromSprintId, toSprintId },
      }),
    {
      onMutate: ({
        toolId,
        fromSprintId,
        toSprintId,
        fromLocation,
        toLocation,
      }) => {
        const prevValues = getPreviousPlanningValues();
        const coachingTool = coachingTools.find(({ id }) => id === toolId);

        if (toLocation && coachingTool) {
          if (
            [fromLocation, toLocation].includes(
              CHALLENGE_LOCATIONS.DRAFT_SPRINT
            )
          ) {
            const prevDraftSprints = getDraftSprints();
            const newItems = produce(prevDraftSprints!.items, (draft) => {
              if (fromLocation === CHALLENGE_LOCATIONS.DRAFT_SPRINT) {
                const fromSprintIndex = draft.findIndex(
                  (sprint) => sprint.sprintId === fromSprintId
                );
                const coachingToolIndex = draft[
                  fromSprintIndex
                ].coachingTools.findIndex((tool) => tool.id === toolId);
                draft[fromSprintIndex].coachingTools.splice(
                  coachingToolIndex,
                  1
                );
              }
              if (toLocation === CHALLENGE_LOCATIONS.DRAFT_SPRINT) {
                const toSprintIndex = draft.findIndex(
                  (sprint) => sprint.sprintId === toSprintId
                );
                draft[toSprintIndex].coachingTools.push(coachingTool);
              }
            });

            updateDraftSprints(newItems);
          }

          if (
            [fromLocation, toLocation].includes(
              CHALLENGE_LOCATIONS.ACTIVE_SPRINT
            )
          ) {
            const prevSprints = getSprints();
            const newItems = [...prevSprints!.items];

            if (fromLocation === CHALLENGE_LOCATIONS.ACTIVE_SPRINT) {
              const fromSprintIndex = newItems.findIndex(
                (sprint) => sprint.sprintId === fromSprintId
              );
              const coachingToolIndex = newItems[
                fromSprintIndex
              ].coachingTools.findIndex((tool) => tool.id === toolId);
              newItems[fromSprintIndex].coachingTools.splice(
                coachingToolIndex,
                1
              );
            }
            if (toLocation === CHALLENGE_LOCATIONS.ACTIVE_SPRINT) {
              const toSprintIndex = newItems.findIndex(
                (sprint) => sprint.sprintId === toSprintId
              );
              newItems[toSprintIndex].coachingTools.push(coachingTool);
            }

            updateSprints(newItems);
          }
        }

        return prevValues;
      },
      onError: (err: Error, __: any, context) => {
        if (context) {
          if (context.previousBacklogValue) {
            updateBacklog(context.previousBacklogValue.items);
          }
          if (context.previousDraftSprintsValue) {
            updateDraftSprints(context.previousDraftSprintsValue.items);
          }
          if (context.previousSprintsValue) {
            updateSprints(context.previousSprintsValue.items);
          }

          logError(err);
          toast.error('Failed to move coaching tool', toastErrorConfig);
        }
      },
      onSuccess: () => {
        invalidateDraftSprintsQuery();
        queryClient.invalidateQueries([queryKeys.sprints, userId]);
      },
    }
  );
};
