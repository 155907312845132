import { useState } from 'react';
import { When } from 'react-if';
import { For } from 'react-loops';

import { Box, Flex } from 'shared';
import Loader from 'shared_DEPRECATED/components/Loader';
import { Nullable } from 'shared_DEPRECATED/types';

import {
  JournalReply,
  JournalRepliesCard,
  useJournalNoteQuery,
  JournalNote,
  JournalEditForm,
  useJournalEditNoteMutation,
  JournalNoteReply,
  JournalReplyCardReactions,
} from 'features/journal';
import { useJournalEditReplyMutation } from 'features/journal/hooks/mutation/useEditReply';
import { User } from 'features/user/User';

import { useSidebarContext } from 'shared/components/Sidebar/context';

export const JournalRepliesContent = () => {
  const [editedNote, setEditedNote] = useState<Nullable<JournalNote>>(null);
  const [editedReply, setEditedReply] =
    useState<Nullable<JournalNoteReply>>(null);

  const { noteId, user } = useSidebarContext() as {
    user: User;
    noteId: string;
  };

  const { data: { note, replies } = {}, isLoading } = useJournalNoteQuery({
    noteId,
  });

  const { mutateAsync: editNote } = useJournalEditNoteMutation();
  const { mutateAsync: editReply } = useJournalEditReplyMutation({ noteId });

  const handleEditNote = async ({ text }: { text: string }) => {
    const noteId = editedNote?.noteId ?? '';
    setEditedNote(null);

    await editNote({ text, noteId });
  };

  const handleEditReply = async ({ text }: { text: string }) => {
    const replyId = editedReply?.replyId ?? '';
    setEditedReply(null);

    await editReply({ text, replyId });
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Flex
      height="100%"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="stretch"
    >
      <Box style={{ flexGrow: 1, overflow: 'auto' }}>
        {note && Object.keys(note).length > 0 && (
          <Box w="100%" p="0.5rem">
            <JournalRepliesCard
              note={note}
              setEditedNode={() => setEditedNote(note)}
            />
          </Box>
        )}
        <When condition={replies && Boolean(replies.length)}>
          <For of={replies}>
            {(reply) => {
              const isAuthor = reply.author.userId === user.userId;

              return (
                <JournalReply
                  date={reply.createdAt}
                  pictureUrl={reply.author.picture.url}
                  text={reply.text}
                  userName={reply.author.fullName}
                  replyId={reply.replyId}
                  isAuthor={isAuthor}
                  setEditedReply={() => setEditedReply(reply)}
                  relatedNoteId={note!.noteId}
                >
                  <JournalReplyCardReactions
                    replyId={reply.replyId}
                    noteId={reply.noteId}
                    reactions={reply.reactions}
                  />
                </JournalReply>
              );
            }}
          </For>
        </When>
      </Box>
      {editedNote && (
        <Box
          position="sticky"
          bottom="0"
          p="1rem"
          boxShadow="0 -2px 4px rgba(0, 0, 0, 0.1)"
        >
          <JournalEditForm
            itemType="note"
            text={editedNote.text}
            onCancel={() => setEditedNote(null)}
            onSave={handleEditNote}
            user={user}
          />
        </Box>
      )}
      {editedReply && (
        <Box
          position="sticky"
          bottom="0"
          p="1rem"
          boxShadow="0 -2px 4px rgba(0, 0, 0, 0.1)"
        >
          <JournalEditForm
            itemType="reply"
            text={editedReply.text}
            onCancel={() => setEditedReply(null)}
            onSave={handleEditReply}
            user={user}
          />
        </Box>
      )}
    </Flex>
  );
};
