import { useMutation } from '@tanstack/react-query';

import { produce } from 'immer';
import { logError } from 'lib/sentry/logError';
import { useMutationHTTPRequest } from 'shared_DEPRECATED/hooks';

import { IPlanningSprint } from 'features/planning/Sprint';
import { useSprintsQueryActions } from 'features/sprint/hooks/query/sprints/useSprintsActions';

import { showErrorToast } from 'shared/components/Toast';

export const useDeleteCoachingToolFromSprintMutation = () => {
  const { request } = useMutationHTTPRequest();

  const { updateSprints, cancelSprintsQueries, getSprints } =
    useSprintsQueryActions();

  return useMutation(
    ({ toolId, sprintId }: { toolId: string; sprintId: string }) =>
      request({
        url: `/api/web/sprint-planning/coaching-tools/remove`,
        body: { toolId, sprintId },
      }),
    {
      onMutate: async ({ toolId, sprintId }) => {
        await cancelSprintsQueries();

        const previousSprintsValue = getSprints();
        const prevSprintItems = previousSprintsValue!.items;

        if (previousSprintsValue) {
          const sprintIndex = prevSprintItems.findIndex(
            (sprint) => sprint.sprintId === sprintId
          );

          if (sprintIndex !== -1) {
            const updatedSprints = produce(prevSprintItems, (draft) => {
              const coachingToolIndex = draft[
                sprintIndex
              ].coachingTools.findIndex((tool) => tool.id === toolId);
              draft[sprintIndex].coachingTools.splice(coachingToolIndex, 1);
            });

            updateSprints(updatedSprints);
          }
        }

        return { previousSprintsValue };
      },
      onError: (
        err: Error,
        __: any,
        context:
          | {
              previousSprintsValue?: {
                items: IPlanningSprint[];
              };
            }
          | undefined
      ) => {
        if (context?.previousSprintsValue) {
          updateSprints(context.previousSprintsValue!.items);
        }
        logError(err);
        showErrorToast('Failed to remove coaching tool.');
      },
    }
  );
};
