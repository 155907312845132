import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useMutationHTTPRequest } from 'api/hooks/useHTTPRequest';
import { logError } from 'lib/sentry/logError';

import { JournalNote, useJournalQueryActions } from 'features/journal';

import { showErrorToast } from 'shared/components/Toast';

export const useJournalEditNoteMutation = () => {
  const { request } = useMutationHTTPRequest();
  const queryClient = useQueryClient();

  const {
    getJournalRelevantQueries,
    cancelJournalRelevantQueries,
    cancelJournalNoteQueries,
    getPreviousJournalNoteValue,
    updateJournalNoteQueryData,
    updateJournalRelevantQueries,
  } = useJournalQueryActions();

  return useMutation(
    async ({ text, noteId }: { text: string; noteId: string }) =>
      request({
        url: `/api/journal/notes/${noteId}/edit`,
        body: { text },
      }),
    {
      onMutate: async ({ text, noteId }: { text: string; noteId: string }) => {
        // When we fetch notes via api/journal/notes, we use a dynamic [queryKeys.journal, queryParams] key to apply filters.
        // That's why we need to update every state.

        const relevantQueries = getJournalRelevantQueries();

        cancelJournalRelevantQueries();

        const previousJournalNotesData = relevantQueries.map(([key, data]) => ({
          key,
          data,
        }));

        updateJournalRelevantQueries((oldData) => {
          oldData.pages = oldData.pages.map((page: any) => ({
            ...page,
            notes: page.notes.map((note: JournalNote) =>
              note.noteId === noteId ? { ...note, text } : note
            ),
          }));
        });

        cancelJournalNoteQueries(noteId);

        const previousJournalNoteData = getPreviousJournalNoteValue(noteId);

        updateJournalNoteQueryData(noteId, (old) => {
          old.note.text = text;
        });

        return { previousJournalNotesData, previousJournalNoteData };
      },
      onError: (err, { noteId }, context) => {
        showErrorToast(
          "There's an error with editing note. Please try again later."
        );

        logError(err);

        context?.previousJournalNotesData.forEach(({ key, data }: any) => {
          queryClient.setQueryData(key, data);
        });

        updateJournalNoteQueryData(
          noteId,
          () => context?.previousJournalNoteData
        );
      },
    }
  );
};
