import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { queryKeys } from 'api/config';

import { PlanningDraftSprint } from 'features/planning/DraftSprint';
import { useUserId } from 'features/user/hooks/useUserId';

export const usePlanningDraftSprintsQueryActions = () => {
  const queryClient = useQueryClient();
  const userId = useUserId();

  const cancelDraftSprintsQueries = useCallback(async () => {
    await queryClient.cancelQueries({
      queryKey: [queryKeys.draftSprints, userId],
    });
  }, [queryClient, userId]);

  const getDraftSprints = useCallback(() => {
    return queryClient.getQueryData<{ items: PlanningDraftSprint[] }>([
      queryKeys.draftSprints,
      userId,
    ]);
  }, [queryClient, userId]);

  const updateDraftSprints = useCallback(
    (newItems: PlanningDraftSprint[]) => {
      queryClient.setQueryData<{ items: PlanningDraftSprint[] }>(
        [queryKeys.draftSprints, userId],
        (old) => ({
          ...old,
          items: newItems,
        })
      );
    },
    [queryClient, userId]
  );

  const invalidateDraftSprintsQuery = useCallback(() => {
    queryClient.invalidateQueries([queryKeys.draftSprints, userId]);
  }, [queryClient, userId]);

  return {
    cancelDraftSprintsQueries,
    getDraftSprints,
    updateDraftSprints,
    invalidateDraftSprintsQuery,
  };
};
