import { Input } from '@chakra-ui/react';
import React from 'react';
import { useForm } from 'react-hook-form';

import { ButtonGroup, ButtonIcon, VARIANTS } from 'shared';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { IPlanningDraftSprint } from 'features/planning';
import { useEditDraftSprintTitle } from 'features/planning/hooks/mutation/useEditDraftSprintTitle';

type TPlanningDraftSprintHeaderEditor = {
  sprint: IPlanningDraftSprint;
  onDone: () => void;
};

export const PlanningDraftSprintHeaderEditor = ({
  sprint,
  onDone,
}: TPlanningDraftSprintHeaderEditor) => {
  const mutation = useEditDraftSprintTitle();
  const methods = useForm({
    defaultValues: {
      title: sprint.title!,
    },
  });

  const handleSubmit = methods.handleSubmit((data) => {
    mutation.mutate({
      title: data.title,
      sprintId: sprint.sprintId,
    });
    onDone();
  });

  const handleCancel = () => {
    onDone();
  };

  const handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      await handleSubmit();
    }
    if (e.key === 'Escape') {
      e.preventDefault();
      handleCancel();
    }
  };
  return (
    <>
      <Input
        {...methods.register('title')}
        autoFocus={true}
        onKeyDown={handleKeyDown}
      />
      <Spacer size={'0.5rem'} />
      <ButtonGroup spacing="0.5rem" size="sm">
        <ButtonIcon
          ariaLabel="save"
          icon={
            <Icon
              name={iconNames.tick}
              width={14}
              height={14}
              fill="var(--fgPrimary)"
            />
          }
          dataTestid="save-btn"
          variant={VARIANTS.TERTIARY}
          onClick={handleSubmit}
          isRound
        />
        <ButtonIcon
          ariaLabel="cancel"
          icon={
            <Icon
              name={iconNames.cross}
              width={14}
              height={14}
              fill="var(--fgPrimary)"
            />
          }
          dataTestid="save-btn"
          variant={VARIANTS.TERTIARY}
          onClick={handleCancel}
          isRound
        />
      </ButtonGroup>
    </>
  );
};
