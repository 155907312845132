import { IconButton, Portal } from '@chakra-ui/react';

import { Menu, MenuButton, MenuList, VARIANTS } from 'shared';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

import {
  PlanningBacklogAddChallengeButton,
  PlanningBacklogAddLibraryChallengeButton,
} from 'features/planning';

export const PlanningBacklogHeaderMenu = () => (
  <Menu>
    <MenuButton
      as={IconButton}
      aria-label="Options"
      data-testid="backlog-header-add"
      icon={
        <Icon name={iconNames.plus} height="10" width="10" strokeWidth={2} />
      }
      variant={VARIANTS.TERTIARY}
    />
    <Portal>
      <MenuList>
        <PlanningBacklogAddChallengeButton key="add-challenge" />
        <PlanningBacklogAddLibraryChallengeButton key="add-library-challenge" />
      </MenuList>
    </Portal>
  </Menu>
);
