import { Box } from '@chakra-ui/react';
import { useDrag } from 'react-dnd';

type DraggableProps<T> = {
  children?: React.ReactNode;
  item: T;
  width?: string;
  canDrop?: (item: T) => boolean;
};

export const Draggable = <T extends { id: string; type: string }>({
  children,
  item,
}: DraggableProps<T>) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: item.type,
    item,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    isDragging: (monitor) => monitor.getItem().id === item.id,
  }));

  return (
    <Box ref={drag} cursor="move" opacity={isDragging ? 0.5 : 1}>
      {children}
    </Box>
  );
};
