import { useQueryClient } from '@tanstack/react-query';

export const useReactionLoadingState = () => {
  // We need this to prevent sending a delete request
  // for a reaction that exists only in the query cache.
  const queryClient = useQueryClient();

  const setReactionLoadingState = (
    reactionId: string | number,
    isLoading: boolean
  ) => {
    queryClient.setQueryData(['create-journal-reaction', reactionId], {
      isLoading,
    });
  };

  const getReactionLoadingState = (reactionId: string | number) => {
    const data = queryClient.getQueryData([
      'create-journal-reaction',
      reactionId,
    ]) as { isLoading: boolean };
    return data ? data.isLoading : false;
  };

  return { setReactionLoadingState, getReactionLoadingState };
};
