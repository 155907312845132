import { JOURNAL_FEATURE_FLAG, useFeatureFlag } from 'featureFlags';

import { openNotesRepliesSidebar } from 'features/challengeNotes/utils';
import { openJournalRepliesSidebar } from 'features/journal';
import { useUserContext } from 'features/user/context';

export const useNoteRepliesSidebar = (notification) => {
  const { user } = useUserContext();
  const isJournalEnabled = useFeatureFlag(JOURNAL_FEATURE_FLAG);

  const openNoteSidebar = () => {
    if (isJournalEnabled) {
      openJournalRepliesSidebar({
        note: {
          sprintId: notification.sprintId,
          challengeId: notification.challengeId,
          noteId: notification.noteId || notification.coachSessionNoteId,
          userEmail: notification.sharerEmail,
        },
        user,
      });

      return;
    }

    openNotesRepliesSidebar({
      note: {
        sprintId: notification.sprintId,
        challengeId: notification.challengeId,
        noteId: notification.noteId || notification.coachSessionNoteId,
        userEmail: notification.sharerEmail,
      },
      user,
    });
  };

  return openNoteSidebar;
};
