import { useCallback } from 'react';

import { useSprintDurationFeatureFlag } from 'featureFlags/hooks/useSprintDurationFeatureFlag';

import { DraftSprint } from 'features/sprint';
import { IDraftSprint } from 'features/sprint/config/types';

export const useCreateDraftSprintInstanceWithWeeksCount = () => {
  const weeksCount = useSprintDurationFeatureFlag();

  return useCallback(
    (sprint: IDraftSprint): DraftSprint =>
      new DraftSprint({ ...sprint, weeksCount } as IDraftSprint),
    [weeksCount]
  );
};
