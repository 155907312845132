import { Sortable } from 'shared/components/DragNDrop/Sortable';

type CoachingToolsDraggableProps<T> = {
  children?: React.ReactNode;
  item: T;
};

export const CoachingToolsDraggable = <T extends { id: string; type: string }>({
  children,
  item,
}: CoachingToolsDraggableProps<T>) => {
  return <Sortable item={item}>{children}</Sortable>;
};
