import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { queryKeys } from 'api/config';

import { SessionType } from 'features/sessions/config/types';
import { mapSessionData } from 'features/sessions/utils';

interface UpdateSessionNotesProps {
  setValue: Function;
  getValues: Function;
}

export const useUpdateSessionNotes = ({
  setValue,
  getValues,
}: UpdateSessionNotesProps) => {
  const { sessionId } = useParams();
  const queryClient = useQueryClient();

  return () => {
    const updatedSessionQuery = queryClient.getQueryData([
      queryKeys.coachSession,
      sessionId,
    ]) as SessionType;
    const updatedSession = mapSessionData(updatedSessionQuery);

    const currentSessionNotes = getValues('dimensionChallengeNotes');

    Object.keys(currentSessionNotes).forEach((sessionNoteKey) => {
      const currentSessionNote = currentSessionNotes[sessionNoteKey];
      const updatedSessionNote =
        updatedSession.dimensionChallengeNotes[sessionNoteKey];

      const isNewNoteCreated =
        !currentSessionNote.coachingSessionNoteId &&
        updatedSessionNote?.coachingSessionNoteId;

      if (isNewNoteCreated) {
        setValue(`dimensionChallengeNotes.${sessionNoteKey}`, {
          ...currentSessionNote,
          coachingSessionNoteId: updatedSessionNote.coachingSessionNoteId,
        });
      }
    });
  };
};
