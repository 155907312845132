import { IconButton, Menu, MenuItem, MenuList } from '@chakra-ui/react';
import { createPortal } from 'react-dom';

import { MenuButton, Typography, VARIANTS } from 'shared';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { IChallenge } from 'features/challenge/config/types';
import {
  useDeletePlanningBacklogChallengesMutation,
  usePlanningAnalyticsEvents,
  useOpenEditBacklogChallengeDialog,
} from 'features/planning';

type TPlanningBacklogItemMenu = {
  challenge: IChallenge;
};

export const PlanningBacklogMenu = ({
  challenge,
}: TPlanningBacklogItemMenu) => {
  const { sendPlanningChallengeRemovedEvent } = usePlanningAnalyticsEvents();

  const openEditChallengeModal = useOpenEditBacklogChallengeDialog({
    challenge,
  });

  const { mutateAsync: deleteChallenge } =
    useDeletePlanningBacklogChallengesMutation();

  const handleChallengeRemove = () => {
    sendPlanningChallengeRemovedEvent();

    deleteChallenge([challenge.challengeId!]);
  };

  return (
    <Menu placement="bottom-end" offset={[-10, 0]} autoSelect={false}>
      <MenuButton
        as={IconButton}
        icon={<Icon name={iconNames.ellipsisHorizontal} />}
        variant={VARIANTS.TERTIARY}
        size="sm"
      />
      {createPortal(
        <MenuList p="0">
          <Spacer size="sm" />
          <MenuItem
            onClick={openEditChallengeModal}
            _hover={{ outline: 'none', borderRadius: 'var(--border-radius)' }}
            width="100%"
          >
            <Typography color="black">Edit</Typography>
          </MenuItem>
          <MenuItem
            onClick={handleChallengeRemove}
            _hover={{ outline: 'none', borderRadius: 'var(--border-radius)' }}
            width="100%"
          >
            <Typography color="black">Remove</Typography>
          </MenuItem>
          <Spacer size="sm" />
        </MenuList>,
        document.body
      )}
    </Menu>
  );
};
