import { useQuery } from '@tanstack/react-query';

import { queryKeys } from 'api/config';
import { useQueryHTTPRequest } from 'shared_DEPRECATED/hooks';

export const useCoachTimezonesQuery = () => {
  const { request } = useQueryHTTPRequest();

  return useQuery(
    [queryKeys.coachingTimezones],
    () => request({ url: '/api/timezones' }),
    {
      initialData: { timezones: [] },
      select: (data) => data.timezones,
    }
  );
};
