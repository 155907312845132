import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { queryKeys } from 'api/config';
import { logError } from 'lib/sentry/logError';
import { useMutationHTTPRequest } from 'shared_DEPRECATED/hooks';

import { GoalBreakdownChallengeType } from 'features/goal';

export const useAddGoalChallengeToSprintMutation = () => {
  const queryClient = useQueryClient();
  const { goalId } = useParams();

  const { request } = useMutationHTTPRequest();

  return useMutation(
    (
      challenge: GoalBreakdownChallengeType & {
        participantId: string;
      }
    ) =>
      request({
        url: `/api/web/sprints/current/challenges/add`,
        body: challenge,
      }),
    {
      onError: (err) => {
        logError(err);
      },
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.goalBreakdown, goalId]);
      },
    }
  );
};
