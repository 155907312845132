import { useMutation } from '@tanstack/react-query';

import 'api/config';
import { useMutationHTTPRequest } from 'api/hooks/useHTTPRequest';
import { logError } from 'lib/sentry/logError';

import {
  JournalNote,
  JournalNoteReply,
  useJournalQueryActions,
} from 'features/journal';

import { showErrorToast } from 'shared/components/Toast';

export const useDeleteJournalReplyReaction = () => {
  const { request } = useMutationHTTPRequest();

  const {
    cancelJournalNoteQueries,
    getPreviousJournalNoteValue,
    updateJournalNoteQueryData,
  } = useJournalQueryActions();

  return useMutation(
    async ({ reactionId, replyId }: MutationFnPropType) =>
      await request({
        url: `/api/journal/replies/${replyId}/reactions/${reactionId}/delete`,
      }),
    {
      onMutate: ({ reactionId, replyId, noteId }: MutationFnPropType) => {
        cancelJournalNoteQueries(noteId);

        const previousJournalNoteData = getPreviousJournalNoteValue(noteId);

        updateJournalNoteQueryData(
          noteId,
          (draft: { note: JournalNote; replies: JournalNoteReply[] }) => {
            draft.replies = draft.replies.map((reply) => {
              if (reply.replyId === replyId) {
                reply.reactions.filter(
                  (reaction) => reaction.reactionId !== reactionId
                );
              }

              return reply;
            });
          }
        );

        return { previousJournalNoteData };
      },
      onError: (err, { noteId }, context) => {
        showErrorToast(
          "There's an error with deleting reaction. Please try again later."
        );

        updateJournalNoteQueryData(
          noteId,
          () => context?.previousJournalNoteData
        );

        logError(err);
      },

      onSuccess: (newReply: JournalNoteReply, { noteId, replyId }) => {
        updateJournalNoteQueryData(
          noteId,
          (draft: { note: JournalNote; replies: JournalNoteReply[] }) => {
            draft.replies = draft.replies.map((reply) => {
              if (reply.replyId === replyId) {
                return newReply;
              }

              return reply;
            });
          }
        );
      },
    }
  );
};

type MutationFnPropType = {
  reactionId: number;
  replyId: string;
  noteId: string;
};
