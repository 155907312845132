import {
  ButtonIcon,
  ButtonIconPropTypes,
  VARIANTS,
} from 'shared/components/Button';

type ToolbarButtonIconProps = ButtonIconPropTypes;

export const ToolbarButtonIcon = ({
  disabled,
  ...props
}: ToolbarButtonIconProps) => {
  return (
    <ButtonIcon
      variant={VARIANTS.SYMBOL}
      _focusVisible={{
        outline: 'none',
      }}
      _active={{
        bg: 'none',
      }}
      disabled={disabled}
      {...props}
    />
  );
};
