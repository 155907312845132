import { useQueryClient } from '@tanstack/react-query';

import { queryKeys } from 'api/config';
import { produce } from 'immer';

import { IGoal, ColumnsType } from 'features/goal';

import { getUuId } from 'shared/utils/uuid';

type TUseGoalsUpdateQuery = { userId: string; modifiedColumnId: string };

export const useGoalsUpdateQuery = ({
  userId,
  modifiedColumnId,
}: TUseGoalsUpdateQuery) => {
  const queryClient = useQueryClient();

  const previousValue = queryClient.getQueryData([queryKeys.goals, userId]);

  const addGoal = (updatedGoal: IGoal) => {
    const temporaryGoalId = getUuId();
    queryClient.cancelQueries([queryKeys.goals, userId]);

    if (previousValue) {
      queryClient.setQueryData<{ columns: ColumnsType }>(
        [queryKeys.goals, userId],
        (oldVal) =>
          produce(oldVal!, (draft) => {
            for (const column of draft.columns) {
              if (column.columnId === updatedGoal.columnId) {
                column.goals.push({ ...updatedGoal, goalId: temporaryGoalId });
              }
            }
          })
      );
    }

    return { previousValue, temporaryGoalId };
  };

  const updateGoal = (updatedGoal: IGoal) => {
    queryClient.cancelQueries([queryKeys.goals, userId]);

    if (previousValue) {
      queryClient.setQueryData<{ columns: ColumnsType }>(
        [queryKeys.goals, userId],
        (oldVal) => {
          return produce(oldVal!, (draft) => {
            for (const column of draft.columns) {
              column.goals = column.goals.map((goal) =>
                goal.goalId === updatedGoal.goalId ? updatedGoal : goal
              );
            }
          });
        }
      );
    }

    return { previousValue };
  };

  const removeGoal = (goalId: string) => {
    queryClient.cancelQueries([queryKeys.goals, userId]);

    if (previousValue) {
      queryClient.setQueryData<{ columns: ColumnsType }>(
        [queryKeys.goals, userId],
        (oldVal) => {
          return produce(oldVal!, (draft) => {
            for (const column of draft.columns) {
              if (column.columnId === modifiedColumnId) {
                column.goals = column.goals.filter(
                  (goal) => goal.goalId !== goalId
                );
              }
            }
          });
        }
      );
    }

    return { previousValue };
  };

  return { removeGoal, addGoal, updateGoal };
};
